import React, { useState } from 'react'
import Container from '../../Layouts/Common/Container/Container'
import { useFormik } from 'formik';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
// import Toast from 'react-bootstrap/Toast'
import { Modal } from 'react-bootstrap';
import { useSearchParams, useNavigate } from "react-router-dom"

const Signup = () => {
	const navigate = useNavigate();
	const [errorMessage, setErrorMessage] = useState({})
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const closeModalHandle = () => {
		setIsModalVisible(!isModalVisible)
	}

	const handleAuthError = (error) => {
		switch (error.code) {
			case "UsernameExistsException":
				return { message: "User already registered in the site", title: "Error" }
			case "InvalidPasswordException":
				return { message: error.message, title: "Invalid Password" }
			default:
				break;
		}
	}

	async function signUp({ username, password, email }) {
		try {
			const { user } = await Auth.signUp({
				username: email,
				password,
				attributes: {
					email: email,
					name: username
				},
			});
			
			setIsLoading(false)
			navigate(`/confirm-user?emailId=${email}`)
		} catch (error) {
			let errorMessage = handleAuthError(error)
			setIsLoading(false)
			setErrorMessage(errorMessage)
			setIsModalVisible(true)
		}
	}

	const formik = useFormik({
		initialValues: {
			username: "",
			email: "",
			password: "",
			confirmPassword: ""
		},
		validationSchema: Yup.object({
			username: Yup.string()
				.max(64, 'Must be 64 characters or less')
				.required('Name is required'),
			email: Yup.string()
				.email("Provide a valid Email ID")
				.max(64, 'Must be 64 characters or less')
				.required('Email is required'),
			password: Yup.string()
				.required('Password is required')
				.min(8, 'Password must be 8 characters long')
				.matches(/[0-9]/, 'Password requires a number')
				.matches(/[a-z]/, 'Password requires a lowercase letter')
				.matches(/[A-Z]/, 'Password requires an uppercase letter')
				.matches(/[^\w]/, 'Password requires a symbol'),
			confirmPassword: Yup.string().required('Confirm Password is required')
				.oneOf([Yup.ref('password'), null], 'Passwords must match')
		}).shape({}),
		validateOnChange: false,
		validateOnBlur: true,
		onSubmit: (values, { setSubmitting }) => {
			setIsLoading(true)
			delete values['confirmPassword']
			signUp(values)
		},
	});
	return (
		<div>
			<Container pageName={"Sign Up"}>
				<div className="row">
					<div className="signUpCol col-md-6">
						<div className="SignUpform">
							<form onSubmit={formik.handleSubmit} onReset={formik.resetForm}>
								<div className="form-group SignUpformFlexItem signUpInputGroup" >
									<div htmlFor="Name" className="signUpLabel required" >Name</div>
									<input type="text" className={formik.touched.username && formik.errors.username ? "form-control is-invalid" : "form-control"}  {...formik.getFieldProps('username')} />
									<div className="invalid-feedback">
										{formik.touched.username && formik.errors.username}
									</div>
								</div>
								<div className="form-group SignUpformFlexItem signUpInputGroup" >
									<div htmlFor="Email" className="signUpLabel required">Email <span className="signupUserName">(Username)</span></div>
									<input type="text" className={formik.touched.email && formik.errors.email ? "form-control is-invalid" : "form-control"} {...formik.getFieldProps('email')} />
									<div className="invalid-feedback">
										{formik.touched.email && formik.errors.email}
									</div>
								</div>
								<div className="form-group SignUpformFlexItem signUpInputGroup" >
									<div htmlFor="Password" className="signUpLabel required">Password</div>
									<input type="password" className={formik.touched.password && formik.errors.password ? "form-control is-invalid" : "form-control"} {...formik.getFieldProps('password')} />
									<div className="invalid-feedback">
										{formik.touched.password && formik.errors.password}
									</div>
								</div>
								<div className="form-group SignUpformFlexItem signUpInputGroup" >
									<div htmlFor="Confirm Password" className="signUpLabel required">Confirm Password</div>
									<input type="password" className={formik.touched.confirmPassword && formik.errors.confirmPassword ? "form-control is-invalid" : "form-control"} {...formik.getFieldProps('confirmPassword')} />
									<div className="invalid-feedback">
										{formik.touched.confirmPassword && formik.errors.confirmPassword}
									</div>

								</div>
								<div className="SignUpButtonRow" >
									<button type="submit" disabled={isLoading ? true : false} className="signUpButton">Submit</button>
								</div>
								<p className="backToLogin"><span>Already have an account ?</span> <span ><a href="/login" className="SignUpLink">Login</a></span></p>
							</form>
						</div>
					</div>
				</div>
				<Modal
					show={isModalVisible}
					onHide={closeModalHandle}
					backdrop="static"
					keyboard={false}
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					<Modal.Header

					>
						
						<Modal.Title>
							<h4 className="SignupErrorInfo">
								Error
							</h4>
						</Modal.Title>

					</Modal.Header>
					<Modal.Body><span className="signUpErrorMsg">{errorMessage.message}</span>
						<button onClick={closeModalHandle} className="SignUpCloseButton">Close</button>
					</Modal.Body>
				</Modal>
			</Container>
		</div>
	)
}

export default Signup