const initialData = {
	listProjectsPage: { isDataLoaded: false },
	buyerProjectDetails: { isDataLoaded: false },
	buyerJobDetails: { isDataLoaded: false },
	buyerJobs: { isDataLoaded: false },
	supplierList: { isDataLoaded: false },
	bidsList: { isDataLoaded: false },
	buyerCreateProjectResponse: {
		isCreateSuccessful: false,
		isCreateInitiated: false,
		isCreateError: false,
	},
	buyerCreateJobResponse: {
		isCreateSuccessful: false,
		isCreateInitiated: false,
		isCreateError: false,
	},
	buyerUpdateProjectResponse: {
		isUpdateSuccessful: false,
		isUpdateInitiated: false,
	},
	buyerUpdateJobResponse: {
		isUpdateSuccessful: false,
		isUpdateInitiated: false,
	},
	buyerSetBidInviteResponse: {
		isUpdateSuccessful: false,
		isUpdateInitiated: false,
	},
	buyerGetBidResponse: { isDataLoaded: false },
	buyerConfirmSupplier: {
		isUpdateSuccessful: false,
		isUpdateInitiated: false,
		apiStatus: "NOT_SUBMITTED",
	},
	abortProject: { isUpdateSuccessful: false },
	completeProject: { isUpdateSuccessful: false },
	abortJob: { isUpdateSuccessful: false },
	clientList: { isDataLoaded: false },
	buyerList: { isDataLoaded: false },
};

const BuyerDataReducer = (state = initialData, action) => {
	switch (action.type) {
		case "SET_BUYER_PROJECT_LIST":
			return { ...state, ...action.payload };
		case "CREATE_BUYER_PROJECT_INITIATED":
			return { ...state, ...action.payload };
		case "SET_BUYER_PROJECT_CREATE_RESPONSE":
			return { ...state, ...action.payload };
		case "BUYER_PROJECT_CREATE_FAILED":
			return { ...state, ...action.payload };
		case "GET_BUYER_PROJECT_RESPONSE":
			return { ...state, ...action.payload };
		case "GET_BUYER_PROJECT_FAILED":
			return { ...state, ...action.payload };
		case "CREATE_BUYER_JOB_INITIATED":
			return { ...state, ...action.payload };
		case "SET_BUYER_JOB_CREATE_RESPONSE":
			return { ...state, ...action.payload };
		case "GET_BUYER_JOB_RESPONSE":
			return { ...state, ...action.payload };
		case "GET_BUYER_JOBS_INITIATED":
			return { ...state, ...action.payload };
		case "GET_BUYER_JOBS_RESPONSE":
			return { ...state, ...action.payload };
		case "GET_BUYER_JOBS_FAILED":
			return { ...state, ...action.payload };
		case "GET_SUPPLIER_LIST_RESPONSE":
			return { ...state, ...action.payload };
		case "GET_SUPPLIER_LIST_FAILED":
			return { ...state, ...action.payload };
		case "GET_BIDS_LIST_RESPONSE":
			return { ...state, ...action.payload };
		case "GET_BIDS_LIST_FAILED":
			return { ...state, ...action.payload };
		case "UPDATE_BUYER_JOB_RESPONSE":
			return { ...state, ...action.payload };
		case "UPDATE_BUYER_JOB_FAILED":
			return { ...state, ...action.payload };
		case "UPDATE_BUYER_PROJECT_RESPONSE":
			return { ...state, ...action.payload };
		case "UPDATE_BUYER_PROJECT_FAILED":
			return { ...state, ...action.payload };
		case "CREATE_BUYER_PROJECT_PAGE_INITIALIZE":
			return { ...state, ...action.payload };
		case "CREATE_BUYER_JOB_PAGE_INITIALIZE":
			return { ...state, ...action.payload };
		case "UPDATE_BUYER_JOB_PAGE_INITIALIZE":
			return { ...state, ...action.payload };
		case "SET_BUYER_PROJECT_LIST_PAGE_INITIALIZE":
			return { ...state, ...action.payload };
		case "SET_BUYER_BID_INVITE_INITIATED":
			return { ...state, ...action.payload };
		case "SET_BUYER_BID_INVITE_RESPONSE":
			return { ...state, ...action.payload };
		case "BUYER_BID_INVITE_RESPONSE_FAILED":
			return { ...state, ...action.payload };
		case "GET_BUYER_BID_RESPONSE_INITIATED":
			return { ...state, ...action.payload };
		case "SET_BUYER_BID_RESPONSE":
			return { ...state, ...action.payload };
		case "BUYER_BID_RESPONSE_RESPONSE_FAILED":
			return { ...state, ...action.payload };
		case "SET_CONFIRM_SUPPLIER":
			return { ...state, ...action.payload };
		case "CONFIRM_SUPPLIER_INITIATED":
			return { ...state, ...action.payload };
		case "SAVE_CONFIRM_SUPPLIER_RESPONSE":
			return { ...state, ...action.payload };
		case "SAVE_CONFIRM_SUPPLIER_FAILED":
			return { ...state, ...action.payload };
		case "ABORT_PROJECT_RESPONSE":
			return { ...state, ...action.payload };
		case "ABORT_PROJECT_RESPONSE_FAILED":
			return { ...state, ...action.payload };
		case "COMPLETE_PROJECT_RESPONSE":
			return { ...state, ...action.payload };
		case "COMPLETE_PROJECT_RESPONSE_FAILED":
			return { ...state, ...action.payload };
		case "GET_CLIENT_LIST_INITIATED":
			return { ...state, ...action.payload };
		case "GET_CLIENT_LIST_RESPONSE":
			return { ...state, ...action.payload };
		case "CLIENT_LIST_RESPONSE_FAILED":
			return { ...state, ...action.payload };
		case "GET_BUYER_LIST_INITIATED":
			return { ...state, ...action.payload };
		case "GET_BUYER_LIST_RESPONSE":
			return { ...state, ...action.payload };
		case "GET_BUYER_LIST_RESPONSE_FAILED":
			return { ...state, ...action.payload };
		case "ABORT_JOB_RESPONSE":
			return { ...state, ...action.payload };
		case "ABORT_JOB_RESPONSE_FAILED":
			return { ...state, ...action.payload };
		default:
			return state;
	}
};
export default BuyerDataReducer;
