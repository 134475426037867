import { combineReducers } from "redux";
import UserDataReducer from './UserDataReducer'
import BuyerDataReducer from "./BuyerDataReducer";
import SupplierDataReducer from "./SupplierDataReducer";

const allReducers = combineReducers({
	userData: UserDataReducer,
	buyerData: BuyerDataReducer,
	supplierData: SupplierDataReducer
})

export default allReducers
