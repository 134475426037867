import React, { useState } from 'react'
import Container from '../../Layouts/Common/Container/Container'
import { useSearchParams, useNavigate } from "react-router-dom"
import { useFormik } from 'formik';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
import { Modal } from 'react-bootstrap';


const ForgotPasswordVerification = () => {
	let [searchParams, setSearchParams] = useSearchParams()
	const emailId = searchParams.get("emailId") || ""
	const navigate = useNavigate();
	const [errorMessage, setErrorMessage] = useState({ message: "", title: "" })
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const closeModalHandle = (error) => {
		setIsModalVisible(!isModalVisible)
		if (error === "") {
			navigate("/login")
		}
	}

	const handleAuthError = (error) => {
		switch (error.code) {
			case "UsernameExistsException":
				return { message: "An error is encountered while registering your account. Please contact service desk.", title: "User Already Exist" }
			case "InvalidPasswordException":
				return { message: error.message, title: "Invalid Password" }
			default:
				return { message: error.message, title: error.code }
		}
	}
	const confirmUser = async ({ email, confirmationCode, password }) => {

		try {
			const user = await Auth.forgotPasswordSubmit(email, confirmationCode, password);
			setIsLoading(false)
			
			setIsModalVisible(true)
		} catch (error) {
			let errorMessage = handleAuthError(error)
			setIsLoading(false)
			setErrorMessage(errorMessage)
			setIsModalVisible(true)
			
		}
	}

	const formik = useFormik({
		initialValues: {
			email: emailId,
			confirmationCode: "",
			confirmPassword:"",
			password:""
		},
		validationSchema: Yup.object({
			password: Yup.string().required('Password is required')
			.min(8, 'Password must be 8 characters long')
			.matches(/[0-9]/, 'Password requires a number')
			.matches(/[a-z]/, 'Password requires a lowercase letter')
			.matches(/[A-Z]/, 'Password requires an uppercase letter')
			.matches(/[^\w]/, 'Password requires a symbol'),
			confirmPassword: Yup.string()
				.oneOf([Yup.ref('password'), null], 'Passwords must match.'),
			confirmationCode: Yup.string()
				.matches(/^[0-9]+$/, "Must be only digits.")
				.min(6, 'Must be 6 digits.')
				.max(6, 'Must be 6 digits.')
				.required('Verification Code is Required.'),
			email: Yup.string()
				.email("Provide a valid Email ID.")
				.max(64, 'Must be 64 characters or less.')
				.required('Email ID is Required.')
		}).shape({}),
		validateOnChange: false,
		validateOnBlur: true,
		onSubmit: (values) => {
			setIsLoading(true)
			confirmUser(values)
		},
	});

	return (
		<div>
			<div>
				<Container pageName={"Forgot Password Verification"}>
					<div className="signUpCol col-md-6">
						<div className="SignUpform">
							<form onSubmit={formik.handleSubmit} onReset={formik.resetForm}>
								<div className="form-group SignUpformFlexItem signUpLabelInputGroup" >
									<label htmlFor="Email" className="signUpLabel required">Email <span className="signupUserName">(UserName)</span></label>
									<input type="text" className={formik.touched.email && formik.errors.email ? "form-control is-invalid" : "form-control"} disabled={emailId !== ""}{...formik.getFieldProps('email')} />
									<div className="invalid-feedback">
										{formik.touched.email && formik.errors.email}
									</div>
								</div>
								<div className="form-group SignUpformFlexItem signUpLabelInputGroup" >
									<label htmlFor="confirmationCode" className="signUpLabel required" >Confirmation Code</label>
									<input type="text" className={formik.touched.confirmationCode && formik.errors.confirmationCode ? "form-control is-invalid" : "form-control"}  {...formik.getFieldProps('confirmationCode')} />
									<div className="invalid-feedback">
										{formik.touched.confirmationCode && formik.errors.confirmationCode}
									</div>
								</div>
								<div className="form-group SignUpformFlexItem signUpLabelInputGroup" >
									<label htmlFor="Password" className="signUpLabel required">Password</label>
									<input type="password" className={formik.touched.password && formik.errors.password ? "form-control is-invalid" : "form-control"} {...formik.getFieldProps('password')} />
									<div className="invalid-feedback">
										{formik.touched.password && formik.errors.password}
									</div>
								</div>
								<div className="form-group SignUpformFlexItem signUpLabelInputGroup" >
									<label htmlFor="Confirm Password" className="signUpLabel required">Confirm Password</label>
									<input type="password" className={formik.touched.confirmPassword && formik.errors.confirmPassword ? "form-control is-invalid" : "form-control"} {...formik.getFieldProps('confirmPassword')} />
									<div className="invalid-feedback">
										{formik.touched.confirmPassword && formik.errors.confirmPassword}
									</div>
								</div>
								<div className="SignUpButtonRow" >
									<button type="submit" disabled={isLoading ? true : false} className="signUpButton">Submit</button>
								</div>
							</form>
						</div>
					</div>
					<Modal
						show={isModalVisible}
						onHide={() => closeModalHandle(errorMessage.title)}
						backdrop="static"
						keyboard={false}
						size="md"
						aria-labelledby="contained-modal-title-vcenter"
					// centered
					>
						<Modal.Header closeButton>
							<img
								src="holder.js/20x20?text=%20"
								className="rounded me-2"
								alt=""
							/>
							{errorMessage.title === "" ? "Registration Successful...!" : errorMessage.title}
						</Modal.Header>
						<Modal.Body>{errorMessage.message === "" ? "Please login with your new credentials." : errorMessage.message}</Modal.Body>
					</Modal>
				</Container>
			</div>
		</div>
	)
}

export default ForgotPasswordVerification