import React, { useState, useEffect } from 'react'
import { BuyerCognitoGroup, SupplierCognitoGroup } from '../../Utils/Constants'
import { doesUserHasResourceAccess } from '../../Utils/HelperFunctions'
import Container from '../Common/Container/Container'

const Unauthorised = () => {
	const [userRole, setUserRole] = useState("")

	useEffect(() => {
		if (doesUserHasResourceAccess(BuyerCognitoGroup)) {
			setUserRole("buyer")
		}
		else if (doesUserHasResourceAccess(SupplierCognitoGroup)) {
			setUserRole("supplier")
		}
	}, [])

	return (
		<div>
			<Container pageName={"Unauthorized"}>
			<p className="errMsg">You are not authorized to view this page.</p>
				<br />
				{/* <a href={`print/${userRole}/projects`}>
					Click here to redirect to your home page
				</a> */}
			</Container>
		</div>
	)
}

export default Unauthorised