import { Auth } from "aws-amplify";
import { MESSAGES } from "../../Utils/ErrorMessages";

async function loginUser(email, password) {
	try {
		localStorage.clear();
		await Auth.signIn(email, password);
		localStorage.setItem("isAuthenticated", "true");
		let userData = getSessionData();
		localStorage.setItem("userData", JSON.stringify(userData));
		// navigate('/get-token')
	} catch (e) {
		alert(e.message);
		// setLoading(false)
	}
}

async function registerUser(props) {
	try {
		await Auth.signUp({
			username: props.user.username,
			attributes: {
				email: props.user.username,
				name: `${props.user.foreName} ${props.user.surname}`,
			},
			password: props.user.password,
		});

		props.history.push("/confirmRegister");
	} catch (e) {
		props.setisLoading(false);
		if (e.code === "InvalidPasswordException") {
			props.Seterrormsg({ authErr: MESSAGES.PASSWORD_RESTRICTION });
		} else if (e.code === "UsernameExistsException") {
			props.Seterrormsg({
				authErr:
					"An error is encountered while registering your account. Please contact service desk.",
			});
		} else if (e.code === "UsernameExistsException") {
			props.Seterrormsg({
				authErr:
					"An error is encountered while registering your account. Please contact service desk.",
			});
		} else {
			props.Seterrormsg({ authErr: e.message });
		}
	}
}

async function confirmUser(props) {
	try {
		await Auth.confirmSignUp(props.user.username, props.user.confirmationCode, {
			forceAliasCreation: true,
		});
		props.history.push("/dashboard");
	} catch (e) {
		if (e.code === "InvalidParameterException") {
			props.Seterrormsg({ authErr: MESSAGES.CODE_RESTRICTION });
		} else {
			props.Seterrormsg({ authErr: e.message });
		}
	}
}

async function getSessionData() {
	const user = await Auth.currentSession();
	return user;
}

const isUserLoggedIn = async () => {
	try {
		await Auth.currentAuthenticatedUser();
		return true;
	} catch (error) {
		return false;
	}
};

export { loginUser, registerUser, confirmUser, getSessionData, isUserLoggedIn };
