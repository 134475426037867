import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import ReactPaginate from "react-paginate";
import { dateFormatter } from "../../../../Utils/HelperFunctions";
import Container from "../../../../Layouts/Common/Container/Container";
import {
	BuyerListProjectStatusDropDown,
	BuyerJobStatusDropDown,
} from "../../../../Utils/Constants";
import { getBuyerListOfProjects } from "../../../../Actions/BuyerJourneyActions";

export const BuyerListProjects = ({
	getBuyerListOfProjects,
	listProjectsPage,
}) => {
	const initialSearchValue ={
		SearchBy: "",
			PageNum: 1,
			PageSize: "20",JobStatus:"",ProjectStatus:""
	}
	
	const [searchData, setSearchData] = useState({...initialSearchValue});
	
	const [order, setOrder] = useState({
		sortColumn: "completionDate",
		ascending: true,
	});

	const navigate = useNavigate();

	async function runOnceIfSearchDataChanges() {
		await getBuyerListOfProjects(searchData);
	}

	const sorting = async (field) => {
		let sortValue = {
			...order,
			sortColumn: field,
			ascending: !order.ascending,
			...searchData,
		};
		await setOrder(sortValue);
		getBuyerListOfProjects(sortValue);
	};

	// Load Data when Page Number or Page Size changes
	useEffect(() => {
		runOnceIfSearchDataChanges();
	}, [searchData.PageSize, searchData.PageNum]);


	// Load Data when the page loads
	useEffect(() => {
		runOnceIfSearchDataChanges();
	}, []);

	//Load Data when Search Button is clicked
	const searchButtonOnClickHandler = async () => {
		getBuyerListOfProjects(searchData);
	};

	const calculatePagesCount = (pageSize, totalCount) => {
		// we suppose that if we have 0 items we want 1 empty page
		return Math.ceil(
			totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize)
		);
	};

	const handlePageChange = (data) => {
		setSearchData({ ...searchData, PageNum: data.selected + 1 });
	};

	const handleChange = async (event) => {
		let searchObj = { ...searchData };
		if (event.target.value === "null") {
			delete searchObj[event.target.name];
		} else {
			searchObj[event.target.name] = event.target.value;
		}
		
		await setSearchData(searchObj);
	};
	async function clearFilter(){
		 
			
			getBuyerListOfProjects(initialSearchValue)
			setSearchData(initialSearchValue)
		
	}
	return (
		<div>
			<Container pageName={"Projects"}>
				<div className="row listOfProjectsearchRow">
					<div className=" SearchCol col-md-4">
						<label className="SearchLabel">Search by</label>
						<input
							onChange={handleChange}
							name="SearchBy"
							value={searchData.SearchBy}
							type="search"
							className="searchInput form-control rounded "
							placeholder="Either Title, ISBN, Buyer, Client or Client Reference"
							aria-label="Search"
							aria-describedby="search-addon"
						/>
					</div>
					<div className="col-md-1">
						<label className="SearchLabel">Project Status</label>
						<div>
							<select
								className="searchFilter ProjectStatusdropdownbtn dropdown-toggle form-select"
								name="ProjectStatus"
								value={searchData.ProjectStatus}
								onChange={handleChange}
							>
								{BuyerListProjectStatusDropDown.map(
									({ label, value }, idx) => {
										return (
											<option key={idx} value={value}>
												{label}
											</option>
										);
									}
								)}
							</select>
						</div>
					</div>
					<div className="col-md-1">
						<label className="SearchLabel">Job Status</label>
						<div>
							<select
								className="searchFilter JobStatusdropdownbtn dropdown-toggle form-select"
								name="JobStatus"
								
								value={searchData.JobStatus}
								onChange={handleChange}
							>
								{BuyerJobStatusDropDown.map(
									({ label, value }, idx) => {
										return (
											<option key={idx} value={value}>
												{label}
											</option>
										);
									}
								)}
							</select>
						</div>
					</div>
					<div className="col-md-2">
						<div className="row">
							<div className="col-md-6">
							<button
							type="button"
							className="searchButton"
							onClick={searchButtonOnClickHandler}
						>
							Search
						</button>
							</div>
							<div className="col-md-6">
							<div type="button"
							className="clearFilterLink" onClick={clearFilter}><u>Clear Filters</u></div>
							</div>
						
					
				
					</div>
						
						
						
					</div>
					{/* <div className="col-md-1">
					<button type="button"
							className="clearFilterButton" onClick={clearFilter}><u>Clear Filters</u></button>
					</div> */}
					<div className="col-md-4">
						<button
							type="button"
							onClick={() => {
								navigate("/print/buyer/project/create");
							}}
							className="createNewProject"
						>
							Create Project
						</button>
					</div>
				</div>
				{listProjectsPage.isDataLoaded ? (
					<div className="listOfProjectstable">
						<table className="table table-bordered">
							<thead className="thead">
								<tr className="headingRow">
								<th
										className="title tableHeading"
										name="title"
										onClick={() => sorting("title")}
									>
										Title
										{order.sortColumn === "title" ? (
										
											
													<i
												className={
													order.ascending
														? "sortIcon  bi bi-arrow-up"
														: "sortIcon  bi bi-arrow-down "
												}
												aria-hidden="true"
											></i>
												
										
											
										) : " "}
									</th>

									<th
										className="ISBN tableHeading"
										onClick={() => sorting("isbn")}
									>
										ISBN
										{order.sortColumn === "isbn" ? (
											<i
												className={
													order.ascending
														? "sortIcon bi bi-arrow-up "
														: "sortIcon bi bi-arrow-down "
												}
											></i>
										) : null}
									</th>
									<th
										className="Owner tableHeading"
										onClick={() => sorting("buyer")}
									>
										Buyer
										{order.sortColumn === "buyer" ? (
											<i
												className={
													order.ascending
														? "sortIcon bi bi-arrow-up "
														: "sortIcon bi bi-arrow-down "
												}
											></i>
										) : null}
									</th>
									<th
										className="client tableHeading"
										name="sortColumn"
										onClick={() => sorting("client")}
									>
										Client
										{order.sortColumn === "client" ? (
											<i
												className={
													order.ascending
														? "sortIcon bi bi-arrow-up "
														: "sortIcon bi bi-arrow-down "
												}
											></i>
										) : null}
									</th>
									<th
										className="Client Refference tableHeading"
										onClick={() =>
											sorting("clientReference")
										}
									>
										Client Reference
										{order.sortColumn ===
										"clientReference" ? (
											<i
												className={
													order.ascending
														? "sortIcon bi bi-arrow-up "
														: "sortIcon bi bi-arrow-down "
												}
											></i>
										) : null}
									</th>
									<th
										className="CompletionTime tableHeading"
										onClick={() =>
											sorting("completionDate")
										}
									>
										Completion Date
										{order.sortColumn ===
										"completionDate" ? (
											<i
												className={
													order.ascending
														? "sortIcon bi bi-arrow-up"
														: "sortIcon bi bi-arrow-down"
												}
											></i>
										) : null}
									</th>
									<th
										className="Status tableHeading"
										onClick={() => sorting("status")}
									>
										Status
										{order.sortColumn === "status" ? (
											<i
												className={
													order.ascending
														? "sortIcon bi bi-arrow-up "
														: "sortIcon bi bi-arrow-down "
												}
											></i>
										) : null}
									</th>
								</tr>
							</thead>
							<tbody id="ProjectData">
								{listProjectsPage.isDataLoaded ? (
									listProjectsPage.totalRecords > 0 ? (
										listProjectsPage.data.map((project) => {
											let projectURL = `/print/buyer/project/${project.id}/update`;
											return (
												<tr key={project.id}>
													<td>
														<a
															className="projectTitleLink"
															href={projectURL}
														>
															<u>
																{project.title}
															</u>
														</a>
													</td>
													<td>{project.isbn}</td>
													<td>{project.buyer}</td>
													<td>{project.client}</td>
													<td>
														{
															project.clientReference
														}
													</td>
													<td>
														{dateFormatter(
															project.completionDate
														)}
													</td>
													<td>{project.status}</td>
												</tr>
											);
										})
									) : (
										<tr>
											<td
												className="NoProjectDetails"
												colspan="7"
											>
												There are no projects available.
											</td>
										</tr>
									)
								) : (
									""
								)}
							</tbody>
						</table>
					</div>
				) : (
					<div className="listOfProjectsLoader">
						<ClipLoader
							color={"#008080"}
							size={50}
							aria-label="Loading Spinner"
							data-testid="loader"
						/>
					</div>
				)}
				<div className="PaginationRow row">
					<div className="col-md-5 pageSize">
						<select
							className="selectbuyer pageSizedropdownbtn dropdown-toggle form-select"
							name="PageSize"
							value={searchData.PageSize}
							onChange={handleChange}
						>
							<option value="10">10</option>
							<option value="15">15</option>
							<option value="20">20</option>
							<option value="25">25</option>
						</select>
					</div>
					<div className="col-md-7 pageNumber">
						<ReactPaginate
							previousLabel={"<"}
							nextLabel={">"}
							breakLabel={"..."}
							pageCount={calculatePagesCount(
								searchData.PageSize,
								listProjectsPage.totalRecords || 0
							)}
							onPageChange={handlePageChange}
							marginPagesDisplayed={1}
							pageRangeDisplayed={1}
							containerClassName={"pagination"}
							pageClassName={"page-item"}
							pageLinkClassName={"page-link"}
							previousClassName={"page-item"}
							previousLinkClassName={"page-link"}
							nextClassName={"page-item"}
							nextLinkClassName={"page-link"}
							breakClassName={"page-item"}
							breakLinkClassName={"page-link"}
							activeClassName={"active"}
						/>
					</div>
				</div>
			</Container>
		</div>
	);
};

const mapStateToProps = (state) => {
	return { ...state.buyerData };
};

const mapDispatchToProps = (dispatch) => {
	return {
		getBuyerListOfProjects: (searchData) => {
			dispatch(getBuyerListOfProjects(searchData));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyerListProjects);
