import allReducers from "./../Reducers";
import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./../Sagas";

const sagaMiddleware = createSagaMiddleware();

export default function configureIndexStore() {
	const store = configureStore(
		{
			reducer: allReducers,
			middleware: [sagaMiddleware]
		},
		window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
	);
	sagaMiddleware.run(rootSaga);
	return store
}

