import { API } from 'aws-amplify';
import { getSessionData } from '../Auth/AuthAPI';
import axios from 'axios';


export const myInitHeader = (currentSession) => ({
	headers: {
		Authorization: `Bearer ${currentSession.getIdToken().getJwtToken()}`,
	}
})

export async function get(path, name) {
	let apiName = name;
	const myNewInit = myInitHeader(await getSessionData());

	return API.get(apiName, path, myNewInit)
		.then(response => {
			return response
		})
		.catch(e => {
		
			throw getValidErrors(e, path);
		});
}

export async function post(path, name, data, customresHeader = {}, customreqHeader = {}) {
	let apiName = name;
	const myNewInit = myInitHeader(await getSessionData());
	let myInit = {
		body: data,
		headers: { ...myNewInit.headers, ...customreqHeader },
		...customresHeader
	}
	return API.post(apiName, path, myInit)
		.then(response => {
			return response
		}).catch(e => {
			
			throw getValidErrors(e, path);
		})

}

export const del = async (path, name, data) => {
	const responseObj = {}
	let apiName = name;
	const myNewInit = myInitHeader(await getSessionData());
	let myInit = {
		body: data,
		...myNewInit
	}
	return API.del(apiName, path, myInit)
		.then(response => {
			responseObj.posted = response.success
			responseObj.success = response.success
			responseObj.message = response.message
			responseObj.data = response.data
			return responseObj;
		}).catch(e => {
			
			throw getValidErrors(e, path);

		})
}

export const apiPut = async (path, name, data) => {
	const responseObj = {}
	let apiName = name;
	const myNewInit = myInitHeader(await getSessionData());

	let myInit = {
		body: data,
		...myNewInit
	}
	return API.put(apiName, path, myInit)
		.then(response => {
			responseObj.code = 'success'
			responseObj.message = response.message
			responseObj.posted = response.success
			responseObj.success = response.success
			responseObj.data = response.data
			return responseObj;
		}).catch(e => {
			
			throw getValidErrors(e, path);

		})
}


const getValidErrors = (error, apiUrl) => {
	const errorDetails = error.response;
	if (errorDetails) {
		const { message, error: statusText, success, detailedMessages, data } = errorDetails.data || {};
		const { status } = errorDetails

		if (status === 401 || status === 403) {
			window.location.href = '/print/unauthorised?status=UnauthorisedStatusFromAPI'
		}
		if (status === 404 ) {
			window.location.href = '/print/PageNotFound?status=PageNotFoundfromAPI'
		}
		if(status >= 500 )
		{
			window.location.href = '/print/error?status=InternalServerErrorFromAPI'
		}
		// if(status === 400){
		// 	window.location.href = '/print/error?status=BadRequest'
		// }

		return { success, statusText, status, message, detailedMessages, data };
	}

	return {
		error: true,
		success: false,
		statusText: `${error.response} ${apiUrl}`,
		status: 500,
		message: "server is temporarily down."
	};
}

export const axiosPost = async (path, name, data) => {
	// let apiName = name;
	const myNewInit = myInitHeader(await getSessionData());
	let headers = {
		headers: { ...myNewInit.headers }
	}
	const responseData = await axios.post(`http://localhost:1880/print/buyer/project/search`, data, headers)
	return responseData.data
}

// const handleError = response => {
//     if (response.status === 401) {
//         //handle 401
//     }
//     if (response.status === 202) {
//         //handle 202

//     }
//     if (response.status === 200) {
//         //handle 200

//     }
//     if (response.status === 403) {
//         //handle 403
//     }

//     return response;
// };