import React from "react";
// import "./Footer.css";

const Footer = () => {
	return (
		<div>
			{" "}
			<footer>
				<div className="footer">
					Copyright Ⓒ 2022 Williams Lea. All Rights Reserved.
				</div>
			</footer>
		</div>
	);
};

export default Footer;
