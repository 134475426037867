const region = "us-east-1"

const qa = {
	appSettingsData: {
		BuyerCognitoGroup: 'wl-qa-procurement-portal-buyers',
		SupplierCognitoGroup: 'wl-qa-procurement-portal-suppliers',
		federatedIDPProvider: 'wl-qa-procurement-portal-idp'
	},
	amplify_config: {
		Auth: {
			mandatorySignIn: true,
			region: region,
			userPoolId: "us-east-1_I5eCFTYeO",
			userPoolWebClientId: "186iqd00ik4p1s10prnehlnmda"
		},
		oauth: {
			domain: "wl-qa-procurement-portal.auth.us-east-1.amazoncognito.com",
			scope: [],
			redirectSignIn: "https://wl-qa-procurement-portal.com/login",
			redirectSignOut: "https://wl-qa-procurement-portal.com/login",
			// redirectSignIn: "http://localhost:3000/login",
			// redirectSignOut: "http://localhost:3000/login",
			responseType: "code",
			clientId: "186iqd00ik4p1s10prnehlnmda",
		},
		API: {
			endpoints: [
				{
					name: "print",
					endpoint: "https://api.wl-qa-procurement-portal.com/print",
					region: region
				}
			]
		},
	}
};

export default qa;