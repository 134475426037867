import dev from "./environment.dev";
import prod from "./environment.prod";
import qa from './environment.qa';
import staging from './environment.staging';

const env = process.env.REACT_APP_NODE_ENV;
let APP_CONFIG = env;

switch (env) {
	case 'dev':
		APP_CONFIG = dev
		break;
	case 'qa':
		APP_CONFIG = qa
		break;
	case 'staging':
		APP_CONFIG = staging
		break;
	case 'prod':
		APP_CONFIG = prod
		break;
	default:
		APP_CONFIG = prod
		break;
}
export default APP_CONFIG;