import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Auth, Hub } from "aws-amplify";
import { setUserData, getUserData } from "../../Actions/UserDataActions";
import { loginUser } from "../../Services/Auth/AuthAPI";
import { MESSAGES } from "../../Utils/ErrorMessages";
import { useNavigate } from "react-router-dom";
import Container from "../../Layouts/Common/Container/Container";
import { AuthenticatedBuyerUserHomepage, AuthenticatedSupplierUserHomepage, SupplierCognitoGroup, FederatedIDPProvider } from "../../Utils/Constants";
import { doesUserHasResourceAccess } from '../../Utils/HelperFunctions'
// import "./Login.css";

const Login = (props) => {
	const navigate = useNavigate();

	const [user, setUser] = useState({
		username: null,
		password: null,
		isValid: false,
	});

	

	const [error, Seterrormsg] = useState({
		username: null,
		password: null,
		authErr: null,
	});

	useEffect(() => {
		localStorage.clear()
	}, [])


	useEffect(() => {
		Hub.listen("auth", (data) => {
			switch (data.payload.event) {
				case "signIn":
					// setLoading(true);
					localStorage.setItem("cognitoUser", true);
					localStorage.setItem("isAuthenticated", true);
					Auth.currentSession().then((response) => {
						localStorage.setItem(
							"userData",
							JSON.stringify(response.idToken.payload)
						);
						props.setUserData({ ...response.idToken.payload, isAuthenticated: true })
						if (doesUserHasResourceAccess(SupplierCognitoGroup)) {
							navigate(AuthenticatedSupplierUserHomepage);
						} else {
							navigate(AuthenticatedBuyerUserHomepage);
						}
					});
					// setLoading(false);
					break;
				case "signIn_failure":
					// setLoading(false);
					break;
				default:
					// setLoading(false);
					break;
			}
		});
	});

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (validateUser()) {
			await loginUser(user.username, user.password);
		}
	};

	const FederatedLogin = () => {
		// setLoading(true)
		localStorage.setItem("idpLogin", true);
		Auth.federatedSignIn({ provider: FederatedIDPProvider });
	};

	function onHandleChange(e, name) {
		let userObj = { ...user };
		userObj[name] = e.target.value;
		setUser(userObj);
		Seterrormsg("");
	}

	function validateUser() {
		let isValid = true;
		let userObj = { ...user };
		if (!userObj.username && !userObj.password) {
			Seterrormsg({
				username: MESSAGES.EMAIL_REQUIRED,
				password: MESSAGES.PASSWORD_REQUIRED,
			});
			isValid = false;
		} else if (!userObj.username) {
			Seterrormsg({ username: MESSAGES.EMAIL_REQUIRED });
			isValid = false;
		} else if (!MESSAGES.PATTERN.test(userObj.username)) {
			Seterrormsg({ username: MESSAGES.INVALID_EMAIL });
			isValid = false;
		} else if (!userObj.password) {
			Seterrormsg({ password: MESSAGES.PASSWORD_REQUIRED });
			isValid = false;
		}
		return isValid;
	}

	return (
		<Container pageName={"Login"}>
			<div className="row">
				<div className="col-md-5">
					<p className="Suppliersheading">Suppliers</p>
					
					<form className="loginForm">
						<div className="form-group loginLabel" id="loginLabel">
							<div htmlFor="Email" className="required emailLabel">
								Email
							</div>
							<input
								type="email"
								className="form-control w-100"
								onChange={(e) => onHandleChange(e, "username")}
								required
							/>
							<br />
						</div>
						
						<div className="form-group loginLabel" id="loginLabel">
							<div htmlFor="Password" className="required">
								Password
							</div>
							<input
								type="password"
								className="form-control"
								onChange={(e) => onHandleChange(e, "password")}
								required
							/>
							
						</div>
						
						<p id="ForgetloginLabel"><a href="forgot-password" className="forgetPasswordLink">Forgot password ?</a></p>
					
					
						
						<div id="loginButton">
							<button type="button" className="Loginbutton" onClick={handleSubmit}>
								Login
							</button>
							<p className="signUpTab"><span>Not a member?</span> <span ><a href="/signup" className="SignUpLink">Sign-Up</a></span></p>
						</div>
					</form>
				</div>
				<div className="col-md-2">
					<div className="vertical"></div>
				</div>
				<div className="col-md-5">
					<p className="Buyersheading">Buyers</p>
					
					<div className="intro">
						<p>Please use your organisation account to access Williams lea
						Procurement portal </p>
						<p>
						To Change or reset your password of your organisation account,please
						contact your system administrator or helpdesk
						</p>
						
					</div>
				
					<div className="loginButton">
						<button type="button" onClick={FederatedLogin} className="buyerLoginbutton">
							Login via Organisation
						</button>
					</div>
				</div>
			</div>
		</Container>
	);
};
const mapStateToProps = (state) => {
	return { ...state.userData };
};

const mapDispatchToProps = (dispatch) => {
	return {
		setUserData: (data) => {
			dispatch(setUserData(data));
		},
		getUserData: () => {
			dispatch(getUserData());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
