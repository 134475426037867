import React, { useState } from 'react'
import Container from '../../Layouts/Common/Container/Container'
import { useFormik } from 'formik';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
// import Toast from 'react-bootstrap/Toast'
import { Modal } from 'react-bootstrap';
import {  useNavigate } from "react-router-dom"

const ForgotPassword = () => {
	const navigate = useNavigate();
	const [errorMessage, setErrorMessage] = useState({})
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const closeModalHandle = () => {
		setIsModalVisible(!isModalVisible)
	}

	const handleAuthError = (error) => {
		switch (error.code) {
			case "UsernameExistsException":
				return { message: "An error is encountered while registering your account. Please contact service desk.", title: "User Already Exist" }
			case "InvalidPasswordException":
				return { message: error.message, title: "Invalid Password" }
			default:
				break;
		}
	}

	async function forgotPassword({ email }) {
		try {
			const response = await Auth.forgotPassword(email);
			
			setIsLoading(false)
			navigate(`/forgot-password-verification?emailId=${email}`)
		} catch (error) {
			let errorMessage = handleAuthError(error)
			setIsLoading(false)
			setErrorMessage(errorMessage)
			setIsModalVisible(true)
		}
	}
	const formik = useFormik({
		initialValues: {
			email: ""
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.email("Provide a valid Email ID.")
				.max(64, 'Must be 64 characters or less.')
				.required('Email ID is Required.'),
		}).shape({}),
		validateOnChange: false,
		validateOnBlur: true,
		onSubmit: (values, { setSubmitting }) => {
			setIsLoading(true)
			delete values['confirmPassword']
			forgotPassword(values)
		},
	});

	return (
		<div>
			<Container pageName={"Forgot Password"}>
				<div className="row">
					<div className="signUpCol col-md-6">
						<div className="SignUpform">
							<form onSubmit={formik.handleSubmit} onReset={formik.resetForm}>
								<div className="form-group SignUpformFlexItem" >
									<div className="ForgotPasswordEmailLabel">
									<label htmlFor="Email" className="signUpLabel required ">Email ID</label>
									<input type="text" className={formik.touched.email && formik.errors.email ? "form-control is-invalid" : "form-control"} {...formik.getFieldProps('email')} />
									<div className="invalid-feedback">
										{formik.touched.email && formik.errors.email}
									</div>
									</div>
								</div>
								<div className="SignUpButtonRow" >
									<button type="submit" disabled={isLoading} className="signUpButton">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<Modal
					show={isModalVisible}
					onHide={closeModalHandle}
					backdrop="static"
					keyboard={false}
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
				// centered
				>
					<Modal.Header closeButton>
						<img
							src="holder.js/20x20?text=%20"
							className="rounded me-2"
							alt=""
						/>
						{errorMessage.title}
					</Modal.Header>
					<Modal.Body>{errorMessage.message}</Modal.Body>
				</Modal>
			</Container>
		</div>
	)
}

export default ForgotPassword