import React from 'react'
import Container from '../Common/Container/Container'
import { timeStamp } from '../../Utils/HelperFunctions';

const Error =()=>{
	const date= new Date();
	
    return (
		<div>
			<Container pageName={"Error"}>
			<p className="errMsg">An error is encountered, while processing your request at  {(timeStamp(date))} . Please contact the system administrator.</p>
			</Container>
		</div>
	)
}

export default Error;