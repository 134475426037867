const MESSAGES = {
	PATTERN: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	EMAIL_REQUIRED: "Email is required",
	INVALID_EMAIL: "Please enter valid email",
	PASSWORD_REQUIRED: "Password is required",
	CONFIRM_PASSWORD: "Confirm password is required",
	CODE_REQUIRED: "Confirmation code is required",
	INVALID_CONFIRM_PASSWORD: "Confirm password does not match",
	PASSWORD_LENGTH: "Password must contain a minimum of 8 characters.",
	PASSWORD_RESTRICTION: "Password must have minimum 8 characters and be a combination of uppercase letters,lowercase letters,special characters and numbers.",
	INVALID_CREDENTIALS: "Incorrect username or password.",
	CODE_RESTRICTION: "Please enter valid confirmation code",
	// PASSWORD_PATTERN: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
	PASSWORD_PATTERN: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{6,99}$/,
	// CREATE-NEW-PROFILE  starts here
	FEE_EARNER_REQUIRED: "Please select a Fee Earner",
	SUPERVISOR_REQUIRED: "Please select a supervisor",
	ALLOCATOR_REQUIRED: "Please select a allocator",
	NAME_REQUIRED: "Please Enter Profile Name",
	TEAM_REQUIRED: "Please select a Team",
	INTERNATIONALcLAIM_REQUIRED: "Please select a international team from the drop down menu",
	MAXVAL_RESTRICTION: "Maximum value must be greater than minimum value",
	PROFILE_WORKTYPECODE: "Please add work type code",
	PROFILE_SUB_CATOGORIES: "Please add  sub catogories",
	FORENAME_REQUIRED: "Forename is required",
	SURNAME_REQUIRED: "Surname is required",
	CLIENT_MAXLENGTH: "Permitted number exceeded. Exclude non-relevant clients instead.",
	NOTES: "Allowed 4098 chars exceeded.",
	JURIDICTION_ERROR: "Please choose atleast one Jurisdiction"
}
export {
	MESSAGES
}