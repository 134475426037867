import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UnAuthenticatedUserHomepage } from "./../Utils/Constants"
import { doesUserHasResourceAccess } from "./../Utils/HelperFunctions"

const ProtectedRoute = ({ roleGroup, failureUrl }) => {
	const isAuthenticated = localStorage.getItem("cognitoUser");
	return (isAuthenticated ? doesUserHasResourceAccess(roleGroup) ? <Outlet /> : <Navigate to={failureUrl} /> : <Navigate to={UnAuthenticatedUserHomepage} />);
}

export default ProtectedRoute;