
const initialData = {
	isAuthenticated: false
}

const UserDataReducer = (state = initialData, action) => {
	switch (action.type) {
		case "SET_USER_DATA":
			return { ...state, ...action.payload };
		default:
			return state;
	}
}

export default UserDataReducer;