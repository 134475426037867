import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Container from "../../../../Layouts/Common/Container/Container";

import { getSupplierListOfProjects } from "../../../../Actions/SupplierJourneyActions";
import { dateFormatter } from "../../../../Utils/HelperFunctions";
import ReactPaginate from "react-paginate";
import ClipLoader from "react-spinners/ClipLoader";

export const SuppliersListProjects = ({
	getSupplierListOfProjects,
	supplierProjectListPage,
}) => {
	const [searchData, setSearchData] = useState({
		SearchBy: " ",
		PageNum: 1,
		PageSize: "20",
	});
	const [order, setOrder] = useState({
		sortColumn: "completionDate",
		ascending: true,
	});
const initialSearchValue ={
		SearchBy: "",
			PageNum: 1,
			PageSize: "20",JobStatus:"",ProjectStatus:""
	}
	
	useEffect(() => {
		async function runOnceIfSearchDataChanges() {
			await getSupplierListOfProjects(searchData);
		}
		runOnceIfSearchDataChanges();
	},[]);

	// Load Data when Page Number or Page Size changes
	useEffect(() => {
		getSupplierListOfProjects(searchData);
	}, [searchData.PageSize, searchData.PageNum]);

	const handleChange = async (event) => {
		let searchObj = { ...searchData };
		if (event.target.value === "null") {
			delete searchObj[event.target.name];
		} else {
			searchObj[event.target.name] = event.target.value;
		}
		await setSearchData(searchObj);
	};
	const searchButtonOnClickHandler = async () => {
		getSupplierListOfProjects(searchData);
	};
	const sorting = async (field) => {
		let sortValue = {
			...order,
			sortColumn: field,
			ascending: !order.ascending,
			...searchData,
		};
		await setOrder(sortValue);
		getSupplierListOfProjects(sortValue);
	};

	const calculatePagesCount = (pageSize, totalCount) => {
		// we suppose that if we have 0 items we want 1 empty page
		return Math.ceil(
			totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize)
		);
	};

	const handlePageChange = (data) => {
		setSearchData({ ...searchData, PageNum: data.selected + 1 });
	};

	async function clearFilter(){
		 
			
		getSupplierListOfProjects(initialSearchValue)
		setSearchData(initialSearchValue)
	
}
	return (
		<div>
			<Container pageName={"Projects"}>
				<div className="sellerProjectList row">
					<div className=" Search col-md-4">
						<span className="SearchLabel">Search by</span>
						<input
							type="search"
							onChange={handleChange}
							className="form-control rounded "
							placeholder="Either Title, ISBN, Owner, Client or Client Reference"
							aria-label="Search"
							aria-describedby="search-addon"
							name="SearchBy"
							value={searchData.SearchBy}
						/>
					</div>
					<div className="col-md-1">
						<div className="row">
							<div className="col-md-8">
							<button
							type="button"
							onClick={searchButtonOnClickHandler}
							className="sellerSearchButton"
						>
							Search
						</button>
							</div>
							<div className="col-md-3">
							<div >
					<div type="button" 
							className="clearFilterLinkSupplier" onClick={clearFilter}><u>Clear Filters</u></div>
					</div>
							</div>
						</div>
						
					</div>
				
				</div>
				{supplierProjectListPage.isDataLoaded ? (
					<div className="sellerlistOfProjectstable">
						<table className="table table-bordered">
							<thead className="thead">
								<tr>
									<th
										className="title tableHeading"
										name="title"
										onClick={() => sorting("title")}
									>
										Title{" "}
										{order.sortColumn === "title" ? (
											<i
												className={
													order.ascending
														? "sortIcon  bi bi-arrow-up "
														: "sortIcon bi bi-arrow-down "
												}
											></i>
										) : null}{" "}
									</th>
									<th
										className="ISBN tableHeading"
										onClick={() => sorting("isbn")}
									>
										ISBN
										{order.sortColumn === "isbn" ? (
											<i
												className={
													order.ascending
														? "sortIcon bi bi-arrow-up "
														: "sortIcon bi bi-arrow-down "
												}
											></i>
										) : null}
									</th>
									<th
										className="Owner tableHeading"
										onClick={() => sorting("buyer")}
									>
										Owner
										{order.sortColumn === "buyer" ? (
											<i
												className={
													order.ascending
														? "sortIcon bi bi-arrow-up "
														: "sortIcon bi bi-arrow-down "
												}
											></i>
										) : null}
									</th>
									<th
										className="client tableHeading"
										name="sortColumn"
										onClick={() => sorting("client")}
									>
										Client
										{order.sortColumn === "client" ? (
											<i
												className={
													order.ascending
														? "sortIcon bi bi-arrow-up "
														: "sortIcon bi bi-arrow-down "
												}
											></i>
										) : null}
									</th>
									<th
										className="Client Refference tableHeading"
										onClick={() =>
											sorting("clientReference")
										}
									>
										Client Reference
										{order.sortColumn ===
										"clientReference" ? (
											<i
												className={
													order.ascending
														? "sortIcon bi bi-arrow-up "
														: "sortIcon bi bi-arrow-down "
												}
											></i>
										) : null}
									</th>
									<th
										className="CompletionTime tableHeading"
										onClick={() =>
											sorting("completionDate")
										}
									>
										Completion Date
										{order.sortColumn ===
										"completionDate" ? (
											<i
												className={
													order.ascending
														? "sortIcon bi bi-arrow-up "
														: "sortIcon bi bi-arrow-down "
												}
											></i>
										) : null}
									</th>
									<th
										className="Status tableHeading"
										onClick={() => sorting("status")}
									>
										Status
										{order.sortColumn === "status" ? (
											<i
												className={
													order.ascending
														? "sortIcon bi bi-arrow-up "
														: "sortIcon bi bi-arrow-down "
												}
											></i>
										) : null}
									</th>
								</tr>
							</thead>
							<tbody id="ProjectData">
								{supplierProjectListPage.isDataLoaded ? (
									supplierProjectListPage.totalRecords > 0 ? (
										supplierProjectListPage.data.map(
											(project, idx) => {
												let projectURL = `/print/supplier/project/${project.id}`;
												return (
													<tr key={project.id}>
														<td>
															<u>
																<a
																	className="projectTitleLink"
																	href={projectURL}
																		
																	
																>
																	{
																		project.title
																	}
																</a>
															</u>
														</td>
														<td>{project.isbn}</td>
														<td>{project.buyer}</td>
														<td>
															{project.client}
														</td>
														<td>
															{
																project.clientReference
															}
														</td>
														<td>
															{dateFormatter(
																project.completionDate
															)}
														</td>
														<td>
															{project.status}
														</td>
													</tr>
												);
											}
										)
									) : (
										<tr>
											<td
												className="NoProjectDetails"
												colspan="7"
											>
												There are no projects available.
											</td>
										</tr>
									)
								) : (
									""
								)}
							</tbody>
						</table>
					</div>
				) : (
					<div className="supplierlistOfProjectsLoader">
						<ClipLoader
							color={"#008080"}
							size={50}
							aria-label="Loading Spinner"
							data-testid="loader"
						/>
					</div>
				)}

				<div className="row">
					<div className="col-md-5 pageSize">
						<select
							className="selectbuyer pageSizedropdownbtn dropdown-toggle form-select"
							name="PageSize"
							value={searchData.PageSize}
							onChange={handleChange}
						>
							<option value="10">10</option>
							<option value="15">15</option>
							<option value="20">20</option>
							<option value="25">25</option>
						</select>
					</div>
					<div className="col-md-7 pageNumber">
						<ReactPaginate
							previousLabel={"<"}
							nextLabel={">"}
							breakLabel={"..."}
							pageCount={calculatePagesCount(
								searchData.PageSize,
								supplierProjectListPage.totalRecords || 0
							)}
							onPageChange={handlePageChange}
							marginPagesDisplayed={2}
							pageRangeDisplayed={3}
							containerClassName={"pagination"}
							pageClassName={"page-item"}
							pageLinkClassName={"page-link"}
							previousClassName={"page-item"}
							previousLinkClassName={"page-link"}
							nextClassName={"page-item"}
							nextLinkClassName={"page-link"}
							breakClassName={"page-item"}
							breakLinkClassName={"page-link"}
							activeClassName={"active"}
						/>
					</div>
				</div>
			</Container>
		</div>
	);
};

const mapStateToProps = (state) => {
	return { ...state.supplierData };
};

const mapDispatchToProps = (dispatch) => {
	return {
		getSupplierListOfProjects: (searchData) => {
			dispatch(getSupplierListOfProjects(searchData));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SuppliersListProjects);
