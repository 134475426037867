import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router";
import BidSelectionConfirmation from "./BidSelectionConfirmation";
import ClipLoader from "react-spinners/ClipLoader";
import Container from "../../../../Layouts/Common/Container/Container";


import {
	getSupplierList,
	getBidsList,
	// createBidInvites,
	setBidInviteData,
	getBidResponse,
} from "../../../../Actions/BuyerJourneyActions";
// import "./BuyerManageBids.css"

export const BuyerManageBids = ({
	getSupplierList,
	supplierList,
	getBidsList,
	bidsList,
	setBidInviteData,
	isCreateSuccessful,
	getBidResponse,
	buyerGetBidResponse,

	buyerConfirmSupplier,
}) => {
	const { projectId, jobId } = useParams();

	const [bidInvite, setBidInvite] = useState({});
	// const [bidResponse, setBidResponse] = useState("");
	const [confirmedSupplierValue, setConfirmedSupplierValue] = useState("");
	const [confirmedQtyValue, setConfirmedQtyValue] = useState("");
	const [confirmedSupplierName, setConfirmedSupplierName] = useState("");

	const navigate = useNavigate();
	const [selectedSupplier, setSelectedSupplier] = useState({});
	const [inputQuantityBreakup, setInputQuantityBreakup] = useState("");
	const [isShowBidSelectConfirmation, setIsShowBidSelectConfirmation] =
		useState(false);
	const [qtyBreakUpError,setQtyBreakUpError]=useState({isValid:true,isQtyNotANumber:true});
	const[supplierSelectionError,setSupplierSelectionError]=useState({isValid:true});
	// const [enableBidInvite,setEnableBidInvite]=useState(false);

	useEffect(() => {
		setBidInvite(bidsList);
	}, [bidsList]);

	useEffect(() => {
		getBidsList({ jobId, projectId });
		getSupplierList();
		getBidResponse({ jobId, projectId });
	}, []);




	useEffect(() => {
		getBidsList({ jobId, projectId });
		getSupplierList();
	}, [isCreateSuccessful]);

	function supplierOnChangeHandler(e) {
		// userObj[name] = e.target.value;
		if (supplierList.data[e.target.selectedIndex - 1] !== undefined) {
			setSelectedSupplier(supplierList.data[e.target.selectedIndex - 1]);
		}
	}

	function addSupplier() {
		if (
			selectedSupplier.name !== "" &&
			typeof selectedSupplier.name !== "undefined"
		) {
			let isValid=true;
			setSupplierSelectionError({isValid});
			let modifiedSuppliers = new Set([
				...bidInvite.data.suppliers,
				selectedSupplier,
			]);
			setBidInvite({
				...bidInvite,
				data: {
					suppliers: [...modifiedSuppliers],
					qtyBreakups: bidInvite.data.qtyBreakups,
				},
			});
			setSelectedSupplier({ name: "" });
		}
		else{
			let isValid=false;
			let errorMsg="Please select a supplier.";
			setSupplierSelectionError({isValid,errorMsg});
			

		}
	}

	function addQuantityBreakup() {
		if(bidInvite.data.qtyBreakups.length <6){
			
			if (inputQuantityBreakup !== "" ) {
			let inputQtyVal=parseInt(inputQuantityBreakup);
			
				if( !isNaN(inputQtyVal) ){
					let isQtyNotANumber=true;
					setQtyBreakUpError({
						isQtyNotANumber,
					})
					let modifiedQuantityBreakup = new Set([
						...bidInvite.data.qtyBreakups,
						inputQuantityBreakup,
					]);
					setBidInvite({
						...bidInvite,
						data: {
							suppliers: bidInvite.data.suppliers,
							qtyBreakups: [...modifiedQuantityBreakup],
						},
					});
					setInputQuantityBreakup("");
				}
					else{
						let isQtyNotANumber=false;
						let errorMsg ="Please provide a valid number.";
						setQtyBreakUpError({
							errorMsg,isQtyNotANumber,
						})
					}
			}
		}
		else{
			let errorMsg ="Max allowed quantity break-up is 6.";
			let isValid=false;
			setQtyBreakUpError({
				errorMsg,isValid,
			})
		}
		
	}

	function removeQuantityBreakup(id) {
		let modifiedQuantityBreakup = [...bidInvite.data.qtyBreakups];
		modifiedQuantityBreakup.splice(id, 1);
		setBidInvite({
			...bidInvite,
			data: {
				suppliers: bidInvite.data.suppliers,
				qtyBreakups: [...modifiedQuantityBreakup],
			},
		});
	}

	function removeSupplier(id) {
		let modifiedSupplier = [...bidInvite.data.suppliers];
		modifiedSupplier.splice(id, 1);
		setBidInvite({
			...bidInvite,
			data: {
				suppliers: [...modifiedSupplier],
				qtyBreakups: bidInvite.data.qtyBreakups,
			},
		});
	}

	function saveBids(bidInvite) {
		setBidInviteData({ ...bidInvite, projectId, jobId });
		navigate(`/print/buyer/project/${projectId}/update`);
	}

	function confirmBidSelection() {
		if (confirmedSupplierValue !== "" && confirmedQtyValue !== "") {
			setIsShowBidSelectConfirmation(!isShowBidSelectConfirmation);
		}
	}

	function selectedSupplierFunction(event) {
		setConfirmedSupplierValue(event.target.value);
		setConfirmedSupplierName(
			event.target.options[event.target.selectedIndex].label
		);
	}
	
	

	const updatePageURL = `/print/buyer/project/${projectId}/update`;
	
	return (
		<div>
			{bidsList.isDataLoaded ? (
				<Container
					pageName={`${bidsList.data.projectTitle} - ${bidsList.data.jobName}`}
				>
					<div className="manageBidMajorBid row">
						
						<div className="BIDINVITATION col-md-3">
							<h3 className="Heading">BID INVITATION SENT</h3>
						
							<div className="row">
								<div className=" row">
								<label className="required">Quantity Break-Up</label>
									<div className="col-md-10">
										<input
											maxLength="15"
											type="text"
											className="inputBreakUpQuantity form-control"
											value={inputQuantityBreakup}
											onChange={(e) => {
												setInputQuantityBreakup(
													e.target.value
												);
											}}
											
										/>
										
									</div>
									<div className=" iconCol col-md-2">
										<button
											type="button"
											className="PlusIconBtn btn "
											onClick={addQuantityBreakup}
											id="submit"
										>
											<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="teal" className="bi bi-plus-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
</svg>
										</button>
								
									</div>
								</div>
								</div>
							
							<div className="qtyBreakupTablerow">
								<table className="table table-bordered">
									<thead className="thead-dark">
										<tr>
											<th className="qty" scope="col">
												Selected Quantity Breakup
											</th>
											<th
												className="removecol"
												scope="col"
											></th>
										</tr>
									</thead>
									<tbody className="qtyBreakupTableData">
										{bidInvite.isDataLoaded
											? bidInvite.data.qtyBreakups
													.length !== 0
												? bidInvite.data.qtyBreakups.map(
														(quantity, idx) => {
															return (
																<tr key={idx} >
																	<td className="bidInvitationTableCell" >
																		{
																			quantity
																		}
																	</td>
																	<td className="remove bidInvitationTableCell">
																		<i
																			className="bi bi-x-lg"
																			onClick={() => {
																				removeQuantityBreakup(
																					idx
																				);
																			}}
																		/>
																	</td>
																</tr>
															);
														}
												  )
												: <tr className="NoQtyDetails">
													<td>Quantity breakup is not defined.</td>
													<td></td>
												</tr>
											: null}
											
									</tbody>
								</table>
								{!qtyBreakUpError.isValid || !qtyBreakUpError.isQtyNotANumber?
								<div className="qtyBreakUpError">{qtyBreakUpError.errorMsg}</div>:null
								}
								
							</div>
							
							<div className="row">
								<label className="required">Suppliers</label>
								<br></br>
								<div className=" row">
								<div className="col-md-10">
									<select
										className="manageBidselectSupplier  dropdown-toggle form-select"
										value={selectedSupplier.name}
										onChange={supplierOnChangeHandler}
									>
										<option value="" default>
											Please Select
										</option>
										{supplierList.isDataLoaded
											? supplierList.data.map(
													(supplier, idx) => {
														return (
															<option
																key={idx}
																value={
																	supplier.name
																}
																default
															>
																{supplier.name}
															</option>
														);
													}
											  )
											: ""}
									</select>
								
								</div>
								<div className="iconCol col-md-2">
									<button
										type="button"
										className="PlusIconBtn btn "
										id="submit"
										onClick={addSupplier}
									>
	<svg xmlns="http://www.w3.org/2000/svg" width="30" height="35" fill="teal" className="bi bi-plus-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
</svg>
									</button>
								</div>
								</div>
							</div>
							<div className="supplierSelectionList">
								<table className="qtyBreakupTable table table-bordered">
									<thead className="thead-dark">
										<tr>
											<th className="qty" scope="col">
												Suppliers Requested for Quote
											</th>
											<th
												className="removecol"
												scope="col"
											></th>
										</tr>
									</thead>
									<tbody id="table">
										{bidInvite.isDataLoaded
											? bidInvite.data.suppliers
													.length !== 0
												? bidInvite.data.suppliers.map(
														(supplier, idx) => {
															return (
																<tr key={idx}>
																	<td className="bidInvitationTableCell">
																		{
																			supplier.name
																		}
																	</td>
																	<td className="remove bidInvitationTableCell">
																		<i
																			className="bi bi-x-lg"
																			onClick={() => {
																				removeSupplier(
																					idx
																				);
																			}}
																		/>
																	</td>
																</tr>
															);
														}
												  )
												: <tr className="NoSupplierDetails">
												<td className="bidInvitationTableCell">Suppliers are not available.
</td>
												<td></td>
											</tr>
											: null}
									</tbody>
								</table>
								{!supplierSelectionError.isValid?
								<div className="supplierListErrorMsg">{supplierSelectionError.errorMsg}</div>:null}
							</div>
							
							{bidsList.data.jobStatus === "Live" &&
							bidsList.data.projectStatus === "Live" && bidInvite.isDataLoaded ? (
								<div className="BitInvsubmitButtonrow">
									<button
										type="submit"
										className="BitInvSavebutton"
										onClick={() => saveBids(bidInvite)}
										disabled={ bidInvite.data.qtyBreakups.length >6 || bidInvite.data.suppliers.length ===0 ||  bidInvite.data.qtyBreakups.length ===0}
									>
										Save
									</button>
									<button
										type="button"
										className="BitInvCancelbutton"
										onClick={() =>
											navigate(
												`/print/buyer/project/${projectId}/update`
											)
										}
									>
										Cancel
									</button>
								</div>
							) : (
								<div className="BitInvsubmitButtonrow">
									<button
										type="button"
										className="BitInvCancelbutton"
										onClick={() =>
											navigate(
												`/print/buyer/project/${projectId}/update`
											)
										}
									>
										Cancel
									</button>
								</div>
							)}
							
						</div>
						
						<div className="bidInvitationRecieved col-md-9">
							<h3 className="Heading">BID RESPONSE RECEIVED</h3>
							{buyerGetBidResponse.isDataLoaded ?
							<div className="bidInvitation row">
								<div className="bidResponsiveTable table">
								<table className="bidInvitationTable table table-bordered ">
												<thead className="thead">
													<tr >
														<th
															
															className="ManageBidBidResponseTableName  tableheading"
														>
															{
																buyerGetBidResponse
																	.data
																	.qtyHeading
																	.name
															}
														</th>
														{buyerGetBidResponse
															.data
															.qtyAvailability
															.isQty1Available ===
														true ? (
															<th
																
																className="ManageBidBidResponseTableHeading tableheading"
															>
																{
																	buyerGetBidResponse
																		.data
																		.qtyHeading
																		.qty1Name
																}
															</th>
														) : <th className="BidResponseHeading"></th>}
														{buyerGetBidResponse
															.data
															.qtyAvailability
															.isQty2Available ===
														true ? (
															<th
																scope="col"
																className="ManageBidBidResponseTableHeading tableheading"
															>
																{
																	buyerGetBidResponse
																		.data
																		.qtyHeading
																		.qty2Name
																}
															</th>
														) : <th className="BidResponseHeading"></th>}
														{buyerGetBidResponse
															.data
															.qtyAvailability
															.isQty3Available ===
														true ? (
															<th
																scope="col"
																className=" ManageBidBidResponseTableHeading tableheading"
															>
																{
																	buyerGetBidResponse
																		.data
																		.qtyHeading
																		.qty3Name
																}
															</th>
														) : <th className="BidResponseHeading"></th>}
														{buyerGetBidResponse
															.data
															.qtyAvailability
															.isQty4Available ===
														true ? (
															<th
																scope="col"
																className="ManageBidBidResponseTableHeading tableheading"
															>
																{
																	buyerGetBidResponse
																		.data
																		.qtyHeading
																		.qty4Name
																}
															</th>
														) : <th className="BidResponseHeading"></th>}
														{buyerGetBidResponse
															.data
															.qtyAvailability
															.isQty5Available ===
														true ? (
															<th
																scope="col"
																className="ManageBidBidResponseTableHeading tableheading"
															>
																{
																	buyerGetBidResponse
																		.data
																		.qtyHeading
																		.qty5Name
																}
															</th>
														) : <th className="BidResponseHeading"></th>}
														{buyerGetBidResponse
															.data
															.qtyAvailability
															.isQty6Available ===
														true ? (
															<th
																scope="col"
																className="ManageBidBidResponseTableHeading tableheading"
															>
																{
																	buyerGetBidResponse
																		.data
																		.qtyHeading
																		.qty6Name
																}
															</th>
														) : <th className="BidResponseHeading"></th>}
													</tr>
												</thead>
												<tbody>
									{buyerGetBidResponse.isDataLoaded 
									 ? (
										buyerGetBidResponse.data.brCostsByQuantities
										.length !== 0 ? (
										<>
												{buyerGetBidResponse.data.brCostsByQuantities.map(
													
													(bidRes, idx) => {
														
														return (
															<tr key={idx}>
																<th
																	scope="row"
																	className="suppliertitle"
																>
																	{
																		bidRes.supplierName
																	}
																</th>
																{buyerGetBidResponse
																	.data
																	.qtyAvailability
																	.isQty1Available ===
																true ? (
																	<th
																		id={
																			bidRes
																				.qty1Detail
																				.isSelected ===
																			true
																				? "selectedCell"
																				: " "
																		}
																	>
																		{
																			bidRes
																				.qty1Detail
																				.cost
																		}
																	</th>
																) : <td></td>}
																{buyerGetBidResponse
																	.data
																	.qtyAvailability
																	.isQty2Available ===
																true ? (
																	<td
																		id={
																			bidRes
																				.qty2Detail
																				.isSelected ===
																			true
																				? "selectedCell"
																				: " "
																		}
																	>
																		{
																			bidRes
																				.qty2Detail
																				.cost
																		}
																	</td>
																) : <td></td>}
																{buyerGetBidResponse
																	.data
																	.qtyAvailability
																	.isQty3Available ===
																true ? (
																	<td
																		id={
																			bidRes
																				.qty3Detail
																				.isSelected ===
																			true
																				? "selectedCell"
																				: " "
																		}
																	>
																		{
																			bidRes
																				.qty3Detail
																				.cost
																		}
																	</td>
																) : <td></td>}
																{buyerGetBidResponse
																	.data
																	.qtyAvailability
																	.isQty4Available ===
																true ? (
																	<td
																		id={
																			bidRes
																				.qty4Detail
																				.isSelected ===
																			true
																				? "selectedCell"
																				: " "
																		}
																	>
																		{
																			bidRes
																				.qty4Detail
																				.cost
																		}
																	</td>
																) : <td></td>}
																{buyerGetBidResponse
																	.data
																	.qtyAvailability
																	.isQty5Available ===
																true ? (
																	<td
																		id={
																			bidRes
																				.qty5Detail
																				.isSelected ===
																			true
																				? "selectedCell"
																				: " "
																		}
																	>
																		{
																			bidRes
																				.qty5Detail
																				.cost
																		}
																	</td>
																) : <td></td>}
																		{buyerGetBidResponse
																	.data
																	.qtyAvailability
																	.isQty6Available ===
																true ? (
																	<td
																		id={
																			bidRes
																				.qty6Detail
																				.isSelected ===
																			true
																				? "selectedCell"
																				: " "
																		}
																	>
																		{
																			bidRes
																				.qty6Detail
																				.cost
																		}
																	</td>
																) : <td></td>}
															</tr>
														);
													}
												)}
												</>
											
										) :
									
										<tr>
									<td
										className="NoProjectDetails"
										colSpan="8"
									>
										There are no quantity break-up or suppliers defined.{" "}
									</td>
									</tr>
								
									
								
								
									) : (
										
										<div className="listOfProjectsLoader">
										<ClipLoader
											color={"#008080"}
											size={50}
											aria-label="Loading Spinner"
											data-testid="loader"
										/>
									</div>
									)}
									</tbody>
										</table>
								</div>
							</div>:
							" "
         }

							{buyerGetBidResponse.data !== null &&
							buyerGetBidResponse.isDataLoaded ? (
								<>
								<div className="SelectedSupplierAndQuantityCol">
									<div className="selectedSuppliers">
										<label>Selected Suppliers</label>

										<select
											className=" manageBidSelectSupplierDropDown dropdown-toggle form-select"
											value={confirmedSupplierValue}
											onChange={(event) => {
												selectedSupplierFunction(event);
											}}
										>
											<option value="" disabled>
												Please Select
											</option>
											{buyerGetBidResponse.isDataLoaded
												? buyerGetBidResponse.data.suppliers.map(
														(supplier, idx) => {
															return (
																<option
																	key={idx}
																	value={
																		supplier.id
																	}
																	name={
																		supplier.name
																	}
																	default
																>
																	{
																		supplier.name
																	}
																</option>
															);
														}
												  )
												: ""}
										</select>
									</div>
									<div className="selectedQuantity">
										<label>Selected Quantity</label>

										<select
											className=" manageBidSelectQtyDropDown dropdown-toggle form-select"
											value={confirmedQtyValue}
											onChange={(event) =>
												setConfirmedQtyValue(
													event.target.value
												)
											}
										>
											<option value="" disabled>
												Please Select
											</option>
											{bidsList.isDataLoaded
												? bidsList.data.qtyBreakups.map(
														(qty, idx) => {
															return (
																<option
																	key={idx}
																	value={qty}
																	default
																>
																	{qty}
																</option>
															);
														}
												  )
												: ""}
										</select>
									</div>
									</div>
									{bidsList.data.jobStatus === "Live" &&
							bidsList.data.projectStatus === "Live" && buyerGetBidResponse.data.suppliers.length !== 0?
							<div className="manageBidsubmitButtonrow">
							<button
								type="button"
								className="manaageBidCompletebutton"
								onClick={() =>
									confirmBidSelection()
								}
							>
								Confirm Supplier
							</button>
					
							<button
								type="button"
								className="manageBidCancelCancelbutton"
								onClick={() =>
									navigate(updatePageURL)
								}
							>
								Cancel
							</button>
						</div>:
						<div className="manageBidsubmitButtonrow">
						<button
							type="button"
							className="manageBidCompletedprojectCancelCancelbutton"
							onClick={() =>
								navigate(updatePageURL)
							}
						>
							Cancel
						</button>
					</div>}
									
								</>
								
							) : null}
                              
							<BidSelectionConfirmation
								isShowBidSelectConfirmation={
									isShowBidSelectConfirmation
								}
								confirmBidSelection={confirmBidSelection}
								confirmedSupplierValue={confirmedSupplierValue}
								confirmedQtyValue={confirmedQtyValue}
								jobId={jobId}
								projectId={projectId}
								projectTitle={bidsList.data.projectTitle}
								jobName={bidsList.data.jobName}
								confirmedSupplierName={confirmedSupplierName}
							/>
						</div>
					</div>
				</Container>
			) : (
				<div className="listOfProjectsLoader">
					<ClipLoader
						color={"#008080"}
						size={50}
						aria-label="Loading Spinner"
						data-testid="loader"
					/>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return { ...state.buyerData };
};

const mapDispatchToProps = (dispatch) => {
	return {
		getSupplierList: () => {
			dispatch(getSupplierList());
		},
		getBidsList: (data) => {
			dispatch(getBidsList(data));
		},
		setBidInviteData: (data) => {
			dispatch(setBidInviteData(data));
		},
		getBidResponse: (data) => {
			dispatch(getBidResponse(data));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyerManageBids);
