export const dateFormatter = (inputDate, isReadOnly = true) => {
	//TODO: Convert to date format
	inputDate = new Date(inputDate)

	try {
		let date, month, year,hour;
		date = inputDate.getDate();
		month = inputDate.getMonth() + 1;
		year = inputDate.getFullYear();
		date = date.toString().padStart(2, '0');
		month = month.toString().padStart(2, '0');
		if (isReadOnly) {
			return `${date}/${month}/${year}`;
		}
		else {
			return `${year}-${month}-${date}`;
		}
	} catch (error) {
		return ""
	}
};

export const timeStamp=(inputDate)=>{
	inputDate = new Date(inputDate)
	
		let date, month, year,hour,minute;
		date = inputDate.getDate();
		month = inputDate.getMonth() + 1;
		year = inputDate.getFullYear();
		hour =inputDate.getHours()
		minute=inputDate.getMinutes()
		date = date.toString().padStart(2, '0');
		month = month.toString().padStart(2, '0');
		
			return `${date}/${month}/${year} ${hour}:${minute}`;
		
		
	
};

export const doesUserHasResourceAccess = (roleGroup) => {
	try {
		return JSON.parse(localStorage.getItem("userData"))[
			"cognito:groups"
		].includes(roleGroup);
	} catch (error) {
		return false;
	}
};

export const tableHeading = (idx) => {
	const headerStyle = "sellersviewPageHeading";
	const qtyStyle = "sellersviewPageHeadingName";
	if (idx === 0) {
		return headerStyle;
	} else {
		return " ";
	}
};
