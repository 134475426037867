export const getBuyerListOfProjects = (payload) => {
	return {
		type: "GET_BUYER_PROJECT_LIST",
		payload
	}
}

export const createBuyerProject = (payload) => {
	return {
		type: "CREATE_BUYER_PROJECT",
		payload
	}
}

export const createBuyerProjectPageInitialize = (payload) => {
	return {
		type: "CREATE_BUYER_PROJECT_PAGE_INITIALIZE",
		payload
	}
}

export const updateBuyerProjectPageInitialize = (payload) => {
	return {
		type: "UPDATE_BUYER_PROJECT_PAGE_INITIALIZE",
		payload
	}
}

export const updateBuyerProject = (payload) => {
	return {
		type: "UPDATE_BUYER_PROJECT",
		payload
	}
}

export const getBuyerProject = (payload) => {
	return {
		type: "GET_BUYER_PROJECT",
		payload
	}
}

export const createBuyerJob = (payload) => {
	return {
		type: "CREATE_BUYER_JOB",
		payload
	}
}

export const createBuyerJobPageInitialize = (payload) => {
	return {
		type: "CREATE_BUYER_JOB_PAGE_INITIALIZE",
		payload
	}
}

export const updateBuyerJobPageInitialize = (payload) => {
	return {
		type: "UPDATE_BUYER_JOB_PAGE_INITIALIZE",
		payload
	}
}

export const getBuyerJob = (payload) => {
	return {
		type: "GET_BUYER_JOB",
		payload
	}
}

export const getBuyerJobs = (payload) => {
	return {
		type: "GET_BUYER_JOBS",
		payload
	}
}

export const updateBuyerJob = (payload) => {
	return {
		type: "UPDATE_BUYER_JOB",
		payload
	}
}

export const getSupplierList = (payload) => {
	return {
		type: "GET_SUPPLIER_LIST",
		payload
	}
}

export const getBidsList = (payload) => {
	return {
		type: "GET_BIDS_LIST",
		payload
	}
}

export const createBidInvite = (payload) => {
	return {
		type: "CREATE_BUYER_BID_INVITE",
		payload
	}
}


export const setBidInviteData = (payload) => {
	return {
		type: "SET_BUYER_BID_INVITE",
		payload
	}
}

export const getBidResponse = (payload) => {
	return {
		type: "GET_BUYER_BID_RESPONSE",
		payload
	}
}

export const ConfirmSupplier = (payload) => {
	return {
		type: "SET_CONFIRM_SUPPLIER",
		payload
	}
}

export const abortProject = (payload) => {
	return {
		type: "ABORT_PROJECT",
		payload
	}
}

export const completeProject = (payload) => {
	return {
		type: "CONFIRM_PROJECT",
		payload
	}
}

export const abortJob = (payload) => {

	return {
		type: "ABORT_JOB",
		payload
	}
}
export const getClientList = (payload) => {

	return {
		type: "GET_CLIENT_LIST",
		payload
	}
}

export const getBuyerList = (payload) => {

	return {
		type: "GET_BUYER_LIST",
		payload
	}
}