import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router";
import * as Yup from "yup";
import { useFormik } from "formik";
import ClipLoader from "react-spinners/ClipLoader";


// import "./BuyerUpdateJob.css";
import {
	updateBuyerJob,
	getBuyerProject,
	getBuyerJob,
	updateBuyerJobPageInitialize,
	abortJob,
} from "../../../../Actions/BuyerJourneyActions";
import Container from "../../../../Layouts/Common/Container/Container";
import { PageSize, Proofs, DeliveryAddress } from "../../../../Utils/Constants";
import { dateFormatter } from "../../../../Utils/HelperFunctions";

export const BuyerUpdateJob = ({
	getBuyerProject,
	buyerProjectDetails,
	getBuyerJob,
	buyerJobDetails,
	updateBuyerJob,
	buyerUpdateJobResponse,
	updateBuyerJobPageInitialize,
	abortJob,

}) => {
	const { projectId, jobId } = useParams();
	const navigate = useNavigate();
	const [finishedSizeOtherInputState, setFinishedSizeOtherInputState] =
		useState(true);
	const [projectDetails, setProjectDetails] = useState({});
	const [jobDetails, setJobDetails] = useState({});

	const [otherRecipientNameInputState, setOtherRecipientNameInputState] = useState(true);
	useEffect(() => {
		updateBuyerJobPageInitialize({
			buyerUpdateJobResponse: {
				isUpdateSuccessful: false,
				isUpdateInitiated: false,
			},
		});
		getBuyerJob({ jobId, projectId });
		getBuyerProject(projectId);

	}, []);

	useEffect(() => {
		if (
			buyerProjectDetails.isDataLoaded === true &&
			buyerJobDetails.isDataLoaded === true
		) {
			let pageName = buyerJobDetails.name + " - Update Job";
			setProjectDetails({ ...buyerProjectDetails });
			let printPdfAvailability = dateFormatter(buyerJobDetails.printPdfAvailability, false);
			let deliveryDate = dateFormatter(buyerJobDetails.deliveryDate, false);
			let quoteRequiredBy = dateFormatter(buyerJobDetails.quoteRequiredBy, false);
			let finishedSizeOther = "";
			let selectRecipientName = "";
			let finishedSize = buyerJobDetails.finishedSize;
			let recipientName = buyerJobDetails.recipientName;

			if (
				!PageSize.map((pageSize) => {
					return pageSize.value;
				}).includes(buyerJobDetails.finishedSize)
			) {
				if (finishedSize !== "") {
					finishedSizeOther = buyerJobDetails.finishedSize;
					finishedSize = "Other";
				}
				else {
					finishedSize = ""
				}
			}
			if (!DeliveryAddress.map((deliveryAddress) => {
				return deliveryAddress.recipientName;
			}).includes(buyerJobDetails.recipientName)) {
				selectRecipientName = 'Other';
			}
			else {
				selectRecipientName = buyerJobDetails.recipientName
			}
			setJobDetails({
				...buyerJobDetails,
				pageName,
				printPdfAvailability,
				deliveryDate,
				quoteRequiredBy,
				finishedSizeOther,
				finishedSize,
				recipientName,
				selectRecipientName,
			});
		}
	}, [buyerProjectDetails, buyerJobDetails]);

	const initialEmptyJobDetails = {
		name: "",
		approver: "",
		quoteRequiredBy: "",
		printPdfAvailability: "",
		deliveryDate: "",
		proofs: "",
		finishedSize: "",
		finishedSizeOther: "",
		orientation: "",
		coverPages: "",
		coverColours: "",
		coverFinish: "",
		coverMaterial: "",
		textPages: "",
		textColours: "",
		textFinish: "",
		textMaterial: "",
		bindingFinishing: "",
		packing: "",
		fileCopies: "",
		doesCarbonBalancingRequired: false,
		notes: "",
		recipientName: "",
		selectRecipientName: "",
		addressLine1: "",
		postCode: "",
		county: "",
	};
	
	const formik = useFormik({
		initialValues: { ...initialEmptyJobDetails, ...jobDetails },
		enableReinitialize: true,
		validateOnChange: true,
		validateOnBlur: true,
		validationSchema: Yup.object({
			name: Yup.string()
				.max(1024, "Must be 1024 characters or less.")
				.required("Job Name is required."),
			approver: Yup.string().max(512, "Must be 512 characters or less."),
			quoteRequiredBy: Yup.date()
				
				.required("Quote Required By is Required."),
			printPdfAvailability: Yup.date()
				
				.required("Print PDF Availability is required."),
			deliveryDate: Yup.date()
				
				.required("Delivery Date is required."),
			proofs: Yup.string().max(512, "Must be 512 characters or less."),
			finishedSize: Yup.string().max(
				512,
				"Must be 512 characters or less."
			),
			orientation: Yup.string().max(20, "Must be 20 characters or less."),
			coverPages: Yup.string().max(512, "Must be 512 characters or less."),
			coverColours: Yup.string().max(
				512,
				"Must be 512 characters or less."
			),
			coverFinish: Yup.string().max(
				512,
				"Must be 512 characters or less."
			),
			coverMaterial: Yup.string().max(
				512,
				"Must be 512 characters or less."
			),
			textColours: Yup.string().max(
				512,
				"Must be 512 characters or less."
			),
			textPages: Yup.string().max(512, "Must be 512 characters or less."),
			textFinish: Yup.string().max(512, "Must be 512 characters or less."),
			textMaterial: Yup.string().max(
				512,
				"Must be 512 characters or less."
			),
			bindingFinishing: Yup.string().max(
				512,
				"Must be 512 characters or less."
			),
			packing: Yup.string().max(512, "Must be 512 characters or less."),
			fileCopies: Yup.string().max(512, "Must be 512 characters or less."),
			doesCarbonBalancingRequired: Yup.bool().required(
				"Carbon Balancing Field is required."
			),
			notes: Yup.string().max(2048, "Must be 2048 characters or less."),
			selectRecipientName:Yup.string().max(1024, "Must be 1024 characters or less.").required("Recipient is required."),
		}).shape({
			finishedSizeOther: Yup.string()
				.ensure()
				.when("finishedSize", {
					is: "Other",
					then: Yup.string()
						.required("Finished Size is required.")
						.max(512, "Must be 512 characters or less."),
				}),
			addressLine1: Yup.string()
				.ensure()
				.when("selectRecipientName", {
					is: "Other",
					then: Yup.string()
						.required("Address Line 1 is required.")
						.max(512, "Must be 512 characters or less"),
				})
			,
			postCode: Yup.string()
				.ensure()
				.when("selectRecipientName", {
					is: "Other",
					then: Yup.string()
						.required("Postal Code is required.")
						.max(512, "Must be 512 characters or less"),
				})
			,
			recipientName: Yup.string()
				.ensure()
				.when("selectRecipientName", {
					is: "Other",
					then: Yup.string()
						.required("Recipient Name is required.")
						.max(512, "Must be 512 characters or less"),
				})
			,
			county: Yup.string()
				.ensure()
				.when("selectRecipientName", {
					is: "Other",
					then: Yup.string()
						.required("County is required.")
						.max(512, "Must be 512 characters or less"),
				})
		}),
		onSubmit: (values, { setSubmitting }) => {
			if (!finishedSizeOtherInputState) {
				values.finishedSize = values.finishedSizeOther;
			}
			delete values["finishedSizeOther"];
			updateBuyerJob({ values, projectId, jobId });
		},
	});

	const handleRecipientChange = async (event) => {
	
		await formik.setFieldValue(
			"recipientName",
			DeliveryAddress[event.target.selectedIndex].recipientName
		);

		await formik.setFieldValue(
			"addressLine1",
			DeliveryAddress[event.target.selectedIndex].addressLine1
		);
		await formik.setFieldValue(
			"postCode",
			DeliveryAddress[event.target.selectedIndex].postCode
		);
		await formik.setFieldValue(
			"county",
			DeliveryAddress[event.target.selectedIndex].county
		);
		formik.validateForm();

	};

	useEffect(() => {
		if (formik.values.finishedSize === "Other") {
			setFinishedSizeOtherInputState(false);
		} else if (formik.values.finishedSize !== "Other") {
			setFinishedSizeOtherInputState(true);
		}
	}, [formik.values.finishedSize]);
	function callAbortJob() {
		abortJob({ projectId, jobId });
	}
	useEffect(() => {

		if (formik.values.selectRecipientName === "Other") {
			
			setOtherRecipientNameInputState(false);
		} else if (formik.values.selectRecipientName !== "Other") {
			setOtherRecipientNameInputState(true);
		}

	}, [formik.values.selectRecipientName]);


	return (
		<div>
			{buyerUpdateJobResponse.isUpdateSuccessful &&
				!buyerUpdateJobResponse.isUpdateInitiated
				? navigate(`/print/buyer/project/${projectId}/update`)
				: null}
			<Container pageName={jobDetails.pageName}>
				{projectDetails.isDataLoaded && jobDetails.isDataLoaded ? (
					<>
						<form
							onSubmit={formik.handleSubmit}
							onReset={formik.resetForm}
						>
							<div className={buyerJobDetails.status === "Live" && buyerJobDetails.projectStatus === "Live" ? "newJobMajorRow row" : "disableUpdateProject row"}>
								<div className="newJobGeneral col-md-3">
									<div>
										<p className="Heading">GENERAL</p>
									</div>
									<div className="projectDetailsAreaRowOne">
										<div className="InlineData">
											<label>Client</label>
											<div className="value">{projectDetails.client}</div>
										</div>
										<div className="InlineData">
											<label>Is this an ISBN Job?</label>
											<div className="value">
												<label className="switch">
													<input
														type="checkbox"
														value={formik.values.isIsbn}
														role="switch"
														id="isIsbn"
														name="isIsbn"
														onChange={(event) => {
															formik.handleChange(
																event
															);
														}}
														checked={
															formik.values.isIsbn
																? "checked"
																: null
														}
														disabled
													/>
													<span className="slider round"></span>
												</label>
											</div>
										</div>
										<div className="InlineData">
											<label>ISBN</label>
											<div className="value">{projectDetails.isbn}</div>
										</div>
										<div className="InlineData">
											<label>ISBN Title</label>
											<div className="value">{projectDetails.isbnTitle}</div>
										</div>
										<div className="InlineData">
											<label>Impression</label>
											<div className="value">{projectDetails.impression}</div>
										</div>
									</div>
									<div className="projectDetailsAreaRowTwo">
										<div className="updateJobNameInputGroup">
											<label
												htmlFor="Job Name"
												className="required"
											>
												Job Name
											</label>
											<input
												type="text"
												className={
													formik.touched.name && formik.errors.name
														? "form-control is-invalid"
														: "form-control"
												}
												{...formik.getFieldProps("name")}
											></input>
											<div className="invalid-feedback">
												{formik.touched.name && formik.errors.name}
											</div>
										</div>
										<div className="updateJobInputGroup">
											<label htmlFor="Approver">
												Approver
											</label>
											<input
												type="text"
												className={
													formik.errors.approver
														? "form-control is-invalid"
														: "form-control"
												}
												{...formik.getFieldProps(
													"approver"
												)}
											/>
											<div className="invalid-feedback">
												{formik.errors.approver}
											</div>
										</div>
										<div className="updateJobInputGroup row">
											<div className="col-md-6">
												<label
													htmlFor="Quote Required By"
													className="required"
												>
													Quote Required By
												</label>
											</div>
											<div className="col-md-6">
												<div
													className="input-date dateFieldValue"
													id="datepicker"
												>
													<input
														type="date"
														className={
															formik.touched.quoteRequiredBy && formik.errors
																.quoteRequiredBy
																? "dateselector is-invalid"
																: "dateselector"
														}
														{...formik.getFieldProps(
															"quoteRequiredBy"
														)}
													/>
													<div className="invalid-feedback">
														{formik.touched.quoteRequiredBy && formik.errors.quoteRequiredBy}
													</div>
												</div>
											</div>
										</div>
										<div className="updateJobInputGroup row">
											<div className="col-md-6">
												<label htmlFor="Print PDF Availability" className="required">
													Print PDF Availability
												</label>
											</div>
											<div className="col-md-6">
												<div
													className="input-date dateFieldValue"
													id="datepicker"
												>

													<input
														type="date"
														className={
															formik.touched.printPdfAvailability && formik.errors
																.printPdfAvailability
																? "dateselector is-invalid"
																: "dateselector"
														}
														{...formik.getFieldProps(
															"printPdfAvailability"
														)}
													/>
													<div className="invalid-feedback">
														{
															formik.touched.printPdfAvailability && formik.errors
																.printPdfAvailability
														}
													</div>
												</div>
											</div>
										</div>
										<div className="updateJobInputGroup row">
											<div className="col-md-6">
												<label
													htmlFor="Delivery Date"
													className="required"
												>
													Delivery Date
												</label>
											</div>
											<div className="col-md-6">
												<div
													className="input-date dateFieldValue"
													id="datepicker"
												>
													<input
														type="date"
														className={
															formik.touched.deliveryDate && formik.errors.deliveryDate
																? "dateselector is-invalid"
																: "dateselector"
														}
														{...formik.getFieldProps(
															"deliveryDate"
														)}
													/>
													<div className="invalid-feedback">
														{formik.touched.deliveryDate && formik.errors.deliveryDate}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* <!-------------Specification---------------------> */}
								<div className="newJobSpecification col-md-6">
									<div>
										<p className="Heading">SPECIFICATIONS</p>
									</div>
									<div className="Majorrow row">
										<div className="newJobspecolone col-md-6">
											<div className="newJobSepcflex-item">
												<label htmlFor="Proofs">
													Proofs
												</label>
												<select
													className={
														formik.errors.proofs
															? " dropdownbtn dropdown-toggle is-invalid form-select"
															: " dropdownbtn dropdown-toggle form-select"
													}
													{...formik.getFieldProps(
														"proofs"
													)}
												>
													<option value="">
														Please Select
													</option>
													{Proofs.map((Proofs, idx) => {
														return (
															<option
																key={idx}
																value={Proofs.value}
															>
																{Proofs.label}
															</option>
														);
													})}
												</select>
												<div className="invalid-feedback">
													{formik.errors.proofs}
												</div>
											</div>
											<div className="newJobSepcflex-item row">
												{/* <div className="FinisedSizebuttonrow "> */}
												<div className="col-md-6">
													<label htmlFor="Finised Size">
														Finished Size
													</label>
													<select
														className={
															formik.errors
																.finishedSize
																? "FinisedSizebutton finishedSizedropdown dropdownbtn  dropdown-toggle is-invalid form-select"
																: "FinisedSizebutton finishedSizedropdown  dropdownbtn  dropdown-toggle form-select"
														}
														{...formik.getFieldProps(
															"finishedSize"
														)}
													>
														<option value="" className="finisedSizeOption">
															Please select
														</option>
														{PageSize.map(
															(PageSize, idx) => {
																return (
																	<option
																		key={
																			idx
																		}
																		value={
																			PageSize.value
																		}
																	>
																		{
																			PageSize.label
																		}
																	</option>
																);
															}
														)}
													</select>
													<div className="invalid-feedback">
														{
															formik.errors
																.finishedSize
														}
													</div>
												</div>
												<div className="col-md-6 inputGroup">
													<label htmlFor="Other">
														Other
													</label>
													<input
														type="text"
														className={
															formik.touched.finishedSizeOther && formik.errors
																.finishedSizeOther
																? "form-control is-invalid"
																: "form-control"
														}
														disabled={
															finishedSizeOtherInputState
														}
														{...formik.getFieldProps(
															"finishedSizeOther"
														)}
													/>
													<div className="invalid-feedback">
														{
															formik.touched.finishedSizeOther && formik.errors
																.finishedSizeOther
														}
													</div>
												</div>
												{/* </div> */}
											</div>
											<div className="newJobSepcflex-item">
												<label htmlFor="Orientation">
													Orientation
												</label>
												<select
													className={
														formik.errors.orientation
															? "selectfiled  dropdownbtn dropdown-toggle is-invalid form-select"
															: "selectfiled  dropdownbtn dropdown-toggle form-select"
													}
													{...formik.getFieldProps(
														"orientation"
													)}
												>
													<option value="">
														Please Select
													</option>
													<option value="Portrait">
														Portrait
													</option>
													<option value="Landscape">
														Landscape
													</option>
												</select>
												<div className="invalid-feedback">
													{formik.errors.orientation}
												</div>
											</div>
											<div className="newJobSepcflex-item">
												<label htmlFor="Cover Pages">
													Cover Pages
												</label>
												<select
													className={
														formik.errors.coverPages
															? "selectfiled  dropdownbtn dropdown-toggle is-invalid form-select"
															: "selectfiled  dropdownbtn dropdown-toggle form-select"
													}
													{...formik.getFieldProps(
														"coverPages"
													)}
												>
													<option value="">
														Please Select
													</option>
													<option value="Cover Page">
														Cover Page
													</option>
												</select>
												<div className="invalid-feedback">
													{formik.errors.coverPages}
												</div>
											</div>
											<div className="newJobSepcflex-item">
												<label htmlFor="Cover Color">
													Cover Colours
												</label>
												<input
													type="text"
													className={
														formik.errors.coverColours
															? "form-control is-invalid"
															: "form-control"
													}
													{...formik.getFieldProps(
														"coverColours"
													)}
												/>
												<div className="invalid-feedback">
													{formik.errors.coverColours}
												</div>
											</div>
											<div className="newJobSepcflex-item">
												<label htmlFor="Cover Finish">
													Cover Finish
												</label>
												<input
													type="text"
													className={
														formik.errors.coverFinish
															? "form-control is-invalid"
															: "form-control"
													}
													{...formik.getFieldProps(
														"coverFinish"
													)}
												/>
												<div className="invalid-feedback">
													{formik.errors.coverFinish}
												</div>
											</div>
											<div className="newJobSepcflex-item">
												<label htmlFor="Cover Material">
													Cover Material
												</label>
												<input
													type="text"
													className={
														formik.errors.coverMaterial
															? "form-control is-invalid"
															: "form-control"
													}
													{...formik.getFieldProps(
														"coverMaterial"
													)}
												/>
												<div className="invalid-feedback">
													{formik.errors.coverMaterial}
												</div>
											</div>
										</div>
										<div className="newJobspecolTwo col-md-6">
											<div className="newJobSepcflex-item">
												<label htmlFor="Text Pages">
													Text Pages
												</label>
												<input
													type="text"
													className={
														formik.errors.textPages
															? "form-control is-invalid"
															: "form-control"
													}
													{...formik.getFieldProps(
														"textPages"
													)}
												/>
												<div className="invalid-feedback">
													{formik.errors.textPages}
												</div>
											</div>
											<div className="newJobSepcflex-item">
												<label htmlFor="Text colours">
													Text Colours
												</label>
												<input
													type="text"
													className={
														formik.errors.textColours
															? "form-control is-invalid"
															: "form-control"
													}
													{...formik.getFieldProps(
														"textColours"
													)}
												/>
												<div className="invalid-feedback">
													{formik.errors.textColours}
												</div>
											</div>
											<div className="newJobSepcflex-item">
												<label htmlFor="Text Finish">
													Text Finish
												</label>
												<input
													type="text"
													className={
														formik.errors.textFinish
															? "form-control is-invalid"
															: "form-control"
													}
													{...formik.getFieldProps(
														"textFinish"
													)}
												/>
												<div className="invalid-feedback">
													{formik.errors.textFinish}
												</div>
											</div>
											<div className="newJobSepcflex-item">
												<label htmlFor="Text Material">
													Text Material
												</label>
												<input
													type="text"
													className={
														formik.errors.textMaterial
															? "form-control is-invalid"
															: "form-control"
													}
													{...formik.getFieldProps(
														"textMaterial"
													)}
												/>
												<div className="invalid-feedback">
													{formik.errors.textMaterial}
												</div>
											</div>
											<div className="newJobSepcflex-item">
												<label htmlFor="Binding/Finishing">
													Binding/Finishing
												</label>
												<input
													type="text"
													className={
														formik.errors
															.bindingFinishing
															? "form-control is-invalid"
															: "form-control"
													}
													{...formik.getFieldProps(
														"bindingFinishing"
													)}
												/>
												<div className="invalid-feedback">
													{formik.errors.bindingFinishing}
												</div>
											</div>
											<div className="newJobSepcflex-item">
												<label htmlFor="Packing">
													Packing
												</label>
												<input
													type="text"
													className={
														formik.errors.packing
															? "form-control is-invalid"
															: "form-control"
													}
													{...formik.getFieldProps(
														"packing"
													)}
												/>
												<div className="invalid-feedback">
													{formik.errors.packing}
												</div>
											</div>
											<div className="newJobSepcflex-item">
												<label htmlFor="File Copies">
													File Copies
												</label>
												<input
													type="text"
													className={
														formik.errors.fileCopies
															? "form-control is-invalid"
															: "form-control"
													}
													{...formik.getFieldProps(
														"fileCopies"
													)}
												/>
												<div className="invalid-feedback">
													{formik.errors.fileCopies}
												</div>
											</div>
											<br />
											<div className="InlineData">
												<div>
													<label htmlFor="Does Require Carbon Cost?">
														Does Require Carbon Cost?
													</label>
												</div>
												<div>
													<label className="switch">
														<input
															type="checkbox"
															onChange={(event) => {
																formik.handleChange(
																	event
																);
															}}
															value={
																formik.values
																	.doesCarbonBalancingRequired
															}
															checked={
																formik.values
																	.doesCarbonBalancingRequired
																	? "checked"
																	: null
															}
															{...formik.getFieldProps(
																"doesCarbonBalancingRequired"
															)}
														/>
														<span className="slider round"></span>
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* <!---Delivery--> */}
								<div className="newJobDelivery col-md-3">
									<div>
										<div>
											<p className="deliveryHeading">
												DELIVERY
											</p>
										</div>
									</div>
									<div className="deliveryDetails">
										<div className="row">
											<div className="recipientNameInputGroup col-md-6">
												<label htmlFor="RecipientsName" className="required">
													Select Recipient
												</label>
												<select
													className={
														formik.touched.selectRecipientName && formik.errors.selectRecipientName
															? "selectfiled dropdownbtn dropdown-toggle is-invalid form-select"
															: "selectfiled dropdownbtn dropdown-toggle form-select"
													}
													id="selectRecipientName"
													name="selectRecipientName"
													type="text"
													onChange={(e) => {
														formik.handleChange(e);
														handleRecipientChange(e);
													}}
													value={formik.values.selectRecipientName}
												>
													{DeliveryAddress.map(
														(DeliveryAddress, idx) => {
															return (
																<option
																	key={idx}
																	value={
																		DeliveryAddress.value
																	}
																>
																	{DeliveryAddress.label}
																</option>
															);
														}
													)}
												</select>
												<div className="invalid-feedback">
													{formik.touched.selectRecipientName && formik.errors.selectRecipientName}
												</div>
											</div>
											<div className="otherRecipientNameInputGroup col-md-6">
												<label htmlFor="recipientName">Recipient Name</label>
												<input
													type="text"
													className={
														formik.errors.recipientName
															? "form-control is-invalid"
															: "form-control"
													}
													{...formik.getFieldProps(
														"recipientName"
													)}
													disabled={
														otherRecipientNameInputState

													}
												>

												</input>
												<div className="invalid-feedback">
													{ formik.touched.recipientName && formik.errors.recipientName}
												</div>
											</div>
										</div>

										<div className="deliveryAddressLineInputgroup">
											<label htmlFor="Address Line 1">
												Address Line 1
											</label>

											<textarea
												className={
													formik.errors.addressLine1
														? "form-control is-invalid"
														: "form-control"
												}
												id="AddressLine"
												rows="5"
												{...formik.getFieldProps("addressLine1")}
												disabled={
													otherRecipientNameInputState

												}
											/>
												<div className="invalid-feedback">
											{formik.touched.addressLine1 && formik.errors.addressLine1}
										</div>
										</div>
										<div className="deliveryPostalInputgroup">
											<label htmlFor="Postal Code">
												Postal Code
											</label>
											<input
												type="text"
												className={
													formik.errors.postCode
														? "form-control is-invalid"
														: "form-control"
												}
												{...formik.getFieldProps("postCode")}
												disabled={
													otherRecipientNameInputState

												}
											/>
											<div className="invalid-feedback">
											{formik.touched.postCode && formik.errors.postCode}
										</div>
										</div>
										<div className="deliveryCountyInputgroup">
											<label htmlFor="County">County</label>
											<input
												type="text"
												className={
													formik.errors.county
														? "form-control is-invalid"
														: "form-control"
												}
												{...formik.getFieldProps("county")}
												disabled={
													otherRecipientNameInputState

												}
											/>
											<div className="invalid-feedback">
											{formik.touched.county && formik.errors.county}
										</div>
										</div>
										<div className="deliveryAdditionalInputgroup">
											<label htmlFor="Notes">
												Additional Notes
											</label>
											<div className="form-outline">
												<textarea
													className={
														formik.errors.notes
															? "form-control is-invalid"
															: "form-control"
													}
													id="textAreaExample"
													rows="5"
													{...formik.getFieldProps("notes")}
												/>
											</div>
										</div>
									</div>


								</div>
							</div>
							{<div className="row">

								{buyerJobDetails.status === "Live" &&
									buyerJobDetails.projectStatus === "Live" ? (
									<div className="newJobsubmitButtonRow">

										<button
											type="button"
											className="AbortJobbutton"
											onClick={callAbortJob}
										>
											Abort Job
										</button>
										<div className="updateJobSaveAndCancel">

											<button
												type="submit"
												className=" newJobSavebutton"
											>
												Save
											</button>



											<button
												type="button"
												onClick={() => {
													navigate(
														`/print/buyer/project/${projectId}/update`
													);
												}}
												className="newJobCancelbutton"
											>
												Cancel
											</button>

										</div>

									</div>
								) : (
									<div className="newJobsubmitButtonRow">
										<button
											type="button"
											onClick={() => {
												navigate(
													`/print/buyer/project/${projectId}/update`
												);
											}}
											className="updateJobCancelbutton"
										>
											Cancel
										</button>
									</div>
								)}
							</div>}
						</form>

					</>
				) : (
					<div className="updateJobLoader">
						<ClipLoader
							color={"#008080"}
							size={50}
							aria-label="Loading Spinner"
							data-testid="loader"
						/>
					</div>
				)}
			</Container>
		</div>
	);
};

const mapStateToProps = (state) => {
	return { ...state.buyerData };
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateBuyerJob: (data) => {
			dispatch(updateBuyerJob(data));
		},
		getBuyerProject: (projectId) => {
			dispatch(getBuyerProject(projectId));
		},
		getBuyerJob: (data) => {
			dispatch(getBuyerJob(data));
		},
		updateBuyerJobPageInitialize: (data) => {
			dispatch(updateBuyerJobPageInitialize(data));
		},
		abortJob: (data) => {
			dispatch(abortJob(data));
		},

	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyerUpdateJob);
