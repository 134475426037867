import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router";
import * as Yup from "yup";
import { useFormik } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import { dateFormatter } from "../../../../Utils/HelperFunctions";
import Container from "../../../../Layouts/Common/Container/Container";
import { BuyerList } from "../../../../Utils/Constants";
import { getClientList } from "../../../../Actions/BuyerJourneyActions";
import {
	getBuyerProject,
	updateBuyerProject,
	updateBuyerProjectPageInitialize,
	getBuyerJobs,
	abortProject,
	completeProject,
} from "../../../../Actions/BuyerJourneyActions";

export const BuyerUpdateProject = ({
	getBuyerProject,
	getBuyerJobs,
	buyerJobs,
	buyerProjectDetails,
	updateBuyerProject,
	buyerUpdateProjectResponse,
	updateBuyerProjectPageInitialize,
	abortProject,
	completeProject,
	getClientList,
	clientList,
}) => {
	const navigate = useNavigate();
	const { projectId } = useParams();
	const [projectDetails, setProjectDetails] = useState({});

	useEffect(() => {
		updateBuyerProjectPageInitialize({
			buyerUpdateProjectResponse: {
				isUpdateSuccessful: false,
				isUpdateInitiated: false,
				isUpdateError: false,
			},
		});
		getBuyerProject(projectId);
		getBuyerJobs({ projectId });
		getClientList();
	}, []);

	useEffect(() => {
		if (buyerProjectDetails.isDataLoaded === true) {
			let completionDate = dateFormatter(
				buyerProjectDetails.completionDate,
				false
			);
			setProjectDetails({ ...buyerProjectDetails, completionDate });
		}
	}, [buyerProjectDetails]);

	function callAbortProject() {
		abortProject({ projectId });
	}
	function callcompleteProject() {
		completeProject({ projectId });
	}
	const formik = useFormik({
		initialValues: {
			title: "",
			description: "",
			buyer: "",
			client: "",
			clientReference: "",
			completionDate: "",
			isbn: "",
			impression: "",
			isbnTitle: "",
			notes: "",
			isIsbn: false,
			...projectDetails,
		},
		enableReinitialize: true,
		validationSchema: Yup.object({
			title: Yup.string()
				.max(1024, "Must be 1024 characters or less")
				.required("Title is required."),
			buyer: Yup.string()
				.max(512, "Must be 512 characters or less")
				.required("Buyer is required."),
			client: Yup.string()
				.max(512, "Must be 512 characters or less")
				.required("Client is required."),
			notes: Yup.string().max(2048, "Must be 2048 characters or less."),
			description: Yup.string().max(
				2048,
				"Must be 2048 characters or less."
			),
			completionDate: Yup.date()
				// .min(new Date(), "Past date is not allowed. Please provide valid future date.")
				.required("Completion Date is required."),
		}).shape({
			impression: Yup.string()
				.ensure()
				.when("isIsbn", {
					is: true,
					then: Yup.string()
						.required("Impression is required.")
						.max(16, "Must be 16 characters or less."),
				}),
			isbn: Yup.string()
				.ensure()
				.when("isIsbn", {
					is: true,
					then: Yup.string()
						.required("ISBN is required.")
						.max(32, "Must be 32 characters or less."),
				}),
			isbnTitle: Yup.string()
				.ensure()
				.when("isIsbn", {
					is: true,
					then: Yup.string()
						.required("ISBN Title is required.")
						.max(512, "Must be 512 characters or less."),
				}),
		}),
		validateOnChange: true,
		validateOnBlur: true,
		onSubmit: (values, { setSubmitting }) => {
			updateBuyerProject(values);
		},
	});
	const handleIsISBNChange = (event) => {
		formik.setFieldValue("isbn", "");
		formik.setFieldValue("isbnTitle", "");
		formik.setFieldValue("impression", "");
	};

	return (
		<div>
			{buyerUpdateProjectResponse.isUpdateSuccessful &&
			!buyerUpdateProjectResponse.isUpdateInitiated
				? navigate(`/print/buyer/projects`)
				: null}
			<Container pageName={`Project - ${buyerProjectDetails.title}`}>
				{buyerProjectDetails.isDataLoaded ? (
					<form
						onSubmit={formik.handleSubmit}
						onReset={formik.resetForm}
					>
						<div
							className={
								buyerProjectDetails.status === "Live"
									? "updateProjectRow row"
									: " disableUpdateProject row"
							}
						>
							<div className="col-md-3">
								<div className="newProjectDetailsColOne">
									<div className="NewProjectCol1TitleInputGroup">
										<label
											htmlFor="title"
											className="required "
										>
											Title
										</label>
										<input
											type="text"
											className={
												formik.touched.title &&
												formik.errors.title
													? "form-control is-invalid"
													: "form-control"
											}
											{...formik.getFieldProps("title")}
										></input>

										<div className="invalid-feedback">
											{formik.touched.title &&
												formik.errors.title}
										</div>
									</div>
									<br />
									<div className="ProjectCol1DescriptionInputGroup">
										<label htmlFor="description">
											Description
										</label>
										<div className="form-outline">
											<textarea
												className="form-control"
												id="textAreaExample"
												rows="9"
												{...formik.getFieldProps(
													"description"
												)}
											></textarea>
										</div>
									</div>
								</div>
							</div>
							<div className="newProjectDetailsColTwo col-md-3">
								<div className="NewProjectCol2BuyerInputGroup">
									<label htmlFor="buyer" className="required">
										Buyer
									</label>
									<select
										className={
											formik.touched.buyer &&
											formik.errors.buyer
												? "NewProjectselectbuyer  dropdownbtn dropdown-toggle form-select is-invalid"
												: "NewProjectselectbuyer  dropdownbtn dropdown-toggle form-select"
										}
										{...formik.getFieldProps("buyer")}
									>
										<option value="" default>
											Please Select a Buyer
										</option>
										{BuyerList.map((buyer, idx) => {
											return (
												<option
													key={idx}
													value={buyer.value}
												>
													{buyer.label}
												</option>
											);
										})}
									</select>
									<div className="invalid-feedback">
										{formik.touched.buyer &&
											formik.errors.buyer}
									</div>
								</div>
								{clientList.isDataLoaded ? (
									<div className="NewProjectCol2ClientInputGroup">
										<label
											htmlFor="client"
											className="required"
										>
											Client
										</label>
										<select
											className={
												formik.touched.client &&
												formik.errors.client
													? "NewProjectselectClient dropdownbtn dropdown-toggle form-select is-invalid"
													: "NewProjectselectClient dropdownbtn dropdown-toggle form-select"
											}
											{...formik.getFieldProps("client")}
										>
											<option value="" default>
												Please Select a Client
											</option>
											{clientList.data.map((data) => {
												return (
													<option
														key={data.id}
														value={data.name}
													>
														{data.name}
													</option>
												);
											})}
										</select>
										<div className="invalid-feedback">
											{formik.touched.client &&
												formik.errors.client}
										</div>
									</div>
								) : (
									<div></div>
								)}
								<div className="NewProjectCol2ClientReferenceInputGroup">
									<label className="Client Reference">
										Client Reference
									</label>
									<input
										type="text"
										className="form-control"
										{...formik.getFieldProps(
											"clientReference"
										)}
									></input>
								</div>
								<div className="NewProjectCol2ProjectCompletionDateInputGroup">
									<label
										htmlFor="Project Completion date"
										className="required"
									>
										Project Completion Date
									</label>
									<div
										className=" projectCompletionDate input-date"
										id="datepicker"
									>
										<input
											type="date"
											className={
												formik.touched.completionDate &&
												formik.errors.completionDate
													? "dateselector is-invalid"
													: "dateselector"
											}
											{...formik.getFieldProps(
												"completionDate"
											)}
										/>
										<div className="invalid-feedback">
											{formik.touched.completionDate &&
												formik.errors.completionDate}
										</div>
										<span className="input-group-append"></span>
									</div>
								</div>
							</div>
							<div className=" col-md-3">
								<div className="isIsbnInlineData">
									<div className="isIsbnLabel">
										Is this an ISBN Job?
									</div>
									<div>
										<label className="switch">
											<input
												className="form-check-input"
												type="checkbox"
												role="switch"
												id="isIsbn"
												name="isIsbn"
												onChange={(event) => {
													formik.handleChange(event);
												}}
												onClick={(event) => {
													handleIsISBNChange(event);
												}}
												{...formik.getFieldProps(
													"isIsbn"
												)}
												value={formik.values.isIsbn}
												checked={
													formik.values.isIsbn
														? "checked"
														: null
												}
											/>
											<span className="slider round"></span>
										</label>
									</div>
								</div>

								<div className="NewProjectCol2ISBNInputGroup">
									<label htmlFor="ISBN">ISBN</label>
									<input
										type="text"
										className={
											formik.touched.isbn &&
											formik.errors.isbn
												? "form-control is-invalid"
												: "form-control"
										}
										{...formik.getFieldProps("isbn")}
										disabled={
											!formik.values.isIsbn
												? "disabled"
												: null
										}
									/>
									<div className="invalid-feedback">
										{formik.touched.isbn &&
											formik.errors.isbn}
									</div>
								</div>

								<div className="NewProjectCol2ISBNTitleInputGroup">
									<label htmlFor="ISBN Title">
										ISBN Title
									</label>
									<input
										type="text"
										className={
											formik.touched.isbnTitle &&
											formik.errors.isbnTitle
												? "form-control is-invalid"
												: "form-control"
										}
										{...formik.getFieldProps("isbnTitle")}
										disabled={
											!formik.values.isIsbn
												? "disabled"
												: null
										}
									/>
									<div className="invalid-feedback">
										{formik.touched.isbnTitle &&
											formik.errors.isbnTitle}
									</div>
								</div>

								<div className="NewProjectCol2ISBNImpressionInputGroup">
									<label htmlFor="Impression">
										Impression
									</label>
									<input
										type="text"
										className={
											formik.touched.impression &&
											formik.errors.impression
												? "form-control is-invalid"
												: "form-control"
										}
										{...formik.getFieldProps("impression")}
										disabled={
											!formik.values.isIsbn
												? "disabled"
												: null
										}
									/>
									<div className="invalid-feedback">
										{formik.touched.impression &&
											formik.errors.impression}
									</div>
								</div>
							</div>
							<div className="newProjectDetailsColFour col-md-3">
								<div>
									{" "}
									<label htmlFor="Additional Notes">
										Additional Notes
									</label>
								</div>
								<div>
									<div className="form-outline">
										<textarea
											className="form-control"
											id="textAreaExample"
											rows="13"
											{...formik.getFieldProps("notes")}
										></textarea>
									</div>
								</div>
							</div>
						</div>
						{buyerProjectDetails.status === "Live" ? (
							<div className="updateProjectSubmitButtonRow">
								<button
									type="submit"
									className="updateProjectSavebutton"
								>
									Save
								</button>
								<button
									type="reset"
									className="updateProjectCancelbutton"
									onClick={() => {
										navigate("/print/buyer/projects");
									}}
								>
									Cancel
								</button>
							</div>
						) : (
							<div className="updateProjectSubmitButtonRow">
								<button
									type="reset"
									className="updateProjectCancelbutton"
									onClick={() => {
										navigate("/print/buyer/projects");
									}}
								>
									Cancel
								</button>
							</div>
						)}
					</form>
				) : (
					<div className="buyerUpdateProjectLoader">
						<ClipLoader
							color={"#008080"}
							size={50}
							aria-label="Loading Spinner"
							data-testid="loader"
						/>
					</div>
				)}
				<div className="CreateNewJobTab row">
					<div className="col-md-6">
						<label className=" newProjectJobs">Jobs</label>
					</div>
					<div className="col-md-6">
						{buyerProjectDetails.status === "Live" ? (
							<div className="createNewProjectrow">
								<button
									type="button"
									className=" newProjectcreateNewJob"
									onClick={() => {
										navigate(
											`/print/buyer/project/${projectId}/job/create`
										);
									}}
								>
									Create Job
								</button>
							</div>
						) : (
							<div className="createNewProjectrow"></div>
						)}
					</div>
				</div>

				<div className="  Jobtablerow row">
					<div className="updateProjectTable">
						<table className="updateProjectJobTable table table-bordered ">
							<thead className="thead">
								<tr>
									<th
										className="newProjectjobName tableHeading"
										data-sortable="true"
										data-sorter="alphanum"
									>
										Job Name
									</th>

									<th className="newProjectQuoteRequiredBy tableHeading">
										Quote Required By
									</th>
									<th className="newProjectBidsReceived tableHeading">
										Bids Received
									</th>
									<th className="newProjectDeliveryDate tableHeading">
										Delivery Date
									</th>
									<th className="newProjectStatus tableHeading">
										Status
									</th>
									<th className="newProjectAction tableHeading">
										Action
									</th>
								</tr>
							</thead>
							<tbody>
							{buyerJobs.isDataLoaded ? (
								buyerJobs.data.length !== 0 ? (
									buyerJobs.data.map((buyerJobs) => {
										
										let manageBidURL = `/print/buyer/project/${projectId}/job/${buyerJobs.id}/bids`;
										let updateJobURL = `/print/buyer/project/${projectId}/job/${buyerJobs.id}/update`;
										return (
											
												<tr className="BuyerJobData" key={buyerJobs.id}>
													<td className="newProjectjobName"
													>
														
														<a
															className="jobNameLink"
															href={updateJobURL}
														>
															<u>
																{buyerJobs.name}
															</u>
														</a>
													</td>

													<td className="newProjectQuoteRequiredBy"
													>
														{dateFormatter(
															buyerJobs.quoteRequiredBy
														)}
													</td>
													<td className="newProjectBidsReceived"
													>
														{buyerJobs.bidsReceived}
													</td>
													<td className="newProjectDeliveryDate"
													>
														{dateFormatter(
															buyerJobs.deliveryDate
														)}
													</td>
													<td className="newProjectStatus"
													>
														{buyerJobs.status}
													</td>
													<td className="newProjectAction"
													>
														<a
															className="manageBidsLink"
															href={manageBidURL}
														>
															<u>Manage Bids</u>
														</a>
													</td>
												</tr>
											
										);
									})
								) : (
									
										<tr>
											<td className="noJobs" colSpan="6">
												There are no jobs available for
												this project.
											</td>
										</tr>
									
								)
							) : (
								
									<tr >
										<td colSpan="6">
											<div className="BuyerUpdateJobLoader">
												<ClipLoader
													color={"#008080"}
													size={50}
													aria-label="Loading Spinner"
													data-testid="loader"
												/>
											</div>
										</td>
									</tr>
								
							)}
							</tbody>
						</table>
					</div>
				</div>
				{buyerProjectDetails.status === "Live" ? (
					<div className=" updateProjectsubmitButtonrow ">
						<button
							type="button"
							className="newProjectAbortProject"
							onClick={callAbortProject}
						>
							Abort Project
						</button>
						<button
							type="button"
							className=" newProjectcompleteProject"
							onClick={callcompleteProject}
						>
							Complete Project
						</button>
					</div>
				) : (
					<div></div>
				)}
			</Container>
		</div>
	);
};

const mapStateToProps = (state) => {
	return { ...state.buyerData };
};

const mapDispatchToProps = (dispatch) => {
	return {
		getBuyerProject: (projectId) => {
			dispatch(getBuyerProject(projectId));
		},
		updateBuyerProject: (data) => {
			dispatch(updateBuyerProject(data));
		},
		updateBuyerProjectPageInitialize: (data) => {
			dispatch(updateBuyerProjectPageInitialize(data));
		},
		getBuyerJobs: (data) => {
			dispatch(getBuyerJobs(data));
		},
		abortProject: (data) => {
			dispatch(abortProject(data));
		},
		completeProject: (data) => {
			dispatch(completeProject(data));
		},
		getClientList: (data) => {
			dispatch(getClientList(data));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyerUpdateProject);
