export const getSupplierListOfProjects = (payload) => {
	return {
		type: "GET_SUPPLIER_PROJECT_LIST",
		payload
	}
}

export const getSupplierProject = (payload) => {
	return {
		type: "GET_SUPPLIER_PROJECT",
		payload
	}
}

export const getSupplierJobs = (payload) => {
	return {
		type: "GET_SUPPLIER_JOBS",
		payload
	}
}

export const getSupplierJob = (payload) => {
	return {
		type: "GET_SUPPLIER_JOB",
		payload
	}
}

export const getJobBids = (payload) => {
	return {
		type: "GET_SUPPLIER_JOB_BIDS",
		payload
	}
}

export const saveBidCosting = (payload) => {
	return {
		type: "SAVE_SUPPLIER_BID_COST",
		payload
	}
}

export const getBidDetails = (payload) => {
	return {
		type: "GET_SUPPLIER_BID_DETAILS",
		payload
	}
}

export const submitBid = (payload) => {
	return {
		type: "SUBMIT_SUPPLIER_BID",
		payload
	}
}