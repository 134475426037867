import React, {  useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router'
import Container from '../../../../Layouts/Common/Container/Container'
import ClipLoader from "react-spinners/ClipLoader";
import { getSupplierProject } from '../../../../Actions/SupplierJourneyActions'
import { getSupplierJobs } from '../../../../Actions/SupplierJourneyActions';
import { dateFormatter } from '../../../../Utils/HelperFunctions';

export const ViewProject = ({ getSupplierProject, supplierProjectDetails, supplierJobDetails, getSupplierJobs }) => {
	
	const { projectId } = useParams();

	useEffect(() => {
		getSupplierProject({ projectId })
		getSupplierJobs({ projectId })
	}, [])

	return (
		<div>
			<Container pageName={supplierProjectDetails.title}>
				{supplierProjectDetails.isDataLoaded ?
					<div className=" sellerViewPageRow row">
						<div className="col-md-3">
							<div className="sellerprojectDetails">
								<label htmlFor="Title">Title</label>
								<p>{supplierProjectDetails.title}</p>
							</div>
							<div className="sellerprojectDetails">
								<label htmlFor="Buyer">Buyer</label>
								<p>{supplierProjectDetails.buyer}</p>
							</div>
							<div className="sellerprojectDetails">
								<label htmlFor="Client">Client</label>
								<p>{supplierProjectDetails.client}</p>
							</div>

						</div>
						<div className="col-md-3">
							<div className="sellerprojectDetails">
								<label htmlFor="ISBN">ISBN</label>
								<p>{supplierProjectDetails.isbn}</p>
							</div>
							<div className="sellerprojectDetails">
								<label htmlFor="Client Refference">Client Reference</label>
								<p>{supplierProjectDetails.clientReference}</p>
							</div>
							<div className="sellerprojectDetails">
								<label htmlFor="Project Completion date">Project Completion date</label>

								<p>{dateFormatter(supplierProjectDetails.completionDate)}</p>
							</div>
						</div>
						<div className="col-md-3">
							<div>
								<label htmlFor="Description">Description</label>
								<div className="form-outline">
									<textarea readOnly className="form-control" id="textAreaExample" value={supplierProjectDetails.description} rows="8"></textarea>

								</div>
							</div>
						</div>
						<div className="sellerViewAdditionalNotes col-md-3">
							<div>
								<label htmlFor="Additional Notes">Additional Notes</label>
								<div className="form-outline">
									<textarea readOnly className="form-control" id="textAreaExample" value={supplierProjectDetails.notes} rows="8"></textarea>

								</div>
							</div>
						</div>
					</div> :
					<div className="ViewProjectLoader">
						<ClipLoader
							color={'#008080'}
							size={50}
							aria-label="Loading Spinner"
							data-testid="loader"
						/>
					</div>
				}

				<div className="sellerViewJob row">
					<div className="sellerViewJobTable row">
						<p className="JobsHeading">Jobs</p>
						{supplierJobDetails.isDataLoaded || supplierJobDetails.status === "404" ? supplierJobDetails.data !== null ?
							<table className="sellerJobTable table table-bordered">
								<thead className="thead">
									<tr>
										<th className="sellerviewjobName tableHeading" data-sortable="true" data-sorter="alphanum">Job Name</th>
										<th className="sellerviewQuoterequiredby  tableHeading">Quote Required By</th>
										<th className="sellerviewtablepdfavailability tableHeading">Print PDF Availability</th>
										<th className="sellerviewtabledeliverydate  tableHeading">Delivery Date</th>
										<th className="sellerviewtablestatus  tableHeading">Status</th>
										<th className="sellerviewApprover  tableHeading">Approver</th>
									</tr>
								</thead>

								<tbody id="supplierJobsData">

									{supplierJobDetails.isDataLoaded ? supplierJobDetails.data.map((job) => {
										let jobId = job.id
										let JobURL = `/print/supplier/project/${projectId}/job/${jobId}`;
										return (
											<tr key={job.id}>
												<td><a className="projectTitleLink" href={JobURL}  >{job.name}</a></td>
												<td>{dateFormatter(job.quoteRequiredBy)}</td>
												<td>{dateFormatter(job.printPdfAvailability)}</td>
												<td>{dateFormatter(job.deliveryDate)}</td>
												<td>{job.status}</td>
												<td>{job.approver}</td>
											</tr>
										)
									}) : null

									}
								</tbody>
							</table> :
							<table className=" table-bordered">
								<thead className="thead">
									<tr>
										<th className="sellerviewjobName tableHeading" data-sortable="true" data-sorter="alphanum">Job Name</th>
										<th className="sellerviewQuoterequiredby  tableHeading">Quote Required By</th>
										<th className="sellerviewtablepdfavailability tableHeading">Print PDF Availability</th>
										<th className="sellerviewtabledeliverydate  tableHeading">Delivery Date</th>
										<th className="sellerviewtablestatus  tableHeading">Status</th>
										<th className="sellerviewApprover  tableHeading">Approver</th>
									</tr>
								</thead>

								<tbody id="ProjectData">
								<tr>
											<td className="noJobs" colspan="6">
												There are no jobs available for
												this project.
											</td>
										</tr>
								</tbody>
							</table>
							: <div className="sellerViewJobsLoader">
								<ClipLoader
									color={'#008080'}
									size={50}
									aria-label="Loading Spinner"
									data-testid="loader"
								/>
							</div>
						}
					</div>
				</div>

			</Container>

		</div>
	)
}

const mapStateToProps = (state) => {
	return { ...state.supplierData };
};

const mapDispatchToProps = (dispatch) => {
	return {

		getSupplierProject: (projectId) => {
			dispatch(getSupplierProject(projectId));
		},
		getSupplierJobs: (projectId) => {
			dispatch(getSupplierJobs(projectId));
		},

	};

}
export default connect(mapStateToProps, mapDispatchToProps)(ViewProject)