import React, { useState, useEffect } from 'react'
import Container from '../Common/Container/Container'

const PageNotFound =()=>{
    return (
		<div>
			<Container pageName={"Not Found"}>
           <p className="PageNotFound">Sorry, the requested page is not found.</p> 
				
			</Container>
		</div>
	)
}

export default PageNotFound;