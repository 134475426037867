import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Container from "../../../../Layouts/Common/Container/Container";
import { BuyerList } from "../../../../Utils/Constants";
import {
	createBuyerProject,
	createBuyerProjectPageInitialize,
	getClientList,
} from "../../../../Actions/BuyerJourneyActions";

export const BuyerCreateProject = ({
	createBuyerProject,
	buyerCreateProjectResponse,
	createBuyerProjectPageInitialize,
	clientList,
	getClientList,
}) => {
	useEffect(() => {
		createBuyerProjectPageInitialize({
			buyerCreateProjectResponse: {
				isCreateSuccessful: false,
				isCreateInitiated: false,
			},
		});
		getClientList();
	}, []);

	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			Title: "",
			Description: "",
			Buyer: "",
			Client: "",
			ClientReference: "",
			CompletionDate: "",
			Isbn: "",
			Impression: "",
			IsbnTitle: "",
			Notes: "",
			isIsbn: false,
		},
		validationSchema: Yup.object({
			Title: Yup.string()
				.max(1024, "Must be 1024 characters or less.")
				.required("Title is required."),
			Buyer: Yup.string()
				.max(512, "Must be 512 characters or less.")
				.required("Buyer is required."),
			Client: Yup.string()
				.max(512, "Must be 512 characters or less.")
				.required("Client is required."),
			Description: Yup.string().max(
				2048,
				"Must be 2048 characters or less."
			),
			Notes: Yup.string().max(2048, "Must be 2048 characters or less."),
			CompletionDate: Yup.date()
			.required("Completion Date is required."),
		}).shape({
			Impression: Yup.string()
				.ensure()
				.when("isIsbn", {
					is: true,
					then: Yup.string()
						.required()
						.max(16, "Must be 16 characters or less.")
						.required("Impression is required."),
				}),
			Isbn: Yup.string()
				.ensure()
				.when("isIsbn", {
					is: true,
					then: Yup.string()
						.required()
						.max(32, "Must be 32 characters or less.")
						.required("ISBN is required."),
				}),
			IsbnTitle: Yup.string()
				.ensure()
				.when("isIsbn", {
					is: true,
					then: Yup.string()
						.required()
						.max(512, "Must be 512 characters or less.")
						.required("ISBN Title is required."),
				}),
		}),
		validateOnChange: true,
		validateOnBlur: true,
		onSubmit: (values, { setSubmitting }) => {
			createBuyerProject(values);
		},
	});
	const handleIsISBNChange = (event) => {
		formik.setFieldValue("Isbn", "");
		formik.setFieldValue("IsbnTitle", "");
		formik.setFieldValue("Impression", "");
	};
	useEffect(() => {
		if (buyerCreateProjectResponse.isCreateSuccessful && !buyerCreateProjectResponse.isCreateInitiated) 
		{
			navigate(`/print/buyer/project/${buyerCreateProjectResponse.id}/update`);
		}
	}, [
		buyerCreateProjectResponse.isCreateSuccessful,
		buyerCreateProjectResponse.isCreateInitiated,
	]);
	return (
		<React.Fragment>
			<div>
				<Container pageName={"Create Project"}>
					{!buyerCreateProjectResponse.isCreateSuccessful &&
					buyerCreateProjectResponse.isCreateInitiated &&
					!buyerCreateProjectResponse.isCreateError ? (
						<div className="createProjectLoader">
							<ClipLoader
								color={"#008080"}
								size={50}
								aria-label="Loading Spinner"
								data-testid="loader"
							/>
						</div>
					) : (
						<form
							onSubmit={formik.handleSubmit}
							onReset={formik.resetForm}
						>
							<div className="newProjectRow row">
								<div className="col-md-3">
									<div className="newProjectDetailsColOne">
										<div className="NewProjectCol1TitleInputGroup">
											<label
												htmlFor="Title"
												className="required "
											>
												Title
											</label>
											<input
												type="text"
												className={
													formik.touched.Title &&
													formik.errors.Title
														? "form-control is-invalid"
														: "form-control"
												}
												{...formik.getFieldProps(
													"Title"
												)}
											/>

											<div className="invalid-feedback">
												{formik.touched.Title &&
													formik.errors.Title}
											</div>
										</div>
										<br />
										<div className="NewProjectCol1DescriptionInputGroup">
											<label htmlFor="Description">
												Description
											</label>
											<div className="form-outline">
												<textarea
													className={
														formik.errors
															.Description
															? "form-control is-invalid"
															: "form-control"
													}
													id="textAreaExample"
													rows="9"
													{...formik.getFieldProps(
														"Description"
													)}
												></textarea>
											</div>
										</div>
									</div>
								</div>
								<div className="newProjectDetailsColTwo col-md-3">
									<div className="NewProjectCol2BuyerInputGroup">
										<label
											htmlFor="Buyer"
											className="required"
										>
											Buyer
										</label>
										<select
											className={
												formik.touched.Buyer &&
												formik.errors.Buyer
													? "NewProjectselectbuyer  dropdownbtn dropdown-toggle is-invalid form-select"
													: "NewProjectselectbuyer  dropdownbtn dropdown-toggle form-select"
											}
											{...formik.getFieldProps("Buyer")}
										>
											<option value="" default>
												Please Select a Buyer
											</option>
											{BuyerList.map((buyer, idx) => {
												return (
													<option
														key={idx}
														value={buyer.value}
													>
														{buyer.label}
													</option>
												);
											})}
										</select>
										<div className="invalid-feedback">
											{formik.touched.Buyer &&
												formik.errors.Buyer}
										</div>
									</div>
									{clientList.isDataLoaded ? (
										<div className="NewProjectCol2ClientInputGroup">
											<label
												htmlFor="Client"
												className="required"
											>
												Client
											</label>
											<select
												title={formik.values.Client}
												className={
													formik.touched.Client &&
													formik.errors.Client
														? "NewProjectselectClient dropdownbtn dropdown-toggle is-invalid form-select"
														: "NewProjectselectClient dropdownbtn dropdown-toggle form-select"
												}
												{...formik.getFieldProps(
													"Client"
												)}
											>
												<option value="" default>
													Please Select a Client
												</option>
												{clientList.data.map((data) => {
													return (
														<option
															key={data.id}
															title={data.name}
															value={data.name}
														>
															{data.name}
														</option>
													);
												})}
											</select>
											<div className="invalid-feedback">
												{formik.touched.Client &&
													formik.errors.Client}
											</div>
										</div>
									) : (
										<div></div>
									)}

									<div className="NewProjectCol2ClientReferenceInputGroup">
										<label className="Client Reference">
											Client Reference
										</label>
										<input
											type="text"
											className="form-control"
											{...formik.getFieldProps(
												"ClientReference"
											)}
										></input>
									</div>
									<div className="NewProjectCol2ProjectCompletionDateInputGroup">
										<label
											htmlFor="Project Completion date"
											className="required"
										>
											Project Completion Date
										</label>
										<div
											className="input-date"
											id="datepicker"
										>
											<input
												type="date"
												className={
													formik.touched
														.CompletionDate &&
													formik.errors.CompletionDate
														? "dateselector is-invalid"
														: "dateselector"
												}
												{...formik.getFieldProps(
													"CompletionDate"
												)}
											/>
											<div className="invalid-feedback">
												{formik.touched
													.CompletionDate &&
													formik.errors
														.CompletionDate}
											</div>
											<span className="input-group-append"></span>
										</div>
									</div>
								</div>
								<div className=" col-md-3">
									<div className="isIsbnInlineData">
										<div>Is this an ISBN Job?</div>
										<div>
											<label className="switch">
												<input
													type="checkbox"
													{...formik.getFieldProps(
														"isIsbn"
													)}
													onChange={(event) => {
														formik.handleChange(
															event
														);
													}}
													onClick={(event) => {
														handleIsISBNChange(
															event
														);
													}}
													{...formik.getFieldProps(
														"isIsbn"
													)}
													value={formik.values.isIsbn}
													checked={
														formik.values.isIsbn
															? "checked"
															: null
													}
												/>
												<span className="slider round"></span>
											</label>
										</div>
									</div>

									<div className="NewProjectCol2ISBNInputGroup">
										<label htmlFor="ISBN">ISBN</label>
										<input
											type="text"
											className={
												formik.touched.Isbn &&
												formik.errors.Isbn
													? "form-control is-invalid"
													: "form-control"
											}
											{...formik.getFieldProps("Isbn")}
											disabled={
												!formik.values.isIsbn
													? "disabled"
													: null
											}
										/>
										<div className="invalid-feedback">
											{formik.touched.Isbn &&
												formik.errors.Isbn}
										</div>
									</div>

									<div className="NewProjectCol2ISBNTitleInputGroup">
										<label htmlFor="ISBN Title">
											ISBN Title
										</label>
										<input
											type="text"
											className={
												formik.touched.IsbnTitle &&
												formik.errors.IsbnTitle
													? "form-control is-invalid"
													: "form-control"
											}
											{...formik.getFieldProps(
												"IsbnTitle"
											)}
											disabled={
												!formik.values.isIsbn
													? "disabled"
													: null
											}
										/>
										<div className="invalid-feedback">
											{formik.touched.IsbnTitle &&
												formik.errors.IsbnTitle}
										</div>
									</div>

									<div className="NewProjectCol2ISBNImpressionInputGroup">
										<label htmlFor="Impression">
											Impression
										</label>
										<input
											type="text"
											className={
												formik.touched.Impression &&
												formik.errors.Impression
													? "form-control is-invalid"
													: "form-control"
											}
											{...formik.getFieldProps(
												"Impression"
											)}
											disabled={
												!formik.values.isIsbn
													? "disabled"
													: null
											}
										/>
										<div className="invalid-feedback">
											{formik.touched.Impression &&
												formik.errors.Impression}
										</div>
									</div>
								</div>
								<div className="newProjectDetailsColFour col-md-3">
									<div>
										{" "}
										<label htmlFor="Additional Notes">
											Additional Notes
										</label>
									</div>
									<div>
										<div className="form-outline">
											<textarea
												className="form-control"
												id="textAreaExample"
												rows="13"
												{...formik.getFieldProps(
													"Notes"
												)}
											></textarea>
										</div>
									</div>
								</div>
							</div>
							<div className="newProjectsubmitButtonrow">
								<button
									type="submit"
									className="newProjectSavebutton"
								>
									Save
								</button>
								<button
									type="reset"
									onClick={() => {
										navigate("/print/buyer/projects");
									}}
									className="newProjectCancelbutton"
								>
									Cancel
								</button>
							</div>
						</form>
					)}
				</Container>
			</div>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return { ...state.buyerData };
};

const mapDispatchToProps = (dispatch) => {
	return {
		createBuyerProject: (formData) => {
			dispatch(createBuyerProject(formData));
		},
		createBuyerProjectPageInitialize: (data) => {
			dispatch(createBuyerProjectPageInitialize(data));
		},
		getClientList: (data) => {
			dispatch(getClientList(data));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyerCreateProject);
