import React, { useEffect } from 'react'
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";


const Logout = () => {

	const navigate = useNavigate()

	useEffect(() => {
		localStorage.clear();
		Auth.signOut()
			.then(data => navigate('/login'))
			.catch();
	}, [])


	return (
		<div>Logout</div>
	)
}

export default Logout