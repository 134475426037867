
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { doesUserHasResourceAccess } from '../../../Utils/HelperFunctions'
import { BuyerCognitoGroup, SupplierCognitoGroup } from '../../../Utils/Constants'
import logo from "./masterLogo.jpg";

export const Navbar = ({ pageName, userData }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(false)
	const [userRole, setUserRole] = useState("")

	useEffect(() => {
		setIsAuthenticated(localStorage.getItem("isAuthenticated"))
		if (doesUserHasResourceAccess(BuyerCognitoGroup)) {
			setUserRole("buyer")
		}
		else if (doesUserHasResourceAccess(SupplierCognitoGroup)) {
			setUserRole("supplier")
		}
	}, [])

	return (
		<div>
			<div className="NavBarrow">
				<div className="NAVBAR col-md-6">
					{isAuthenticated
						?
						<nav className=" NavBarlist navbar navbar-expand-lg navbar-dark" id="NavBar">
							<div className="collapse navbar-collapse" id="navbarSupportedContent">
								<ul className="navbar-nav mr-auto">
									<li>
										<a href={`/print/${userRole}/projects`}>
											<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="teal" className="bi bi-house-door" viewBox="0 0 16 16">
												<path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146ZM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5Z" />
											</svg>
										</a>
									</li>
									<li className="nav-item active">
										{/* <span className="navbar-brand mb-0 h1">
                                            <a className="Activeheading nav-link" href={`/print/${userRole}/projects`}>
                                                PRINT PROCUREMENT
                                            </a>
                                        </span> */}
										<span className="navbar-brand mb-0 h1"><a className="Activeheading nav-link " href="#">PRINT
											PROCUREMENT<div id="border"></div></a></span>
									</li>
									{/* <li className="nav-item">
                                        <span className="navbar-brand mb-0 h1"><a className="heading nav-link" href="#">REPORTS</a></span>
                                    </li>
                                    <li className="nav-item">
                                        <span className="navbar-brand mb-0 h1"><a className="heading nav-link" href="#">SETTINGS</a></span>
                                    </li> */}
								</ul>
							</div>
						</nav>
						: null}
				</div>
				<div className="col-md-6">
					<div className="masterLogo">
						<img alt="" src={logo} />
					</div>
				</div>
			</div>
			<br />
			<div className="row">
				<div className="col-md-6">
					<div className="ProjectName">
						<p>
							{pageName}
						</p>
					</div>
				</div>
				{isAuthenticated ?
					<div className="col-md-6">
						<div className="UserProfile">
							<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="teal" className="bi bi-person-circle" viewBox="0 0 16 16">
								<path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
								<path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
							</svg>
							{/* <p className="flex-item">
                                <button className="btn btn-text-info dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                    {name}
                                </button>
                            </p> */}
							<div className="dropdown">
								<button className="btn btn-text-info dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle='dropdown' aria-haspopup="true" aria-expanded="false">
									{userData.name}
								</button>
								<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
									<a className="dropdown-item" href="/logout">Logout</a>
								</div>
							</div>
						</div>
					</div> : null}
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)