import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Container from "../../../../Layouts/Common/Container/Container";
import { useNavigate, useParams } from "react-router-dom";
import {
	getJobBids,
	getSupplierJob,
} from "../../../../Actions/SupplierJourneyActions";
import ClipLoader from "react-spinners/ClipLoader";
import BidCostingModal from "./BidCostingModal";
import {  dateFormatter } from "../../../../Utils/HelperFunctions";
import BidSubmitModal from "./BidSubmitModal";

export const ViewJob = ({
	getSupplierJob,
	supplierViewJob,
	getJobBids,
	supplierJobBid,
}) => {
	const [isBidCostOpen, setIsBidCostOpen] = useState(false);
	const [isBidSubmitConfirmationOpen, setIsBidSubmitConfirmationOpen] = useState(false);
	const navigate = useNavigate();
	const { projectId, jobId } = useParams();
	const [selectedQuantity, setSelectedQuantity] = useState("");
	const projectPageURL =`/print/supplier/project/${projectId}`
	
	

	function RenderBidResponseRow(brPropValueByQty, isQtyAvailable, isSpecialRow) {
		isSpecialRow = typeof (isSpecialRow) === 'boolean' ? isSpecialRow : false
		return (
			<tr>
				<td className='specialCell'>
					{brPropValueByQty.name}
				</td>
					
				{
					isQtyAvailable.isQty1Available ?
						(
							<td className={isSpecialRow ? "specialCell" : "normalCell"}>
								{brPropValueByQty.qty1}
							</td>
						) :<td className={isSpecialRow ? "specialCell" : "normalCell"}></td>
				}
				{
					isQtyAvailable.isQty2Available ?
						(
							<td className={isSpecialRow ? "specialCell" : "normalCell"}>
								{brPropValueByQty.qty2}
							</td>
						) : <td className={isSpecialRow ? "specialCell" : "normalCell"}></td>
				}
				{
					isQtyAvailable.isQty3Available ?
						(
							<td className={isSpecialRow ? "specialCell" : "normalCell"}>
								{brPropValueByQty.qty3}
							</td>
						) : <td className={isSpecialRow ? "specialCell" : "normalCell"}></td>
				}
				{
					isQtyAvailable.isQty4Available ?
						(
							<td className={isSpecialRow ? "specialCell" : "normalCell"}>
								{brPropValueByQty.qty4}
							</td>
						) : <td className={isSpecialRow ? "specialCell" : "normalCell"}></td>
				}
				{
					isQtyAvailable.isQty5Available ?
						(
							<td className={isSpecialRow ? "specialCell" : "normalCell"}>
								{brPropValueByQty.qty5}
							</td>
						) : <td className={isSpecialRow ? "specialCell" : "normalCell"}></td>
				}
				{
					isQtyAvailable.isQty6Available ?
						(
							<td className={isSpecialRow ? "specialCell" : "normalCell"}>
								{brPropValueByQty.qty6}
							</td>
						) : <td className={isSpecialRow ? "specialCell" : "normalCell"}></td>
				}
			</tr>
		)
	}

	function RenderBidReferenceRow(brPropValueByQty, isQtyAvailable, isSpecialRow, bidIdPropValueByQty) {
		isSpecialRow = typeof (isSpecialRow) === 'boolean' ? isSpecialRow : false
		return (
			<tr>
				<td className='specialCell'>
					{brPropValueByQty.name}
				</td>
				{
					isQtyAvailable.isQty1Available ?
						(
							<td className={isSpecialRow ? "specialCell" : "normalCell"}>
								<button className='bidReferenceLink btn btn-link ' onClick={() => { BidCostingOnClick(bidIdPropValueByQty.qty1) }}>
									{brPropValueByQty.qty1}
								</button>
							</td>
						) : <td className="normalCell"></td>
				}
				{
					isQtyAvailable.isQty2Available ?
						(
							<td className={isSpecialRow ? "specialCell" : "normalCell"}>
								<button className='bidReferenceLink btn btn-link' onClick={() => { BidCostingOnClick(bidIdPropValueByQty.qty2) }}>
									{brPropValueByQty.qty2}
								</button>
							</td>
						) : <td className="normalCell"></td>
				}
				{
					isQtyAvailable.isQty3Available ?
						(
							<td className={isSpecialRow ? "specialCell" : "normalCell"}>
								<button className='bidReferenceLink btn btn-link' onClick={() => { BidCostingOnClick(bidIdPropValueByQty.qty3) }}>
									{brPropValueByQty.qty3}
								</button>
							</td>
						) : <td className="normalCell"></td>
				}
				{
					isQtyAvailable.isQty4Available ?
						(
							<td className={isSpecialRow ? "specialCell" : "normalCell"}>
								<button className='bidReferenceLink btn btn-link' onClick={() => { BidCostingOnClick(bidIdPropValueByQty.qty4) }}>
									{brPropValueByQty.qty4}
								</button>
							</td>
						) : <td className="normalCell"></td>
				}
				{
					isQtyAvailable.isQty5Available ?
						(
							<td className={isSpecialRow ? "specialCell" : "normalCell"}>
								<button className='bidReferenceLink btn btn-link' onClick={() => { BidCostingOnClick(bidIdPropValueByQty.qty5) }}>
									{brPropValueByQty.qty5}
								</button>
							</td>
						) : <td className="normalCell"></td>
				}
				{
					isQtyAvailable.isQty6Available ?
						(
							<td className={isSpecialRow ? "specialCell" : "normalCell"}>
								<button className='bidReferenceLink btn btn-link' onClick={() => { BidCostingOnClick(bidIdPropValueByQty.qty6) }}>
									{brPropValueByQty.qty6}
								</button>
							</td>
						) : <td className="normalCell"></td>
				}
			</tr>
		)
	}

	function getPropQtyByName(bidResponsePropValuesByQty, propName) {
		return bidResponsePropValuesByQty.filter(bidResponseObject => {
			return bidResponseObject.name === propName.trim()
		})[0]
	}

	useEffect(() => {
		getSupplierJob({ projectId, jobId });
		getJobBids({ projectId, jobId });
	}, []);

	// useEffect(() => {
	//   getSupplierJob({ projectId, jobId });
	//   getJobBids({ projectId, jobId });
	// }, [supplierBidCostResponse.isCreateSuccessful]);

	const BidCostingOnClick = (selQuantity) => {
		setSelectedQuantity(selQuantity);
		setIsBidCostOpen(!isBidCostOpen);
	};

	const BidSubmitOnClick = () => {
		setIsBidSubmitConfirmationOpen(!isBidSubmitConfirmationOpen);
	};

	return (
		<div>
			<Container
				pageName={
					supplierViewJob.isDataLoaded
						? `${supplierViewJob.projectTitle} - ${supplierViewJob.name}`
						: ""
				}
			>
				{supplierViewJob.isDataLoaded ? (
					<div className="  sellerViewMajorRow  row">
						<div className="sellerViewProjectGeneral col-md-3">
							<div>
								<p className="Heading">GENERAL</p>
							</div>
							<div className="InlineData">
								<div className="fieldName">Client</div>
								<div className="value">{supplierViewJob.client}</div>
							</div>
							<div className="InlineData">
								<div className="fieldName">ISBN</div>
								<div className="value">{supplierViewJob.isbn}</div>
							</div>
							<div className="InlineData">
								<div className="fieldName">ISBN Title</div>
								<div className="value">{supplierViewJob.isbnTitle}</div>
							</div>
							<div className="InlineData">
								<div className="fieldName">Job Name</div>
								<div className="value">{supplierViewJob.name}</div>
							</div>
							<div className="InlineData">
								<div className="fieldName">Approver</div>
								<div className="value">{supplierViewJob.approver}</div>
							</div>
							<div className="InlineData">
								<div className="fieldName">Quote Required By</div>
								<div>
									{dateFormatter(
										supplierViewJob.quoteRequiredBy
									)}
								</div>
							</div>
							<div className="InlineData">
								<div className="fieldName">Print Pdf Availability</div>
								<div className="value">
									{dateFormatter(
										supplierViewJob.printPdfAvailability
									)}
								</div>
							</div >
							<div className="InlineData">
								<div className="fieldName">Delivery Date</div>
								<div className="value">
									{dateFormatter(
										supplierViewJob.deliveryDate
									)}
								</div>
							</div>
						</div>

						<div className="sellerViewProjectSpecification col-md-5">
							<div>
								<p className="Heading">SPECIFICATIONS</p>
							</div>
							<div className="row">
								<div className="col-md-6">
								
									<div className="sellerSpecInlineData">
										<div className="fieldName">Proofs</div>
										<div >{supplierViewJob.proofs}</div>
									</div>
									<div className="sellerSpecInlineData">
										<div className="fieldName">Finished Size</div>
										<div >
											{supplierViewJob.finishedSize}
										</div>
									</div>
									<div className="sellerSpecInlineData">
										<div className="fieldName">Orientation</div>
										<div >{supplierViewJob.orientation}</div>
									</div>
									<div className="sellerSpecInlineData">
										<div className="fieldName">Cover Page</div>
										<div >{supplierViewJob.coverPages}</div>
									</div>
									<div className="sellerSpecInlineData">
										<div className="fieldName">Cover Colours</div>
										<div >
											{supplierViewJob.coverColours}
										</div>
									</div>
									<div className="sellerSpecInlineData">
										<div className="fieldName">Cover Finish</div>
										<div >{supplierViewJob.coverFinish}</div>
									</div>
									<div className="sellerSpecInlineData">
										<div className="fieldName">Cover Material</div>
										<div >
											{supplierViewJob.coverMaterial}
										</div>
									</div>
								</div>
								{/* <div className="col-md-1">
									<div className="sellerviewvertical"></div>
								</div> */}
								<div className="col-md-6">
								<div className="sellerviewvertical"></div>
									<div className="sellerSpecInlineData">
										<div className="fieldName">Text Pages</div>
										<div >{supplierViewJob.textPages}</div>
									</div>
									<div className="sellerSpecInlineData">
										<div className="fieldName">Text Colours</div>
										<div >{supplierViewJob.textColours}</div>
									</div>
									<div className="sellerSpecInlineData">
										<div className="fieldName">Text Finish</div>
										<div >{supplierViewJob.textFinish}</div>
									</div>
									<div className="sellerSpecInlineData">
										<div className="fieldName">Text Material</div>
										<div >
											{supplierViewJob.textMaterial}
										</div>
									</div>
									<div className="sellerSpecInlineData">
										<div className="fieldName">Binding/Finishing</div>
										<div >{supplierViewJob.bindingFinishing}</div>
									</div>
									<div className="sellerSpecInlineData">
										<div className="fieldName">Packing</div>
										<div >{supplierViewJob.packing}</div>
									</div>
									<div className="sellerSpecInlineData">
										<div className="fieldName">File Copies</div>
										<div >
											{supplierViewJob.fileCopies}
										</div>
									</div>
									<div className="sellerSpecInlineData">
										<div className="fieldName">Required Carbon Cost</div>
										{
											(supplierViewJob.doesCarbonBalancingRequired =
												true ? (
													<div>Yes</div>
												) : (
													<div>No</div>
												))
										}
									</div>
								</div>
							</div>
						</div>

						<div className="sellerViewProjectDelivery col-md-3">
							<div>
								<p className="Heading">Delivery</p>
								<div className="Address">
									
									<div><b>{supplierViewJob.recipientName}</b></div>

								<div>{supplierViewJob.addressLine1}</div>
								<div>{supplierViewJob.postCode}</div>
								<div>{supplierViewJob.county}</div>
								<div className="fieldName">Additional Notes</div>
								<textarea
										className="form-control"
										id="textAreaExample"
										value={supplierViewJob.notes}
										rows="4"
										readOnly
									></textarea> 
								</div>
							</div>
							
						</div>
						{supplierJobBid.isDataLoaded ? supplierJobBid.jobStatus ==='Live' && supplierJobBid.projectStatus ==='Live' ?
						
						<div className=" sellerViewSubmitTab col-md-1">
						<button
							type="button"
							onClick={BidSubmitOnClick}
							className="sellerViewSubmit"
						>
							Submit
						</button>
						<button type="button" className="sellerViewCancel" onClick={()=>navigate(projectPageURL)}>
							Cancel
						</button>
					</div>:
					<div className=" sellerViewSubmitTab col-md-1">
					
					<button type="button" className="sellerViewCancel" onClick={()=>navigate(projectPageURL)}>
						Cancel
					</button>
				</div>:
				null
						}
						
					</div>
				) : (
					<div className="ViewJobLoader">
						<ClipLoader
							color={"#008080"}
							size={50}
							aria-label="Loading Spinner"
							data-testid="loader"
						/>
					</div>
				)}

				<div className=" sellersviewPageRow row">
					{supplierJobBid.isDataLoaded ? (
						<div >
							<table className="sellersviewPageTable table table-bordered">
								<tbody className="sellersviewJobBids">
									{RenderBidResponseRow(getPropQtyByName(supplierJobBid.brPropValuesByQuantities, "Cost/Quantity"), supplierJobBid.qtyAvailability, true,)}
									{RenderBidReferenceRow(getPropQtyByName(supplierJobBid.brPropValuesByQuantities, "Bid Reference"), supplierJobBid.qtyAvailability, false, supplierJobBid.brPropValuesByQuantities[1])}
									{RenderBidResponseRow(getPropQtyByName(supplierJobBid.brPropValuesByQuantities, "Print Cost"), supplierJobBid.qtyAvailability, false,)}
									{RenderBidResponseRow(getPropQtyByName(supplierJobBid.brPropValuesByQuantities, "Paper Cost"), supplierJobBid.qtyAvailability, false,)}
									{RenderBidResponseRow(getPropQtyByName(supplierJobBid.brPropValuesByQuantities, "Delivery Cost"), supplierJobBid.qtyAvailability, false,)}
									{RenderBidResponseRow(getPropQtyByName(supplierJobBid.brPropValuesByQuantities, "Carbon Balancing Cost"), supplierJobBid.qtyAvailability, false,)}
									{RenderBidResponseRow(getPropQtyByName(supplierJobBid.brPropValuesByQuantities, "Bid Expiry Date"), supplierJobBid.qtyAvailability, false,)}
									{RenderBidResponseRow(getPropQtyByName(supplierJobBid.brPropValuesByQuantities, "Notes"), supplierJobBid.qtyAvailability, false,)}
									{RenderBidResponseRow(getPropQtyByName(supplierJobBid.brPropValuesByQuantities, "Decline Bid"), supplierJobBid.qtyAvailability, false,)}
									{RenderBidResponseRow(getPropQtyByName(supplierJobBid.brPropValuesByQuantities, "Declined Reason"), supplierJobBid.qtyAvailability, false,)}
									{RenderBidResponseRow(getPropQtyByName(supplierJobBid.brPropValuesByQuantities, "Total Cost"), supplierJobBid.qtyAvailability, false,)}
								
								</tbody>
							</table>
							<BidCostingModal
								selectedBidId={selectedQuantity}
								projectId={projectId}
								jobId={jobId}
								isBidCostOpen={isBidCostOpen}
								BidCostingOnClick={BidCostingOnClick}
							/>
							<BidSubmitModal
								projectId={projectId}
								jobId={jobId}
								projectName={supplierViewJob.projectTitle}
								jobName={supplierViewJob.name}
								BidSubmitOnClick={BidSubmitOnClick}
								isBidSubmitConfirmationOpen={
									isBidSubmitConfirmationOpen
								}
								
							/>
						</div>
					) : (
						""
					)}
					<div className="bidResponseLoader">
						<ClipLoader
							loading={
								supplierJobBid.apiStatus !== "FAILED" &&
								supplierJobBid.apiStatus !== "SUCCESSFUL"
							}
							color={"#008080"}
							size={50}
							aria-label="Loading Spinner"
							data-testid="loader"
						/>
					</div>
					{supplierJobBid.apiStatus === "FAILED" ? (
						<div>{supplierJobBid.message}</div>
						
					) : (
						""
					)}
				</div>
			</Container>
		</div>
	);
};
const mapStateToProps = (state) => {
	return { ...state.supplierData };
};

const mapDispatchToProps = (dispatch) => {
	return {
		getSupplierJob: (data) => {
			dispatch(getSupplierJob(data));
		},
		getJobBids: (data) => {
			dispatch(getJobBids(data));
		},
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewJob);
