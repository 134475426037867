import React, { useEffect } from "react";
import { connect } from "react-redux";
import { submitBid } from "../../../../Actions/SupplierJourneyActions";
import { Modal, Container } from "react-bootstrap";

export const BidSubmitModal = ({
	BidSubmitOnClick,
	projectName,
	jobName,
	isBidSubmitConfirmationOpen,
	projectId,
	jobId,
	submitBid,
	supplierBidSubmitResponse,
}) => {
	useEffect(() => {
		if (supplierBidSubmitResponse.status === "SUBMIT_FAILED") {
			BidSubmitOnClick();
		}
	}, [supplierBidSubmitResponse.status]);

	return (
		<div>
			<Modal
				show={isBidSubmitConfirmationOpen}
				onHide={BidSubmitOnClick}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				backdrop="static"
				keyboard={false}
				centered
			>
				<Modal.Header>
					<Modal.Title id="contained-modal-title-vcenter">
						<h5 className="bidSubmitConfirmationHeading">
							Bid Submit Confirmation
						</h5>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container className="bidSubmitModalBox">
						{supplierBidSubmitResponse.status !==
						"SUBMIT_FAILED" ? (
							// supplierBidSubmitResponse.detailedMessages.length ===0
							<div className="bidSubmitInfo">
								<span className="bidSubmitProjectInfo">
									Your bid for the project
								</span>
								<br></br>'{projectName}' - '{jobName}'<br></br>{" "}
								<span className="bidSubmitProjectInfo">
									will be submitted.
								</span>
							</div>
						) : (
							<div>
								<p className="bidSubmitErrorInfo">
									The following error(s) are identified, so
									unable to submit the bid{" "}
								</p>
								<ul>
									<br />
									{supplierBidSubmitResponse.detailedMessages.map(
										(message, idx) => {
											return (
												<div key={idx}>
													<li
														className="bidSubmitError"
														key={idx}
													>
														{message}
													</li>
													<br />
												</div>
											);
										}
									)}
								</ul>
							</div>
						)}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					{supplierBidSubmitResponse.status !== "SUBMIT_FAILED" ? (
						<button
							onClick={() => {
								submitBid({ projectId, jobId });
								BidSubmitOnClick();
							}}
							className="bidSubmitConfirmButton"
						>
							Ok
						</button>
					) : (
						""
					)}
					<button
						onClick={() => {
							BidSubmitOnClick();
							window.location.href = `/print/supplier/project/${projectId}/job/${jobId}`;
						}}
						className="bidSubmitCancelButton"
					>
						Close
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state) => {
	return { ...state.supplierData };
};

const mapDispatchToProps = (dispatch) => {
	return {
		submitBid: (data) => {
			dispatch(submitBid(data));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BidSubmitModal);
