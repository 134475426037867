const initialData = {
	supplierProjectListPage: { isDataLoaded: false },
	supplierProjectDetails: { isDataLoaded: false },
	supplierJobDetails: { isDataLoaded: false },
	supplierViewJob: { isDataLoaded: false },
	supplierJobBid: { isDataLoaded: false },
	supplierBidCostResponse: { isCreateSuccessful: false, isCreateInitiated: false, isCreateError: false },
	supplierBidDetails: { isDataLoaded: false },
	supplierBidSubmitResponse: { status: "NOT_SUBMITTED" }
}

const SupplierDataReducer = (state = initialData, action) => {
	switch (action.type) {
		case "SET_SUPPLIER_PROJECT_LIST_RESPONSE":
			return { ...state, ...action.payload };
		case "GET_SUPPLIER_PROJECT_LIST_INITIATED":
			return { ...state, ...action.payload };
		case "GET_SUPPLIER_PROJECT_LIST_FAILED":
			return { ...state, ...action.payload };
		case "SET_SUPPLIER_PROJECT_RESPONSE":
			return { ...state, ...action.payload }
		case "GET_SUPPLIER_PROJECT_INITIATED":
			return { ...state, ...action.payload }
		case "GET_SUPPLIER_PROJECT_FAILED":
			return { ...state, ...action.payload };
		case "GET_SUPPLIER_JOBS_INITIATED":
			return { ...state, ...action.payload };
		case "SET_SUPPLIER_JOBS_RESPONSE":
			return { ...state, ...action.payload }
		case "GET_SUPPLIER_JOBS_FAILED":
			return { ...state, ...action.payload }
		case "SET_SUPPLIER_JOB_RESPONSE":
			return { ...state, ...action.payload }
		case "GET_SUPPLIER_JOB_INITIATED":
			return { ...state, ...action.payload }
		case "GET_SUPPLIER_JOB_BIDS_INITIATED":
			return { ...state, ...action.payload }
		case "SET_SUPPLIER_JOB_BIDS_RESPONSE":
			return { ...state, ...action.payload }
		case "GET_SUPPLIER_JOB_BIDS_FAILED":
			return { ...state, ...action.payload }
		case "SAVE_SUPPLIER_BID_COST_INITIATED":
			return { ...state, ...action.payload }
		case "SET_SAVE_SUPPLIER_BID_COST_RESPONSE":
			return { ...state, ...action.payload }
		case "SET_SAVE_SUPPLIER_BID_COST_FAILED":
			return { ...state, ...action.payload }
		case "GET_SUPPLIER_BID_DETAILS_INITIATED":
			return { ...state, ...action.payload }
		case "SET_SUPPLIER_BID_DETAILS_RESPONSE":
			return { ...state, ...action.payload }
		case "SUBMIT_SUPPLIER_BID_INITIATED":
			return { ...state, ...action.payload }
		case "SUBMIT_SUPPLIER_BID_RESPONSE":
			return { ...state, ...action.payload }
		case "SUBMIT_SUPPLIER_BID_FAILED":
			return { ...state, ...action.payload }
		default:
			return state;
	}

}

export default SupplierDataReducer;