import React from "react";
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import { Modal } from "react-bootstrap";
// import { useFormik } from "formik";



import { ConfirmSupplier } from "../../../../Actions/BuyerJourneyActions";
export const BidSelectionConfirmation = ({
	isShowBidSelectConfirmation,
	confirmBidSelection,
	confirmedSupplierValue,
	ConfirmSupplier,
	confirmedQtyValue,
	projectId,
	jobId,
	confirmedSupplierName,
	buyerConfirmSupplier,
	buyerProjectDetails,
	buyerJobDetails,
	projectTitle,
	jobName,
}) => {
	function confirmSupplierFunction() {
		ConfirmSupplier({
			bidResponse: {
				supplierId: confirmedSupplierValue,
				qty: confirmedQtyValue,
			},
			jobId,
			projectId,
		});
	}
	

	return (
		<div>
			{isShowBidSelectConfirmation ? (
				<Modal
					show={true}
					onHide={confirmBidSelection}
					backdrop="static"
					keyboard={false}
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					<Modal.Header>
						<Modal.Title>
							<h4 className="BidSelectionConfirmationHeading">
								Bid Selection Confirmation
							</h4>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Container>
							{buyerConfirmSupplier.apiStatus !==
							"SUBMIT_FAILED" ? (
								<div className="manageBidSelectedbox">
									<p>You have selected the Supplier</p>
									<p>
										<span className="Suppliername">
											'{confirmedSupplierName}'
										</span>{" "}
										and
										<span className="Suppliername">
											{" "}
											'{confirmedQtyValue}'
										</span>{" "}
										quantity
									</p>
									<p>bid for Project</p>
									<p className="projectName">
										'{projectTitle}' - '{jobName}'
									</p>

									<div className="manageBidSelectedsubmitButtonrow">
										{/* <p className="manageBidSelectnote">
										(Click 'OK' to confirm. 'Cancel' to
										select someone.)
									</p> */}
										<button
											type="button"
											className=" manageBidSelectedSavebutton"
											onClick={() =>
												confirmSupplierFunction()
											}
										>
											Ok
										</button>
										<button
											type="button"
											className="manageBidSelectedCancelbutton"
											onClick={confirmBidSelection}
										>
											Cancel
										</button>
									</div>
								</div>
							) : (
								<div className="manageBidSelectedbox">
									<p className="bidSubmitErrorInfo">
										The following error(s) are identified,
										so unable confirm the supplier.{" "}
									</p>
									<ul>
										<br />
										{buyerConfirmSupplier.detailedMessages.map(
											(message, idx) => {
												return (
													<div key={idx}>
														<li
															className="bidSubmitError"
															key={idx}
														>
															{message}
														</li>
														<br />
													</div>
												);
											}
										)}
									</ul>
									<button
										type="button"
										className="ConfirmSupplierCloseButton"
										onClick={() => {
											confirmBidSelection();
											window.location.href = `/print/buyer/project/${projectId}/job/${jobId}/bids`;
										}}
									>
										Close
									</button>
								</div>
							)}
						</Container>
					</Modal.Body>
				</Modal>
			) : null}
		</div>
	);
};
const mapStateToProps = (state) => {
	return { ...state.buyerData };
};

const mapDispatchToProps = (dispatch) => {
	return {
		ConfirmSupplier: (data) => {
			dispatch(ConfirmSupplier(data));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BidSelectionConfirmation);
