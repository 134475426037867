import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
	saveBidCosting,
	getBidDetails,
	getJobBids
} from "../../../../Actions/SupplierJourneyActions";
import { dateFormatter } from "../../../../Utils/HelperFunctions";

function BidCostModal({
	isBidCostOpen,
	BidCostingOnClick,
	selectedBidId,
	saveBidCosting,
	supplierBidCostResponse,
	projectId,
	jobId,
	getBidDetails,
	supplierBidDetails,
	supplierJobBid
	
}) {
	const [bidCostingModalData, setBidCostingModalData] = useState({});
	
	

	useEffect(() => {
		if (typeof selectedBidId === "string" && selectedBidId !== "") {
			setBidCostingModalData({
				id: "",
				reference: "",
				qty: "",
				printCost: "",
				paperCost: "",
				deliveryCost: "",
				carbonBalancingCost: "",
				notes: "",
				isDeclinedToBid: true,
				declinedReason: "",
				expiryDate: "",
				status:"",
			});
			getBidDetails({ selectedBidId, projectId, jobId });
			
		}
	}, [selectedBidId]);

	useEffect(() => {
		if (supplierBidDetails.isDataLoaded === true) {
			setBidCostingModalData({
				...supplierBidDetails.data,
				expiryDate:
					supplierBidDetails.data.expiryDate !== null
						? dateFormatter(supplierBidDetails.data.expiryDate, false)
						: "",
			});
		}
	}, [supplierBidDetails]);

	const formik = useFormik({
		initialValues: {
			...bidCostingModalData,
		},
		validateOnChange: false,
		validateOnBlur: true,
		enableReinitialize: true,
		onSubmit: (values, { setSubmitting }) => {
			values.expiryDate =
				values.expiryDate === "" ? null : values.expiryDate;
			saveBidCosting({ values, projectId, jobId });
		},
		validationSchema: Yup.object({
			printCost: Yup.string()
				.max(1024, "Must be 1024 characters or less"),
				
			paperCost: Yup.string()
				.max(1024, "Must be 1024 characters or less"),
				
			deliveryCost: Yup.string()
				.max(1024, "Must be 1024 characters or less"),
				
		}).shape({
			declinedReason: Yup.string()
				.ensure()
				.when("isDeclinedToBid", {
					is: true,
					then: Yup.string()
						.required("Declined reason is required")
						.max(64, "Must be 16 characters or less"),
				})
		}),
	});
	
	

	return (
		<div>

			{isBidCostOpen &&
				supplierBidDetails.isDataLoaded &&
				!supplierBidCostResponse.isCreateSuccessful &&
				selectedBidId !== "" ? (
				<>
					<Modal
						show={true}
						onHide={BidCostingOnClick}
						backdrop="static"
						keyboard={false}
						size="lg"
						aria-labelledby="contained-modal-title-vcenter"
						centered
					>
						<Modal.Header >
							<Modal.Title>
								<h4 className="bidCostingHeading">
									Bid Costing
								</h4>
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Container>
								<form
									onSubmit={formik.handleSubmit}
									onReset={formik.resetForm}
								>
									<div className={bidCostingModalData.status ==="Active" ? "row" : " disableBidCost row"}>
										
										<div className="col-md-6">
											<div className="BidCostingInlineData">
												<label>Bid ID</label>
												<div>
													{
														supplierBidDetails.data
															.reference
													}{" "}
												</div>
											</div>
											<div className="BidCostingInlineData">
												<label>Quantity</label>
												<div>
													{
														supplierBidDetails.data
															.qty
													}
												</div>
											</div>

											<div className="BidCostingInlineData">
												<label
													htmlFor="Print Cost"
													
												>
													Print Cost
												</label>
												<div className="displayFlex">
													<input
														type="text"
														className={
															formik.errors
																.printCost
																? " reqBidCostingReqInputValues is-invalid"
																: "reqBidCostingReqInputValues"
														}
														{...formik.getFieldProps(
															"printCost"
														)}
													></input>
													<label className="invalid-feedback">
														{
															formik.errors
																.printCost
														}
													</label>
												</div>
											</div>

											<div className="BidCostingInlineData">
												<label
													htmlFor="Paper Cost"
													
												>
													Paper Cost
												</label>
												<div>
													<input
														type="text"
														className={
															formik.errors
																.paperCost
																? "reqBidCostingReqInputValues is-invalid"
																: "reqBidCostingReqInputValues"
														}
														{...formik.getFieldProps(
															"paperCost"
														)}
													></input>
													<label className="invalid-feedback">
														{
															formik.errors
																.paperCost
														}
													</label>
												</div>
											</div>

											<div className="BidCostingInlineData">
												<label
													htmlFor="Delivery Cost"
													
												>
													Delivery Cost
												</label>
												<div>
													<input
														type="text"
														className={
															formik.errors
																.deliveryCost
																? "reqBidCostingReqInputValues is-invalid"
																: "reqBidCostingReqInputValues"
														}
														{...formik.getFieldProps(
															"deliveryCost"
														)}
													></input>
													<div className="invalid-feedback">
														{
															formik.errors
																.deliveryCost
														}
													</div>
												</div>
											</div>
											<div className="BidCostingInlineData">
												<label htmlFor="Carbon Balancing Cost">
													Carbon Balancing Cost
												</label>
												<input
													type="text"
													className="bidCostingInputValues"
													{...formik.getFieldProps(
														"carbonBalancingCost"
													)}
												></input>
											</div>
								
											<div className="BidCostingInlineData">
												<label htmlFor="Bid Expiry Date">
													Bid Expiry Date
												</label>
												<div
													className=" input-date"
													id="datepicker"
												>
													<input
														type="date"
														className="bidExpiryDate"
														{...formik.getFieldProps(
															"expiryDate"
														)}
													/>
												</div>
											</div>
										
                                         </div>
										<div className=" BidCosting col-md-6">
											<div>
												<label htmlFor="Notes">
													Notes
												</label>
												<div className="form-outline">
													<textarea
														className="form-control"
														id="textAreaExample"
														rows="5"
														{...formik.getFieldProps(
															"notes"
														)}
													></textarea>
												</div>
											</div>
											<div className="BidCostingInlineData">
												<label htmlFor="Declined to Bid">
													Decline Bid
												</label>
												<div>
													<label className="switch">
														<input
															type="checkbox"
															{...formik.getFieldProps(
																"isDeclinedToBid"
															)}
														/>
														<span className="slider round"></span>
													</label>
												</div>
											</div>
											<div className="declinedReasonInputGroup">
												<label htmlFor="Declined Reason">
													Declined Reason
												</label>
												<div className="form-outline">
													<textarea
														className={formik.errors.declinedReason ? "form-control is-invalid" : "form-control"}
														id="textAreaExample"
														rows="3"
														{...formik.getFieldProps(
															"declinedReason"
														)}
													></textarea>
													<div className="invalid-feedback">
														{formik.errors.declinedReason}
													</div>
												</div>

											</div>
											
										
										</div>
										</div>
									
									{bidCostingModalData.status ==="Active" && supplierJobBid.jobStatus === "Live" && supplierJobBid.projectStatus === "Live"
? 
											<div className="bidCostingSubmitRow">
											<button
												type="submit"
												className="bidCostingSave"
											>
												Save
											</button>
											<button
												type="button"
												className="bidCostingCancel"
												onClick={BidCostingOnClick}
											>
												Cancel
											</button>
										</div> :
										<div className="bidCostingSubmitRow">
										
										<button
											type="button"
											className="bidCostingCancel"
											onClick={BidCostingOnClick}
										>
											Cancel
										</button>
									</div>
											} 
								</form>
							</Container>
						</Modal.Body>
					</Modal>
				</>
			) : null}
		</div>
	);
}

const mapStateToProps = (state) => {
	return { ...state.supplierData };
};

const mapDispatchToProps = (dispatch) => {
	return {
		saveBidCosting: (data) => {
			dispatch(saveBidCosting(data));
		},
		getBidDetails: (data) => {
			dispatch(getBidDetails(data));
		},
		getJobBids: (data) => {
			dispatch(getJobBids(data));
		},
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(BidCostModal);
