import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { doesUserHasResourceAccess } from "./../Utils/HelperFunctions"
import { AuthenticatedBuyerUserHomepage, AuthenticatedSupplierUserHomepage, SupplierCognitoGroup, BuyerCognitoGroup } from "./../Utils/Constants"

const UnProtectedRoute = () => {
	const isAuthenticated = localStorage.getItem("cognitoUser");
	return (!isAuthenticated ?
		<Outlet /> : doesUserHasResourceAccess(SupplierCognitoGroup) ? <Navigate to={AuthenticatedSupplierUserHomepage} /> : doesUserHasResourceAccess(BuyerCognitoGroup) ? <Navigate to={AuthenticatedBuyerUserHomepage} /> : <Outlet />);
}

export default UnProtectedRoute;