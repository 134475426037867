import { call, put, takeEvery } from 'redux-saga/effects'
import { get, post } from "./../Services/API/ApiClient"

function* getSupplierProjectList(data) {
	try {
		yield put({ type: "GET_SUPPLIER_PROJECT_LIST_INITIATED", payload: { supplierProjectListPage: { isDataLoaded: false } } });
		const responseData = yield call(post, "/supplier/project/search", "print", data.payload);
		yield put({ type: "SET_SUPPLIER_PROJECT_LIST_RESPONSE", payload: { supplierProjectListPage: { ...responseData.data, isDataLoaded: true } } });
	} catch (e) {
		yield put({ type: "GET_SUPPLIER_PROJECT_LIST_FAILED", message: e.message });
		
	}
}

function* getSupplierProject(data) {
	try {
		yield put({ type: "GET_SUPPLIER_PROJECT_INITIATED", payload: { supplierProjectDetails: { isDataLoaded: false } } });
		const responseData = yield call(get, `/supplier/project/${data.payload.projectId}`, "print");
		yield put({ type: "SET_SUPPLIER_PROJECT_RESPONSE", payload: { supplierProjectDetails: { ...responseData.data, isDataLoaded: true } } });
	} catch (e) {
		yield put({ type: "GET_SUPPLIER_PROJECT_FAILED", payload: { supplierProjectDetails: { ...e, isCreateSuccessful: false, isCreateInitiated: true } } });
	}
}

function* getSupplierJobs(data) {
	try {
		yield put({ type: "GET_SUPPLIER_JOBS_INITIATED", payload: { supplierJobDetails: { isDataLoaded: false } } });
		const responseData = yield call(get, `/supplier/project/${data.payload.projectId}/jobs`, "print");
		yield put({ type: "SET_SUPPLIER_JOBS_RESPONSE", payload: { supplierJobDetails: { ...responseData, isDataLoaded: true } } });
	} catch (e) {
		//TODO: Create reducer to update the state
		yield put({ type: "GET_SUPPLIER_JOBS_FAILED", payload: { supplierJobDetails: { ...e, isCreateSuccessful: false, isCreateInitiated: true } } });
	}
}

function* getSupplierJob(data) {
	try {
		yield put({ type: "GET_SUPPLIER_JOB_INITIATED", payload: { supplierViewJob: { isDataLoaded: false } } });
		const responseData = yield call(get, `/supplier/project/${data.payload.projectId}/job/${data.payload.jobId}`, "print");
	
		yield put({ type: "SET_SUPPLIER_JOB_RESPONSE", payload: { supplierViewJob: { ...responseData.data, isDataLoaded: true } } });
	} catch (e) {
		//TODO: Create reducer to update the state
		yield put({ type: "GET_SUPPLIER_JOB_FAILED", payload: { supplierViewJob: { ...e, isCreateSuccessful: false, isCreateInitiated: true } } });
	}
}

function* getJobBids(data) {
	try {
		yield put({ type: "GET_SUPPLIER_JOB_BIDS_INITIATED", payload: { supplierJobBid: { isDataLoaded: false, apiStatus: "INITIATED" } } });
		const responseData = yield call(get, `/supplier/project/${data.payload.projectId}/job/${data.payload.jobId}/bids`, "print");
		yield put({ type: "SET_SUPPLIER_JOB_BIDS_RESPONSE", payload: { supplierJobBid: { ...responseData.data, isDataLoaded: true, "apiStatus": "SUCCESSFUL" } } });
	} catch (error) {
		yield put({ type: "GET_SUPPLIER_JOB_BIDS_FAILED", payload: { supplierJobBid: { ...error, isDataLoaded: false, apiStatus: "FAILED" } } });
	}
}

function* saveBidCost(data) {
	try {
		yield put({ type: "SAVE_SUPPLIER_BID_COST_INITIATED", payload: { supplierBidCostResponse: { isCreateSuccessful: false, isCreateInitiated: true, isCreateError: false } } });
		const responseData = yield call(post, `/supplier/project/${data.payload.projectId}/job/${data.payload.jobId}/bid/${data.payload.values.id}`, "print", data.payload.values);
		yield put({ type: "SET_SAVE_SUPPLIER_BID_COST_RESPONSE", payload: { supplierBidCostResponse: { ...responseData.data, isCreateSuccessful: true, isCreateInitiated: true, isCreateError: false } } });
		window.location.href = `/print/supplier/project/${data.payload.projectId}/job/${data.payload.jobId}`
	}
	catch (e) {
		yield put({ type: "SET_SAVE_SUPPLIER_BID_COST_FAILED", payload: { supplierBidCostResponse: { ...e, isCreateSuccessful: false, isCreateInitiated: true, isCreateError: true } } });
	}
}

function* getBidDetails(data) {
	yield put({ type: "GET_SUPPLIER_BID_DETAILS_INITIATED", payload: { supplierBidDetails: { isDataLoaded: false } } });
	const responseData = yield call(get, `/supplier/project/${data.payload.projectId}/job/${data.payload.jobId}/bid/${data.payload.selectedBidId}`, "print");
	yield put({ type: "SET_SUPPLIER_BID_DETAILS_RESPONSE", payload: { supplierBidDetails: { ...responseData, isDataLoaded: true } } });
}

function* submitSupplierBid(data) {
	try {
		yield put({ type: "SUBMIT_SUPPLIER_BID_INITIATED", payload: { supplierBidSubmitResponse: { status: "SUBMITTED" } } });
		const responseData = yield call(post, `/supplier/project/${data.payload.projectId}/job/${data.payload.jobId}/bids/submit`, "print", {});
		yield put({ type: "SUBMIT_SUPPLIER_BID_RESPONSE", payload: { supplierBidSubmitResponse: { ...responseData.data, status: "SUBMIT_SUCCESSFUL" } } });
		// window.location.href = `/print/supplier/projects`
		window.location.href = `/print/supplier/project/${data.payload.projectId}`
	}
	catch (e) {
	
		yield put({ type: "SUBMIT_SUPPLIER_BID_FAILED", payload: { supplierBidSubmitResponse: { ...e, status: "SUBMIT_FAILED" } } });
	}
}

function* SupplierJourneySagas() {
	yield takeEvery("GET_SUPPLIER_PROJECT_LIST", getSupplierProjectList);
	yield takeEvery("GET_SUPPLIER_PROJECT", getSupplierProject)
	yield takeEvery("GET_SUPPLIER_JOBS", getSupplierJobs)
	yield takeEvery("GET_SUPPLIER_JOB", getSupplierJob)
	yield takeEvery("GET_SUPPLIER_JOB_BIDS", getJobBids)
	yield takeEvery("SAVE_SUPPLIER_BID_COST", saveBidCost)
	yield takeEvery("GET_SUPPLIER_BID_DETAILS", getBidDetails)
	yield takeEvery("SUBMIT_SUPPLIER_BID", submitSupplierBid)

}

export default SupplierJourneySagas;