import React, { useEffect, useState } from "react";
// import "./Container.css";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";


const Container = ({ children, pageName }) => {
	const [userData, setUserData] = useState({})
	useEffect(() => {
		async function runOnLoad() {
			setUserData(JSON.parse(localStorage.getItem("userData")))
		}
		runOnLoad()
	}, [])

	return (
		<div>
			<div>
				<Navbar pageName={pageName} userData={userData} />
				<div className="container-fluid">{children}</div>
			</div>

			<Footer />
		</div>
	);
};

export default Container;
