import "./App.css";

import { Provider } from "react-redux";
import configureIndexStore from "./Stores";

import APP_CONFIG from "./Configs/environment";
import { Amplify } from "aws-amplify";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";

import { BuyerCognitoGroup, SupplierCognitoGroup } from "./Utils/Constants";

import Home from "./Components/Home/Home";
import Login from "./Components/Login/Login";
import Logout from "./Components/Logout/Logout";
import UnProtectedRoute from "./Components/UnProtectedRoutes";
import ProtectedRoute from "./Components/ProtectedRoutes";
import Error from "./Layouts/Error/Error";
import PageNotFound from "./Layouts/PageNotFound/PageNotFound"
import Unauthorised from "./Layouts/Unauthorised/Unauthorised";
import BuyerListProjects from "./Components/Print/Buyer/ListProjects/BuyerListProjects";
import BuyerCreateProject from "./Components/Print/Buyer/CreateProject/BuyerCreateProject"
import BuyerUpdateProject from "./Components/Print/Buyer/UpdateProject/BuyerUpdateProject"
import BuyerCreateJob from "./Components/Print/Buyer/CreateJob/BuyerCreateJob"
import BuyerUpdateJob from "./Components/Print/Buyer/UpdateJob/BuyerUpdateJob"
import BuyerManageBids from "./Components/Print/Buyer/ManageBids/BuyerManageBids"
import ViewProject from "./Components/Print/Suppliers/Viewprojects/ViewProject";
import ViewJob from "./Components/Print/Suppliers/ViewJobs/ViewJob";
import SuppliersListProjects from "./Components/Print/Suppliers/ListProjects/SuppliersListProjects"
import ConfirmUser from "./Components/Signup/ConfirmUser";
// import { ViewProject } from "./Components/Print/Suppliers/Viewprojects/ViewProject";
import Signup from "./Components/Signup/Signup"
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";
import ForgotPasswordVerification from "./Components/ForgotPassword/ForgotPasswordVerification";

Amplify.configure(APP_CONFIG.amplify_config);

const store = configureIndexStore()

function App() {
	return (
		<Provider store={store}>
			<Router>
				<Routes>
					<Route element={<UnProtectedRoute />}>
						<Route path="/" element={<Home />} />
						<Route path="login" element={<Login />} />
						<Route path="/signup" element={<Signup />} />
						<Route path="/confirm-user" element={<ConfirmUser />} />
						<Route path="/forgot-password" element={<ForgotPassword />} />
						<Route path="/forgot-password-verification" element={<ForgotPasswordVerification />} />
					</Route>
					<Route element={<ProtectedRoute roleGroup={BuyerCognitoGroup} failureUrl={"print/unauthorised"} />}>
						<Route
							path="print/buyer/projects"
							element={<BuyerListProjects />}
						/>
						<Route
							path="print/buyer/project/create"
							element={<BuyerCreateProject />}
						/>
						<Route
							path="print/buyer/project/:projectId/update"
							element={<BuyerUpdateProject />}
						/>
						<Route
							path="print/buyer/project/:projectId/job/create"
							element={<BuyerCreateJob />}
						/>
						<Route
							path="print/buyer/project/:projectId/job/:jobId/update"
							element={<BuyerUpdateJob />}
						/>
						<Route
							path="print/buyer/project/:projectId/job/:jobId/bids"
							element={<BuyerManageBids />}
						/>
						<Route path="*" element={<PageNotFound />} />
					</Route>
					<Route element={<ProtectedRoute roleGroup={SupplierCognitoGroup} failureUrl={"print/unauthorised"} />}>
						<Route
							path="print/supplier/projects"
							element={<SuppliersListProjects name={"supplier"} />}
						/>
						<Route
							path="print/supplier/project/:projectId"
							element={<ViewProject />}
						/>
						<Route
							path="print/supplier/project/:projectId/job/:jobId"
							element={<ViewJob />}
						/>
					</Route>
					<Route
						path="print/unauthorised"
						element={<Unauthorised />}
					/>
					<Route
						path="print/error"
						element={<Error />}
					/>
					<Route
						path="/logout"
						element={<Logout />}
					/>
					<Route 
						path="*" 
						element={<PageNotFound />} 
					/>
				</Routes>
			</Router>
		</Provider>
	);
}

export default App;
