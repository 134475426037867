import APP_CONFIG from "../Configs/environment";

export const AuthenticatedBuyerUserHomepage = "/print/buyer/projects"
export const AuthenticatedSupplierUserHomepage = "/print/supplier/projects"
export const UnAuthenticatedUserHomepage = "/login"
export const BuyerCognitoGroup = APP_CONFIG.appSettingsData.BuyerCognitoGroup
export const SupplierCognitoGroup = APP_CONFIG.appSettingsData.SupplierCognitoGroup
export const FederatedIDPProvider = APP_CONFIG.appSettingsData.federatedIDPProvider

export const BuyerListProjectStatusDropDown =
	[
		{ label: "All", value: "null" },
		{ label: "Completed", value: "Completed" },
		{ label: "Aborted", value: "Aborted" },
		{ label: "Live", value: "Live" }
	]

export const BuyerJobStatusDropDown =
	[
		{ label: "All", value: "null" },
		{ label: "Completed", value: "Completed" },
		{ label: "Aborted", value: "Aborted" },
		{ label: "Live", value: "Live" }
	]

export const BuyerList =
	[
		{ label: "Hamilton Brown", value: "Hamilton Brown" },
		{ label: "Joe Fearnley", value: "Joe Fearnley" },
		{ label: "Rob Langley", value: "Rob Langley" },
		{ label: "Elaine Farrow", value: "Elaine Farrow" },
	]

// export const ClientList =
// 	[
// 		{ label: "Acceleres (Critical Communication Capability and Agile Service Management) - TBA", value: "Acceleres (Critical Communication Capability and Agile Service Management) - TBA" },
// 		{ label: "Approved Docs (TSO) - 9869", value: "Approved Docs (TSO) - 9869" },
// 		{ label: "BRC Global Standards (BRCGS) - 8816", value: "BRC Global Standards (BRCGS) - 8816" },
// 		{ label: "British Pharmacopoeia - 8842", value: "British Pharmacopoeia - 8842" },
// 		{ label: "Civil Aviation Authority - 9817", value: "Civil Aviation Authority - 9817" },
// 		{ label: "Department for Transport - 9944", value: "Department for Transport - 9944" },
// 		{ label: "DVSA - 9948", value: "DVSA - 9948" },
// 		{ label: "Ernst & Young - TBA", value: "Ernst & Young - TBA" },
// 		{ label: "FCA (previously 9800 - FSA) - 9838", value: "FCA (previously 9800 - FSA) - 9838" },
// 		{ label: "Food Standards Agency - 9872", value: "Food Standards Agency - 9872" },
// 		{ label: "Foreign & Commonwealth Office - 9909", value: "Foreign & Commonwealth Office - 9909" },
// 		{ label: "Health - 9942", value: "Health - 9942" },
// 		{ label: "Highway Code - 9959", value: "Highway Code - 9959" },
// 		{ label: "Highways Agency (Highways England) - 9903", value: "Highways Agency (Highways England) - 9903" },
// 		{ label: "HMSO Bound Volumes - 9923", value: "HMSO Bound Volumes - 9923" },
// 		{ label: "Home Office - 9901", value: "Home Office - 9901" },
// 		{ label: "HS2 (RM3785) - TBA", value: "HS2 (RM3785) - TBA" },
// 		{ label: "HSE - 9812", value: "HSE - 9812" },
// 		{ label: "In Progress - 9875", value: "In Progress - 9875" },
// 		{ label: "International Best Practice - 7777", value: "International Best Practice - 7777" },
// 		{ label: "ITSM - 9987", value: "ITSM - 9987" },
// 		{ label: "KayJayEm Services - 9936", value: "KayJayEm Services - 9936" },
// 		{ label: "Gazette - 9940", value: "Gazette - 9940" },
// 		{ label: "MCA - 9912", value: "MCA - 9912" },
// 		{ label: "MHCLG (previously DCLG) - 9968", value: "MHCLG (previously DCLG) - 9968" },
// 		{ label: "MHRA - 9862", value: "MHRA - 9862" },
// 		{ label: "Ministry of Justice - 9967", value: "Ministry of Justice - 9967" },
// 		{ label: "MOD - 9969", value: "MOD - 9969" },
// 		{ label: "MUSC/TSIM - 8001", value: "MUSC/TSIM - 8001" },
// 		{ label: "NHS Estates - 9946", value: "NHS Estates - 9946" },
// 		{ label: "Notion - 9849", value: "Notion - 9849" },
// 		{ label: "Police Foundation - 9911", value: "Police Foundation - 9911" },
// 		{ label: "Praxix - 9836", value: "Praxix - 9836" },
// 		{ label: "Purity-IQ - 9978", value: "Purity-IQ - 9978" },
// 		{ label: "R&A - 9863", value: "R&A - 9863" },
// 		{ label: "Ross Garland (Project, Programme and Portfolio Governance) - 9805", value: "Ross Garland (Project, Programme and Portfolio Governance) - 9805" },
// 		{ label: "Scotland - 9925", value: "Scotland - 9925" },
// 		{ label: "Statutory Instruments - 9921", value: "Statutory Instruments - 9921" },
// 		{ label: "TSO Marketing - 9253", value: "TSO Marketing - 9253" },
// 		{ label: "TSO Stationery - 9223", value: "TSO Stationery - 9223" },
// 		{ label: "University of Huddersfield - 9974", value: "University " }]

export const PageSize =
	[
		{ label: "A1", value: "A1" },
		{ label: "A2", value: "A2" },
		{ label: "A3", value: "A3" },
		{ label: "A4", value: "A4" },
		{ label: "A5", value: "A5" },
		{ label: "A6", value: "A6" },
		{ label: "A7", value: "A7" },
		{ label: "Other", value: "Other" },
		{ label: "NA", value: "Not Applicable" },
	]

export const Proofs =
	[
		{ label: "PDF", value: "PDF" },
		{ label: "Digital", value: "Digital" },
		{ label: "Book Proof", value: "Book Proof" },
		{ label: "NA", value: "Not Applicable" },
	]

export const DeliveryAddress =
	[
		{ label: "Please Select", value:"",recipientName: "", addressLine1: "", county: "", postCode: "" },
		{ label: "Joe Fearnley",value:"Joe Fearnley", recipientName: "Joe Fearnley", addressLine1: "Williams Lea (TSO), 18 Central Avenue, St Andrews Business Park", county: "Norwich", postCode: "NR7 0HR" },
		{ label: "Rob Langley",value:"Rob Langley", recipientName: "Rob Langley", addressLine1: "Williams Lea (TSO), 18 Central Avenue, St Andrews Business Park", county: "Norwich", postCode: "NR7 0HR" },
		{ label: "Elaine Farrow", value:"Elaine Farrow",recipientName: "Elaine Farrow", addressLine1: "Williams Lea (TSO), 18 Central Avenue, St Andrews Business Park", county: "Norwich", postCode: "NR7 0HR" },
		{ label: "Hamilton Brown", value:"Hamilton Brown",recipientName: "Hamilton Brown", addressLine1: "Williams Lea (TSO), 18 Central Avenue, St Andrews Business Park", county: "Norwich", postCode: "NR7 0HR" },
		{ label: "Woodlands Warehouse", value:"Woodlands Warehouse",recipientName: "Woodlands Warehouse", addressLine1: "The Stationery Office (TSO), c/o Woodland Book Services, Dollymans Farm, Doublegate Lane, Rawreth", county: "Essex", postCode: "SS11 8UD" },
		{ label: "Other", value:"Other",recipientName: "", addressLine1: "", county: "", postCode: "" },
	
	]

