import { call, put, takeEvery } from 'redux-saga/effects'
import { get, post, apiPut } from "./../Services/API/ApiClient"

// worker Saga: will be fired on GET_USER_DATA actions
function* buyerGetProjectsList(data) {
	try {
		yield put({ type: "SET_BUYER_PROJECT_LIST_PAGE_INITIALIZE", payload: { listProjectsPage: { isDataLoaded: false } } });
		const responseData = yield call(post, "/buyer/project/search", "print", data.payload);
		yield put({ type: "SET_BUYER_PROJECT_LIST", payload: { listProjectsPage: { ...responseData.data, isDataLoaded: true } } });
	} catch (e) {
		yield put({ type: "GET_BUYER_PROJECT_FAILED", payload: { listProjectsPage: { ...e, isDataLoaded: true } } });
	}
}

function* createBuyerProject(data) {
	try {
		yield put({ type: "CREATE_BUYER_PROJECT_INITIATED", payload: { buyerCreateProjectResponse: { isCreateSuccessful: false, isCreateInitiated: true, isCreateError: false } } });
		const responseData = yield call(apiPut, "/buyer/project/create", "print", data.payload);
		yield put({ type: "SET_BUYER_PROJECT_CREATE_RESPONSE", payload: { buyerCreateProjectResponse: { ...responseData.data, isCreateSuccessful: true, isCreateInitiated: false, isCreateError: false } } });
	} catch (e) {
		
		yield put({ type: "BUYER_PROJECT_CREATE_FAILED", payload: { buyerCreateProjectResponse: { ...e, isCreateSuccessful: false, isCreateInitiated: true, isCreateError: true } } });
	}
}

function* getBuyerProject({ payload }) {
	try {
		yield put({ type: "GET_BUYER_PROJECT_INITIATED", payload: { buyerProjectDetails: { isDataLoaded: false } } });
		const responseData = yield call(get, `/buyer/project/${payload}`, "print");
		yield put({ type: "GET_BUYER_PROJECT_RESPONSE", payload: { buyerProjectDetails: { ...responseData.data, isDataLoaded: true } } });
	} catch (e) {
		
		yield put({ type: "GET_BUYER_PROJECT_FAILED", payload: { buyerProjectDetails: { ...e, isCreateSuccessful: false, isCreateInitiated: true } } });
	}
}

function* getBuyerJob(data) {
	try {
		yield put({ type: "GET_BUYER_JOB_INITIATED", payload: { buyerJobDetails: { isDataLoaded: false } } });
		const responseData = yield call(get, `/buyer/project/${data.payload.projectId}/job/${data.payload.jobId}`, "print");
		yield put({ type: "GET_BUYER_JOB_RESPONSE", payload: { buyerJobDetails: { ...responseData.data, isDataLoaded: true } } });
	} catch (e) {
		
		yield put({ type: "GET_BUYER_JOB_FAILED", payload: { buyerJobDetails: { ...e, isCreateSuccessful: false, isCreateInitiated: true } } });
	}
}

function* getBuyerJobs(data) {
	try {
		yield put({ type: "GET_BUYER_JOBS_INITIATED", payload: { buyerJobs: { isDataLoaded: false } } });
		const responseData = yield call(get, `/buyer/project/${data.payload.projectId}/jobs`, "print");
		yield put({ type: "GET_BUYER_JOBS_RESPONSE", payload: { buyerJobs: { ...responseData, isDataLoaded: true } } });
	} catch (e) {
	
		yield put({ type: "GET_BUYER_JOBS_FAILED", payload: { buyerJobs: { ...e, isGetSuccessful: false, isGetInitiated: true } } });
	}
}
function* updateBuyerProject(data) {
	try {
		yield put({ type: "UPDATE_BUYER_PROJECT_INITIATED", payload: { buyerUpdateProjectResponse: { isUpdateSuccessful: false, isUpdateInitiated: true, isUpdateError: false } } });
		const responseData = yield call(post, "/buyer/project/update", "print", data.payload);
		yield put({ type: "UPDATE_BUYER_PROJECT_RESPONSE", payload: { buyerUpdateProjectResponse: { ...responseData.data, isUpdateSuccessful: true, isUpdateInitiated: false, isUpdateError: false } } });
	} catch (e) {
	
		yield put({ type: "UPDATE_BUYER_PROJECT_FAILED", payload: { buyerUpdateProjectResponse: { ...e, isUpdateSuccessful: false, isUpdateInitiated: true, isUpdateError: true } } });
	}
}

function* updateBuyerJob(data) {
	try {
		yield put({ type: "UPDATE_BUYER_JOB_INITIATED", payload: { buyerUpdateJobResponse: { isUpdateSuccessful: false, isUpdateInitiated: true } } });
		const responseData = yield call(post, `/buyer/project/${data.payload.projectId}/job/${data.payload.jobId}/update`, "print", data.payload.values);
		yield put({ type: "UPDATE_BUYER_JOB_RESPONSE", payload: { buyerUpdateJobResponse: { ...responseData.data, isUpdateSuccessful: true, isUpdateInitiated: false } } });
	} catch (e) {
	
		yield put({ type: "UPDATE_BUYER_JOB_FAILED", payload: { buyerUpdateJobResponse: { ...e, isUpdateSuccessful: false, isUpdateInitiated: true } } });
	}
}

function* createBuyerJob(data) {
	try {
		yield put({ type: "CREATE_BUYER_JOB_INITIATED", payload: { buyerCreateJobResponse: { isCreateSuccessful: false, isCreateInitiated: true, isCreateError: false } } });
		const responseData = yield call(apiPut, `/buyer/project/${data.payload.projectId}/job/create`, "print", data.payload.values);
		yield put({ type: "SET_BUYER_JOB_CREATE_RESPONSE", payload: { buyerCreateJobResponse: { ...responseData.data, isCreateSuccessful: true, isCreateInitiated: false, isCreateError: false } } });
	} catch (e) {
	
		yield put({ type: "BUYER_JOB_CREATE_FAILED", payload: { buyerCreateJobResponse: { ...e, isCreateSuccessful: false, isCreateInitiated: true, isCreateError: true } } });
	}
}

function* getSupplierList() {
	try {
		yield put({ type: "GET_SUPPLIER_LIST_INITIATED", payload: { supplierList: { isDataLoaded: false } } });
		const responseData = yield call(get, "/buyer/picklist/suppliers", "print");
		yield put({ type: "GET_SUPPLIER_LIST_RESPONSE", payload: { supplierList: { data: responseData, isDataLoaded: true } } });
	} catch (e) {
		
		yield put({ type: "GET_SUPPLIER_LIST_FAILED", payload: { supplierList: { ...e, isGetSuccessful: false, isGetInitiated: true } } });
	}
}

function* getBidsList(data) {
	try {
		yield put({ type: "GET_BIDS_LIST_INITIATED", payload: { bidsList: { isDataLoaded: false } } });
		const responseData = yield call(get, `/buyer/project/${data.payload.projectId}/job/${data.payload.jobId}/bid/invites`, "print");
		yield put({ type: "GET_BIDS_LIST_RESPONSE", payload: { bidsList: { ...responseData, isDataLoaded: true } } });
	} catch (e) {
		
		yield put({ type: "GET_BIDS_LIST_FAILED", payload: { bidsList: { ...e, isGetSuccessful: false, isGetInitiated: true } } });
	}
}

function* createBuyerBidInvite(data) {
	try {
		yield put({ type: "CREATE_BUYER_JOB_INITIATED", payload: { buyerCreateJobResponse: { isCreateSuccessful: false, isCreateInitiated: true } } });
		const responseData = yield call(apiPut, `/buyer/project/${data.payload.projectId}/job/create`, "print", data.payload.values);
		yield put({ type: "SET_BUYER_JOB_CREATE_RESPONSE", payload: { buyerCreateJobResponse: { ...responseData.data, isCreateSuccessful: true, isCreateInitiated: false } } });
	} catch (e) {
	
		yield put({ type: "BUYER_JOB_CREATE_FAILED", payload: { buyerCreateJobResponse: { ...e, isCreateSuccessful: false, isCreateInitiated: true } } });
	}
}
function* setBidInviteData(data) {
	try {
		yield put({ type: "SET_BUYER_BID_INVITE_INITIATED", payload: { buyerSetBidInviteResponse: { isCreateSuccessful: false, isCreateInitiated: true } } });
		const responseData = yield call(post, `/buyer/project/${data.payload.projectId}/job/${data.payload.jobId}/bid/invites`, "print", data.payload.data);
		yield put({ type: "SET_BUYER_BID_INVITE_RESPONSE", payload: { buyerSetBidInviteResponse: { ...responseData.data, isCreateSuccessful: true, isCreateInitiated: false } } });
	} catch (e) {
		
		yield put({ type: "BUYER_BID_INVITE_RESPONSE_FAILED", payload: { buyerSetBidInviteResponse: { ...e, isCreateSuccessful: false } } });
	}
}

function* getBidResponse(data) {
	try {
		yield put({ type: "GET_BUYER_BID_RESPONSE_INITIATED", payload: { buyerGetBidResponse: { isDataLoaded: false } } });
		const responseData = yield call(get, `/buyer/project/${data.payload.projectId}/job/${data.payload.jobId}/bid/responses`, "print");
		yield put({ type: "SET_BUYER_BID_RESPONSE", payload: { buyerGetBidResponse: { ...responseData, isDataLoaded: true } } });
	}
	catch (e) {
		yield put({ type: "BUYER_BID_RESPONSE_RESPONSE_FAILED", payload: { buyerGetBidResponse: { ...e} } });
	}
}

function* ConfirmSupplier(data) {
	try {
		yield put({ type: "CONFIRM_SUPPLIER_INITIATED", payload: { buyerConfirmSupplier: { isCreateSuccessful: false, isCreateInitiated: true ,apiStatus: "INITIATED"} } });
		const responseData = yield call(post, `/buyer/project/${data.payload.projectId}/job/${data.payload.jobId}/bid/confirm`, "print", data.payload.bidResponse);
		yield put({ type: "SAVE_CONFIRM_SUPPLIER_RESPONSE", payload: { buyerConfirmSupplier: { ...responseData.data, isCreateSuccessful: true, isCreateInitiated: true, isCreateError: false ,apiStatus: "SUCCESS"} } });
		window.location.href = `/print/buyer/project/${data.payload.projectId}/job/${data.payload.jobId}/bids`
	}
	catch (e) {
		
		yield put({ type: "SAVE_CONFIRM_SUPPLIER_FAILED", payload: { buyerConfirmSupplier: { ...e, apiStatus: "SUBMIT_FAILED" } } });
	}

}

function* abortProject(data) {
	try {
		const responseData = yield call(post, `/buyer/project/${data.payload.projectId}/abort`, "print", data.payload.projectId);
		yield put({ type: "ABORT_PROJECT_RESPONSE", payload: { abortProject: { ...responseData, isUpdateSuccessful: true } } });
		window.location.href = `/print/buyer/projects`
	}
	catch (e) {
		yield put({ type: "ABORT_PROJECT_RESPONSE_FAILED", payload: { abortProject: { ...e, isUpdateSuccessful: false } } });
	}
}

function* completeProject(data) {
	try {
		const responseData = yield call(post, `/buyer/project/${data.payload.projectId}/complete`, "print", data.payload.projectId);
		yield put({ type: "COMPLETE_PROJECT_RESPONSE", payload: { completeProject: { ...responseData, isUpdateSuccessful: true } } });
		window.location.href = `/print/buyer/projects`
	}
	catch (e) {
		yield put({ type: "COMPLETE_PROJECT_RESPONSE_FAILED", payload: { completeProject: { ...e, isUpdateSuccessful: false } } });
	}
}
function* abortJob(data) {
	try {
		const responseData = yield call(post, `/buyer/project/${data.payload.projectId}/job/${data.payload.jobId}/abort`, "print", data.payload.projectId);
		yield put({ type: "ABORT_JOB_RESPONSE", payload: { abortJob: { ...responseData, isUpdateSuccessful: true } } });
		window.location.href = `/print/buyer/projects`
	}
	catch (e) {
		yield put({ type: "ABORT_JOB_RESPONSE_FAILED", payload: { abortJob: { ...e, isUpdateSuccessful: false } } });
	}
}
function *getBuyerList(){
	try{
		yield put({ type: "GET_BUYER_LIST_INITIATED", payload: { buyerList: { isDataLoaded: false } } });
		const responseData = yield call(get, "/buyer/picklist/buyers", "print");
		yield put({ type: "GET_BUYER_LIST_RESPONSE", payload: { buyerList: { data: responseData, isDataLoaded: true } } });
	}
	catch(e){
		yield put({ type: "GET_BUYER_LIST_RESPONSE_FAILED", payload: { buyerList: { ...e, isUpdateSuccessful: false } } });
	}
}
function *getClientList(){

	try{
		yield put({ type: "GET_CLIENT_LIST_INITIATED", payload: { clientList: { isDataLoaded: false } } });
		const responseData = yield call(get, "/buyer/picklist/clients", "print");
		yield put({ type: "GET_CLIENT_LIST_RESPONSE", payload: { clientList: { data: responseData, isDataLoaded: true } } });
	}
	catch(e){
		yield put({ type: "CLIENT_LIST_RESPONSE_FAILED", payload: { clientList: { ...e } } });
		
	}
}
function* BuyerJourneySagas() {
	yield takeEvery("GET_BUYER_PROJECT", getBuyerProject);
	yield takeEvery("GET_BUYER_JOB", getBuyerJob);
	yield takeEvery("GET_BUYER_JOBS", getBuyerJobs);
	yield takeEvery("GET_BUYER_PROJECT_LIST", buyerGetProjectsList);
	yield takeEvery("CREATE_BUYER_PROJECT", createBuyerProject);
	yield takeEvery("CREATE_BUYER_JOB", createBuyerJob);
	yield takeEvery("UPDATE_BUYER_PROJECT", updateBuyerProject);
	yield takeEvery("UPDATE_BUYER_JOB", updateBuyerJob);
	yield takeEvery("GET_SUPPLIER_LIST", getSupplierList);
	yield takeEvery("GET_BIDS_LIST", getBidsList);
	yield takeEvery("CREATE_BUYER_BID_INVITE", createBuyerBidInvite);
	yield takeEvery("SET_BUYER_BID_INVITE", setBidInviteData)
	yield takeEvery("GET_BUYER_BID_RESPONSE", getBidResponse)
	yield takeEvery("SET_CONFIRM_SUPPLIER", ConfirmSupplier)
	yield takeEvery("ABORT_PROJECT", abortProject)
	yield takeEvery("CONFIRM_PROJECT", completeProject)
	yield takeEvery("ABORT_JOB", abortJob)
	yield takeEvery("GET_BUYER_LIST",getBuyerList)
	yield takeEvery("GET_CLIENT_LIST",getClientList)
}

export default BuyerJourneySagas;